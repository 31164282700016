import { CdkTableModule } from "@angular/cdk/table";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentsModule } from "src/app/components/components.module";
import { SharedModule } from "../../shared/shared.module";
import { GeneralInfoPage } from "./general-info/general-info-page";
import { MeasurePointsPage } from "./measure-points/measure-points-page";
import { StructureCreationPageRoutingModule } from "./structure-creation-page-routing.module";
import { SynthesisPage } from "./synthesis/synthesis-page";

const COMPONENTS = [GeneralInfoPage, MeasurePointsPage, SynthesisPage];

@NgModule({
  imports: [
    SharedModule,
    ComponentsModule,
    BrowserAnimationsModule,
    StructureCreationPageRoutingModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class StructureCreationPageModule {}
