import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { StructureFormGroup } from "../../services/utils/createStructureFormGroup";

@Component({
  selector: "structure-billing-form",
  templateUrl: "./structure-billing-form.component.html",
  styleUrls: ["./structure-billing-form.component.scss"],
})
export class StructureBillingFormComponent implements OnInit {
  @Input() public form!:
    | FormGroup<{
        customerCode: FormControl<string>;
        purchaseOrder: FormControl<string>;
      }>
    | StructureFormGroup;
  @Input() public mode = "creation";
  @Input() public readMode = false;

  public customerCodeHint?: string;
  public purchaseOrderHint?: string;

  @Input() public formHeader!: string;

  public constructor() {}

  public ngOnInit() {
    if (this.mode === "approving") {
      this.customerCodeHint =
        this.form.controls.customerCode.value ?? undefined;
      this.purchaseOrderHint =
        this.form.controls.purchaseOrder.value ?? undefined;
      this.form.controls.customerCode.setValue("");
      this.form.controls.purchaseOrder.setValue("");
    }
  }
}
