import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { DeploymentService } from "../../../services/network/deployment.service";

@Injectable()
export class StructureDeploymentReportDraftResolver {
  public constructor(private deploymentService: DeploymentService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    const id = route.parent!.paramMap.get("id");
    if (id) {
      return this.deploymentService.getDeploymentReportDraft(id);
    }
  }
}
