import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as yaml from "js-yaml";
import * as JSZip from "jszip";
import { isEqual } from "lodash";
import pLimit from "p-limit";
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, take, tap } from "rxjs/operators";
import { mapMeasureItemDates } from "src/app/services/mapping/mapMeasureItemDates";
import { environment } from "../../../environments/environment";
import { Amc } from "../../models/amc.model";
import { DisplayedFile, File } from "../../models/file.model";
import { MeasureItem, OpenSensorValue } from "../../models/measure.model";
import { OpenSensor } from "../../models/open-sensor.model";
import { Pagination } from "../../models/pagination.model";
import { RequestedDate } from "../../models/requested-date.model";
import {
  HashId,
  PatchMeasurePointDTO,
  PatchMeasurePointResponseDTO,
  Structure,
  StructureInfo,
  StructureListFilters,
} from "../../models/structure.model";
import { ReceivedRow } from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import {
  AmcResponse,
  mapAmcResponseToAmc,
} from "../mapping/mapAmcResponseToAmc";
import { mapFilesFromResponseToFiles } from "../mapping/mapFilesFromResponseToFiles";
import { mapPaginationToStructureInfoPagination } from "../mapping/mapPaginationToStructureInfoPagination";
import { DownloadFileService } from "../utils/download-file.service";
import filenamify from "../utils/filenamify";
import { LocalizedDatePipe } from "../utils/LocalizedDate.pipe";
import { StorageService } from "../utils/storage.service";
import {
  clearWhiteSpaceAndLowerCase,
  pollUntil$,
  removeNullishValues,
} from "../utils/utils";
import { ERROR_INTERCEPTOR_SKIP_HEADER } from "./http-error.interceptor";

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  private readonly serviceUrl: string = "api/v1/business";
  private readonly structure$ = new BehaviorSubject<Structure | undefined>(
    undefined,
  );
  private readonly structureInfoList$: BehaviorSubject<
    Pagination<StructureInfo>
  > = new BehaviorSubject({
    count: 0,
    data: [] as StructureInfo[],
    limit: 0,
    offset: 0,
    total: 0,
  });
  private readonly measures$ = new BehaviorSubject<MeasureItem[]>([]);
  private readonly displayedFile$ = new BehaviorSubject<
    DisplayedFile | undefined
  >(undefined);
  private readonly files$ = new BehaviorSubject<File[]>([]);

  public constructor(
    private http: HttpClient,
    private downloadFileService: DownloadFileService,
    private datePipe: LocalizedDatePipe,
    private storage: StorageService,
  ) {}

  public getStructureValue(): Structure {
    return this.structure$.getValue()!;
  }

  public structureStream(): Observable<Structure> {
    return this.structure$.pipe(
      filter((value) => !!value),
    ) as Observable<Structure>;
  }

  public structureInfoListStream(): Observable<Pagination<StructureInfo>> {
    return this.structureInfoList$.asObservable();
  }

  public measureItemsStream(): Observable<MeasureItem[]> {
    return this.measures$.asObservable();
  }

  private formatDateFileName(date: Date) {
    return this.datePipe.transform(date, "dd-MM-yyyy_HH-mm-ss", "UTC");
  }

  public getFilesValue(): File[] {
    return this.files$.value;
  }
  public getFilesStream(): Observable<File[]> {
    return this.files$.asObservable();
  }

  public getDocumentationFilesStream(): Observable<File[]> {
    return this.getFilesStream().pipe(
      map((files) =>
        files.filter((file) => file.category === "documentations"),
      ),
    );
  }
  public getLegalFilesStream(): Observable<File[]> {
    return this.getFilesStream().pipe(
      map((files) =>
        files.filter(
          (file) =>
            file.category === "legals" &&
            file.locale === this.storage.getLocale(),
        ),
      ),
    );
  }
  public getReleaseNotesFilesStream(): Observable<File[]> {
    return this.getFilesStream().pipe(
      map((files) =>
        files.filter(
          (file) =>
            file.category === "release-notes" &&
            file.name.toLowerCase() <= `v${environment.version}`,
        ),
      ),
    );
  }

  public displayedFileStream(): Observable<DisplayedFile | undefined> {
    return this.displayedFile$.asObservable();
  }

  public clearDisplayedFile() {
    return this.displayedFile$.next(undefined);
  }

  public async downloadAllRaws(rows: ReceivedRow[], requestedDate: Date) {
    const fileName = clearWhiteSpaceAndLowerCase(
      `${this.formatDateFileName(requestedDate)}.raw.zip`,
    );
    const limit = pLimit(5);

    const urlsAndFilenames = rows.map((measure) => {
      const filename = `measure${measure.measurePointNumber}.json`;
      const url = `${environment.apiUrl}${measure.sensors.raw!.file.href}`;
      return {
        url,
        filename,
      };
    });

    const requests = urlsAndFilenames.map(
      (elem: { url: string; filename: string }) =>
        limit(() =>
          lastValueFrom(
            this.http
              .get<unknown>(elem.url, {
                reportProgress: true,
              })
              .pipe(map((measure) => ({ filename: elem.filename, measure }))),
          ),
        ),
    );

    await this.downloadFileService.downloadMeasureRaws(requests, fileName);
  }

  public downloadRaw(row: ReceivedRow) {
    this.http
      .get(`${environment.apiUrl}${row.sensors.raw!.file.href}`, {
        responseType: "blob",
        observe: "response",
      })
      .subscribe((res) => {
        const fileName = `${this.formatDateFileName(
          row.requestedDate,
        )}.raw.measure${row.measurePointNumber}.json`;
        this.downloadFileService.downloadFile(
          res.body,
          res.headers.get("Content-Type")!,
          clearWhiteSpaceAndLowerCase(fileName),
        );
      });
  }

  public getOpenSensorByHref(href: string): Observable<OpenSensor> {
    return this.http.get<OpenSensor>(`${environment.apiUrl}${href}`);
  }

  public downloadOpenSensor(openSensor: OpenSensorValue, measure: MeasureItem) {
    this.http
      .get(`${environment.apiUrl}${openSensor.href}/file`, {
        responseType: "blob",
        observe: "response",
      })
      .subscribe((res) => {
        const contentType = res.headers.get("Content-Type")!;
        const dataType = contentType.match(/\b(\w+)($|;)/)![1] || "stream"; // match last word of content-type in our case : "stream" or "json"
        const fileName = `${this.formatDateFileName(
          measure.requestedDate,
        )}.processed.measure${measure.measurePointNumber}.${
          openSensor.name
        }.${dataType}`;

        this.downloadFileService.downloadFile(
          res.body,
          contentType,
          clearWhiteSpaceAndLowerCase(fileName),
        );
      });
  }

  public downloadMultipleOpenSensors(
    openSensors: OpenSensorValue[],
    measure: MeasureItem,
  ) {
    const zipFile: JSZip = new JSZip();

    const reportObs = openSensors.map((openSensor) => {
      return this.http
        .get(`${environment.apiUrl}${openSensor.href}/file`, {
          headers: new HttpHeaders({}),
          responseType: "blob",
          observe: "response",
        })
        .pipe(map((response) => ({ openSensor, response })));
    });
    forkJoin(reportObs).subscribe(async (results) => {
      results.forEach(({ openSensor, response }) => {
        const dataType =
          response.headers.get("Content-Type")!.match(/\b(\w+)($|;)/)![1] ||
          "stream"; // match last word of content-type in our case : "stream" or "json"
        const fileName = filenamify(
          `measure${measure.measurePointNumber}.${openSensor.name}.${dataType}`,
          { replacement: "_" },
        );
        zipFile.file(fileName, response.body!);
      });

      await zipFile.generateAsync({ type: "blob" }).then((file) => {
        const fileName = `${this.formatDateFileName(
          measure.requestedDate,
        )}.processed.zip`;
        this.downloadFileService.downloadFile(
          file,
          "application/zip",
          fileName,
        );
      });
    });
  }

  public getStructureDeploymentFile(structureId: HashId) {
    const headers = new HttpHeaders({});
    this.http
      .get(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentFile`,
        { headers, responseType: "blob", observe: "response" },
      )
      .subscribe((response) => {
        this.downloadFileService.downloadFile(
          response.body,
          response.body!.type,
          getDownloadFilename(response.headers, "deploymentfile"),
        );
      });
  }

  public getStructure$(
    structureId: HashId,
    skipErrorInterceptor = false,
  ): Observable<Structure> {
    const headers = skipErrorInterceptor
      ? new HttpHeaders({
          [ERROR_INTERCEPTOR_SKIP_HEADER]: "",
        })
      : undefined;
    return this.http.get<Structure>(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}`,
      { headers },
    );
  }

  public getStructure(structureId: HashId) {
    return this.getStructure$(structureId).pipe(
      tap((res) => {
        this.structure$.next(res);
      }),
    );
  }

  public getStructureInfoList(
    filters: StructureListFilters,
  ): Observable<Pagination<StructureInfo>> {
    return this.getStructures$(filters).pipe(
      tap((result) => {
        this.structureInfoList$.next(result);
      }),
    );
  }

  public getBlobByHref(href: string) {
    return this.http.get(environment.apiUrl + href, {
      responseType: "blob",
    });
  }

  public isStructureInfoListOutdated(filters: StructureListFilters) {
    const actualStructureList = this.structureInfoList$.value;
    return this.getStructures$(filters, true).pipe(
      map((result) => {
        return !isEqual(result, actualStructureList);
      }),
    );
  }

  private getStructures$(
    filters: StructureListFilters,
    skipError = false,
  ): Observable<Pagination<StructureInfo>> {
    let queryOptions = {};
    if (skipError)
      queryOptions = {
        headers: new HttpHeaders({
          [ERROR_INTERCEPTOR_SKIP_HEADER]: "",
        }),
      };
    let url = `${environment.apiUrl}/${this.serviceUrl}/structure?`;
    if (filters.offset !== undefined) url += `offset=${filters.offset}`;
    if (filters.limit !== undefined) url += `&limit=${filters.limit}`;
    if (filters.nameSearch) url += `&nameSearch=${filters.nameSearch}`;
    if (filters.customerSearch)
      url += `&customerSearch=${filters.customerSearch}`;
    if (filters.contactSearch) url += `&contactSearch=${filters.contactSearch}`;
    filters.types?.forEach((type) => (url += `&types[]=${type}`));
    filters.status?.forEach((status) => (url += `&status[]=${status}`));
    if (filters?.activationDate?.start)
      url += `&commissioningDateStart=${filters.activationDate?.start}`;
    if (filters?.activationDate?.end)
      url += `&commissioningDateEnd=${filters.activationDate?.end}`;
    if (filters?.creationDate?.start)
      url += `&creationDateStart=${filters.creationDate?.start}`;
    if (filters?.creationDate?.end)
      url += `&creationDateEnd=${filters.creationDate?.end}`;

    return this.http
      .get(url, queryOptions)
      .pipe(map((result) => mapPaginationToStructureInfoPagination(result)));
  }

  public getRequestedDateList(
    filters: GetRequestedDatesFilter = {},
  ): Observable<Pagination<RequestedDate>> {
    return this.http
      .get<Pagination<RequestedDate>>(
        `${environment.apiUrl}/${this.serviceUrl}/requestedDate`,
        {
          params: new HttpParams({ fromObject: removeNullishValues(filters) }),
        },
      )
      .pipe(
        map((result) => ({
          ...result,
          data: result.data.map((date) => ({
            ...date,
            requestedDate: new Date(date.requestedDate),
          })),
        })),
      );
  }

  public getMeasures(
    filters: GetMeasuresFilter = {},
  ): Observable<Pagination<MeasureItem>> {
    return this.http
      .get<Pagination<MeasureItem>>(
        `${environment.apiUrl}/${this.serviceUrl}/measure`,
        {
          params: new HttpParams({ fromObject: removeNullishValues(filters) }),
        },
      )
      .pipe(
        map((result) => ({
          ...result,
          data: mapMeasureItemDates(result.data),
        })),
      );
  }

  public getMeasure(measureId: string) {
    return this.http
      .get<MeasureItem>(
        `${environment.apiUrl}/${this.serviceUrl}/measure/${measureId}`,
      )
      .pipe(map((result) => mapMeasureItemDates([result])[0]));
  }

  public getDeploymentArchive(structureId: HashId) {
    const headers = new HttpHeaders({});
    this.http
      .get(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentArchive`,
        { headers, responseType: "blob", observe: "response" },
      )
      .subscribe((response) => {
        this.downloadFileService.downloadFile(
          response.body,
          response.body!.type,
          getDownloadFilename(response.headers, "deployment.zip"),
        );
      });
  }

  public getConfigurationArchive(structureId: HashId) {
    const headers = new HttpHeaders({});
    this.http
      .get(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/devicesConfigurationArchive`,
        { headers, responseType: "blob", observe: "response" },
      )
      .subscribe((response) => {
        this.downloadFileService.downloadFile(
          response.body,
          response.body!.type,
          getDownloadFilename(response.headers, "devices.conf"),
        );
      });
  }

  public postAmc(structureId: HashId, amc: Amc) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/amc`;
    return this.http
      .post<AmcResponse>(url, amc, { headers, responseType: "json" })
      .pipe(take(1), map(mapAmcResponseToAmc));
  }

  public getLastAmc(structureId: HashId) {
    return this.http
      .get<AmcResponse>(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/amc/last`,
      )
      .pipe(take(1), map(mapAmcResponseToAmc));
  }

  public getAmc(structureId: HashId, amcId: number) {
    const headers = new HttpHeaders({});
    return this.http
      .get<AmcResponse>(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/amc/${amcId}`,
        { headers, responseType: "json" },
      )
      .pipe(take(1), map(mapAmcResponseToAmc));
  }

  public getFiles() {
    this.http
      .get(`${environment.apiUrl}/${this.serviceUrl}/file`)
      .subscribe((res: unknown) => {
        const filesFromResponse = res as Array<{ name: string; href: string }>;
        const files = mapFilesFromResponseToFiles(
          filesFromResponse,
          this.serviceUrl,
        );
        this.files$.next(files);
      });
  }

  public getFile(name: string, path: string) {
    const headers = new HttpHeaders();
    this.http
      .get(`${environment.apiUrl}/${this.serviceUrl}${path}`, {
        headers,
        responseType: "blob",
      })
      .subscribe((blob) => {
        const reader = new FileReader();
        reader.readAsText(blob);
        reader.onloadend = () => {
          const content = reader.result!;
          let parsedContent: unknown;
          const filename = name;
          const extension = filename.split(".").pop()!;
          switch (extension) {
            case "yaml":
              parsedContent = yaml.load(content.toString());
              break;
            case "pdf":
              parsedContent = URL.createObjectURL(blob);
              break;
            case "md":
            default:
              parsedContent = content;
              break;
          }
          this.displayedFile$.next({
            name: filename,
            extension,
            file: parsedContent,
            blob,
          });
        };
      });
  }

  public postRetrieve(structureId: HashId, retrieveId: number) {
    return this.http.post(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/retrieve`,
      { retrieveId },
      { responseType: "text" },
    );
  }

  public patchMeasurePoint(
    structureId: HashId,
    measurePointNumber: number,
    newState: PatchMeasurePointDTO,
  ) {
    return this.http
      .patch<PatchMeasurePointResponseDTO>(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/measurePoint/${measurePointNumber}`,
        newState,
      )
      .pipe(
        map((response) => ({
          ...response,
          deactivationDate: response.deactivationDate
            ? new Date(response.deactivationDate)
            : undefined,
        })),
      );
  }

  public pollUntilStructureLeftState(structureId: string, state: string) {
    return (source: Observable<unknown>) =>
      source.pipe(
        mergeMap(() =>
          pollUntil$(() =>
            this.getStructure$(structureId, true).pipe(
              map((structure) => structure.generalInfo.status !== state),
              catchError((error) => of(error.status === 404)),
            ),
          ),
        ),
      );
  }
}

function getDownloadFilename(headers: HttpHeaders, defaultName: string) {
  const contentDisposition = headers.get("Content-Disposition");
  if (!contentDisposition) {
    return defaultName;
  }
  const found = contentDisposition.match(/filename="(.+)"/) ?? [];
  if (found.length < 2) {
    return defaultName;
  }
  return clearWhiteSpaceAndLowerCase(found[1]);
}

export type GetMeasuresFilter = {
  offset?: number;
  limit?: number;
  structureId?: HashId;
  requestedDate?: string;
  fromRequestedDate?: string;
  toRequestedDate?: string;
};

export type GetRequestedDatesFilter = {
  offset?: number;
  limit?: number;
  structureId?: HashId;
  startDate?: string;
  endDate?: string;
};
