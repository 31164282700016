import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import {
  latLng,
  LatLngLiteral,
  LeafletMouseEvent,
  map as leafletMap,
  Map,
  marker,
  Marker,
  tileLayer,
} from "leaflet";
import { geocoder } from "leaflet-control-geocoder";
import { from, Observable, of } from "rxjs";
import { filter, map, mergeMap, startWith } from "rxjs/operators";
import { OpenStreetMapService } from "../../services/network/open-street-map.service";
import { ClickableMarker } from "../../services/utils/ClickableMarker";
import { StructureFormGroup } from "../../services/utils/createStructureFormGroup";

const defaultOptions = {
  layers: [tileLayer(OpenStreetMapService.tileUrl)],
  zoom: 1,
  center: latLng(0, 0),
};

@Component({
  selector: "structure-location-form",
  templateUrl: "./structure-location-form.component.html",
  styleUrls: ["./structure-location-form.component.scss"],
})
export class StructureLocationFormComponent implements OnChanges {
  @Input() public form!: StructureFormGroup;
  @Input() public readMode!: boolean;
  @ViewChild("screenshotableMap") mapRef!: ElementRef<HTMLElement>;

  public mapOptions = defaultOptions;

  public locationMarker$!: Observable<Marker | null>;

  public constructor(
    private osmService: OpenStreetMapService,
    private markerService: ClickableMarker,
  ) {}

  public ngOnChanges(): void {
    this.locationMarker$ = this.form.controls.location.valueChanges.pipe(
      startWith(this.form.value.location),
      map((location) => {
        if (!location?.lat) {
          return null;
        }
        return this.markerService.createMarker(location.lat, location.lng!);
      }),
    );
    this.form.controls.location.valueChanges
      .pipe(
        filter((newLocation) => !!newLocation),
        mergeMap((newLocation) =>
          newLocation!.lat
            ? from(this.takeScreenshot(newLocation as LatLngLiteral))
            : of(null),
        ),
      )
      .subscribe((newThumbnail) => {
        this.form.controls.location.controls!.thumbnail.setValue(newThumbnail, {
          emitEvent: false,
        });
      });
    // if there is a location when the map loads
    // center the map on this location
    if (this.form.value.location?.lat) {
      this.mapOptions = {
        ...defaultOptions,
        center: latLng(
          this.form.value.location.lat,
          this.form.value.location.lng!,
        ),
        zoom: 10,
      };
    }
  }

  public onMapReady(locationMap: Map) {
    if (!this.readMode) {
      geocoder({ defaultMarkGeocode: false })
        .on("markgeocode", (event) => {
          this.form.controls.location.setValue({
            lat: event.geocode.center.lat,
            lng: event.geocode.center.lng,
            thumbnail: null,
          });
          locationMap.fitBounds(event.geocode.bbox);
        })
        .addTo(locationMap);
    }
  }

  public onMapClick(event: LeafletMouseEvent) {
    if (!this.readMode) {
      this.form.controls.location.setValue({
        lat: event.latlng.lat,
        lng: event.latlng.lng,
        thumbnail: null,
      });
    }
  }

  public onClickClear($event: PointerEvent) {
    this.form.controls.location.reset();
    $event.stopPropagation();
  }

  private async takeScreenshot(location: LatLngLiteral): Promise<Blob> {
    const screenshotMap = leafletMap(this.mapRef.nativeElement, {
      layers: [tileLayer(OpenStreetMapService.tileUrl), marker(location)],
      zoom: 12,
      center: location,
    });
    const blob = await this.osmService.createScreenshot(screenshotMap);
    screenshotMap.remove();
    return blob;
  }
}
