<button
  [id]="buttonId"
  [ngClass]="{ small: isSmall }"
  [disabled]="disabled"
  (click)="onToggle()"
  type="button"
  [style.background-color]="state === 'first' ? firstColor : secondColor"
>
  <span
    class="background"
    [ngClass]="state"
    [style.background-color]="ballColor"
  ></span>

  <span
    *ngIf="!isSmall"
    class="first"
    [ngClass]="{ active: state === 'first' }"
  >
    <span>{{ firstStateTranslationKey | translate }}</span>
  </span>

  <span
    *ngIf="!isSmall"
    class="second"
    [ngClass]="{ active: state === 'second' }"
  >
    <span>{{ secondStateTranslationKey | translate }}</span>
  </span>
</button>
