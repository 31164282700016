<div class="body-content">
  <table mat-table [dataSource]="rows" aria-label="list of measures">
    <ng-container cdkColumnDef="requestedDate">
      <th mat-header-cell *cdkHeaderCellDef>
        <structure-measures-table-requested-date-header-cell>
        </structure-measures-table-requested-date-header-cell>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [attr.rowspan]="
          measureRow.isRequestedDate ? measureRow.rowspanProcessed : undefined
        "
        class="date-cell"
        [hidden]="!measureRow.isRequestedDate"
      >
        <ng-container *ngIf="measureRow.isRequestedDate">
          <structure-measures-table-requested-date-cell
            mode="processed"
            [row]="measureRow"
            (amcSelected)="onAmcSelected($event)"
            (toggle)="onToggleMode($event)"
          ></structure-measures-table-requested-date-cell>
        </ng-container>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="noRowMessage">
      <th mat-header-cell *cdkHeaderCellDef hidden></th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [attr.colspan]="columns.length - 2"
        [hidden]="
          !measureRow.isRequestedDate || measureRow.rowspanProcessed !== 1
        "
      >
        {{ "component.measuresTable.noDataToShow" | translate }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="measuringPoint">
      <th mat-header-cell *cdkHeaderCellDef>
        <select-clear-button
          [buttonId]="'select-clear-button-measure-point'"
          [form]="form.get('measurePoints')"
          [options]="measurePoints"
          [labelTranslationKey]="'common.point'"
          [optionTranslations]="measurePointsTranslations"
          [width]="'80px'"
          selectStyle="shrinked"
          class="blue-dark-background"
        ></select-clear-button>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          measureRow.measurePointNumber === 0
            ? ("common.synthesis" | translate)
            : ("common.measurePoint"
              | translate
                : {
                    measurePointNumber: measureRow.measurePointNumber
                  })
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="processingRequestStatus">
      <th mat-header-cell *cdkHeaderCellDef>
        <select-clear-button
          [buttonId]="'select-clear-button-processing-request-status'"
          [form]="form.get('processingRequestStatuses')"
          [options]="processingRequestStatuses"
          [labelTranslationKey]="
            'component.measuresTable.processingRequestStatus'
          "
          [optionTranslations]="processingRequestStatusTranslations"
          class="blue-dark-background"
        ></select-clear-button>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        <processing-requested-status
          *ngIf="measureRow.measurePointNumber === 0"
          [row]="
            requestedDateRowDictionary[measureRow.requestedDate.toISOString()]
          "
        ></processing-requested-status>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="forceProcessing">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "component.measuresTable.forceProcessing" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        <structure-measure-force-processing
          *ngIf="measureRow.measurePointNumber === 0"
          [row]="
            requestedDateRowDictionary[measureRow.requestedDate.toISOString()]
          "
          (forceProcessing)="forceProcessing.emit($event)"
        ></structure-measure-force-processing>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="openSensors">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "common.processeds" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        <div class="actions">
          <button
            *ngIf="measureRow.sensors?.openSensors.length"
            (click)="onMeasureOpenSensorsSelected(measureRow)"
            [id]="
              'structure-measures-table-opensensor-select-button-' +
              measureRow.requestedDate.toISOString() +
              '-' +
              measureRow.measurePointNumber
            "
            mat-mini-fab
            color="primary"
            [matTooltip]="'component.measuresTable.showProcessed' | translate"
            [attr.aria-label]="
              'component.measuresTable.showProcessed' | translate
            "
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            *ngIf="measureRow.sensors?.openSensors.length"
            (click)="downloadAllOpenSensors(measureRow)"
            [id]="
              'structure-measures-table-opensensor-download-button-' +
              measureRow.requestedDate.toISOString() +
              '-' +
              measureRow.measurePointNumber
            "
            mat-mini-fab
            color="primary"
            [matTooltip]="
              'component.measuresTable.downloadProcessed' | translate
            "
            [attr.aria-label]="
              'component.measuresTable.downloadProcessed' | translate
            "
          >
            <icon-download></icon-download>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <ng-container mat-row *cdkRowDef="let measureRow; columns: columns">
      <tr *ngIf="measureRow.isRequestedDate">
        <td class="border-cell" [attr.colspan]="columns.length"></td>
      </tr>
      <tr mat-row></tr>
    </ng-container>
  </table>
</div>
