import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Amc, AmcModeType } from "../../models/amc.model";
import { amcModeToJobConfig } from "../../services/utils/amc-utils";
import { LocalizedDatePipe } from "../../services/utils/LocalizedDate.pipe";
@Component({
  selector: "side-panel-amc",
  templateUrl: "./side-panel-amc.component.html",
})
export class SidePanelAmcComponent implements OnChanges {
  @Input() public amc!: Amc;
  @Input() public mode!: AmcModeType;
  @Output() public closePanelEvent = new EventEmitter<boolean>();

  public title!: string;
  public json!: Record<string, unknown>;

  public constructor(private datePipe: LocalizedDatePipe) {}

  public ngOnChanges(): void {
    this.title = this.getAmcModeTitle(this.amc.id, this.mode);
    this.json = amcModeToJobConfig(
      this.amc.modes.find((mode) => mode.name === this.mode)!,
    );
  }

  public closePanel() {
    this.closePanelEvent.emit(true);
  }

  private getAmcModeTitle(id: number, mode: AmcModeType) {
    const date = this.datePipe.transform(
      new Date(id * 1000),
      "dd-MM-yyyy-HH-mm",
      "UTC",
    );
    return `${date}/${mode}`;
  }
}
