import { Injectable } from "@angular/core";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get nativeWindow(): any {
    return _window();
  }
}
