import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Translation } from "../../models/translation.model";

@Component({
  selector: "select-clear-button",
  templateUrl: "./select-clear-button.component.html",
  styleUrls: ["./select-clear-button.component.scss"],
})
export class SelectClearButtonComponent implements OnInit {
  @Input() public buttonId = "select-clear-button";
  @Input() public selectStyle = "";
  @Input() public form!: FormControl;
  @Input() public options!: unknown[];
  @Input() public labelTranslationKey!: string;
  @Input() public optionTranslations!: Translation[];

  @Input() public width = "180px";

  public selectionForm: FormControl<unknown[]> = new FormControl();

  public ngOnInit() {
    this.selectionForm.patchValue(this.form.value);

    this.form.valueChanges
      .pipe(
        debounceTime(100), //  avoid multiple trigger when it's initializing
        distinctUntilChanged(),
      )
      .subscribe((values) => {
        this.selectionForm.patchValue(values);
      });
    this.selectionForm.valueChanges.subscribe((values) => {
      this.form.patchValue(values);
    });
  }

  public clearSelection() {
    this.selectionForm.patchValue([]);
  }
  public getOptionTranslationKey(option: unknown): Translation | undefined {
    const index = this.options.findIndex((o: unknown) => o === option);
    return this.optionTranslations[index];
  }
}
