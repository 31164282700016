import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Structure } from "../../../../models/structure.model";
import { BusinessService } from "../../../../services/network/business.service";

@Component({
  selector: "structure-measure-spots",
  templateUrl: "./structure-measure-spots.component.html",
  styleUrls: ["./structure-measure-spots.component.scss"],
})
export class StructureMeasureSpotsComponent {
  public structure$: Observable<Structure>;

  public constructor(businessService: BusinessService) {
    this.structure$ = businessService.structureStream();
  }
}
