import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TokenResponse } from "src/app/models/auth.model";
import {
  StructureCreationDTO,
  StructureCreationMeasurePoint,
} from "../../models/structure.model";

const LOCALE_STORAGE_KEY = "LOCALE";
const AUTH_STORAGE_KEY = "shmAuthInfo";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private currentStructureMeasurePoints$ = new BehaviorSubject<
    StructureCreationMeasurePoint[]
  >([]);
  private currentStructureGeneralInfo$ =
    new BehaviorSubject<StructureCreationDTO>({
      name: "",
      type: "bridge",
      address: "",
      contact: "",
      regionId: "",
      hourOfDayToOversight: 0,
      dayOfWeekToOversight: "monday",
      businessType: "shm",
      deploymentFile: undefined,
      customerCode: "",
      purchaseOrder: "",
    });
  private pageHeader = new BehaviorSubject<PageHeader>(emptyHeader);
  public readonly pageHeader$ = this.pageHeader.asObservable();

  /* LOCALE DATA */

  public saveLocale(locale: string) {
    this.saveInLocalStorage(LOCALE_STORAGE_KEY, locale);
  }

  public getLocale(): string | undefined {
    return this.getAnyKeyFromLocalStorage(LOCALE_STORAGE_KEY);
  }

  /* AUTH DATA */

  public storeAuthData(tokenUpdate: TokenResponse) {
    this.saveInLocalStorage(AUTH_STORAGE_KEY, tokenUpdate);
  }

  public getAuthData(): TokenResponse | undefined {
    return this.getJsonKeyFromLocalStorage(AUTH_STORAGE_KEY);
  }

  public deleteAuthData() {
    this.clearInLocalStorage(AUTH_STORAGE_KEY);
  }

  /* OTHER */

  public getCurrentStructureGeneralInfo(): StructureCreationDTO {
    return this.currentStructureGeneralInfo$.value;
  }

  public updateCurrentStructureGeneralInfo(structure: StructureCreationDTO) {
    this.currentStructureGeneralInfo$.next(structure);
  }

  public setPageHeader(header: PageHeader) {
    this.pageHeader.next(header);
  }

  public getCurrentStructureMeasurePoints(): StructureCreationMeasurePoint[] {
    return this.currentStructureMeasurePoints$.value;
  }

  public getCurrentStructureMeasurePointsStream(): Observable<
    StructureCreationMeasurePoint[]
  > {
    return this.currentStructureMeasurePoints$.asObservable();
  }

  public clearMeasurePointsArray() {
    this.currentStructureMeasurePoints$.next([]);
  }

  public buildMeasurePointsArray(numberOfPoints: number) {
    const result = [];
    for (let i = 1; i <= numberOfPoints; i++) {
      result.push({
        comment: "",
        index: i,
      });
    }
    this.currentStructureMeasurePoints$.next(result);
  }

  public removePointFromList(index: number) {
    const copy = this.currentStructureMeasurePoints$.value;
    const indexBasedName = index + 1;
    for (let j = indexBasedName; j < copy.length; j++) {
      copy[j].index = j;
    }
    copy.splice(index, 1);
    this.currentStructureMeasurePoints$.next(copy);
  }

  public addMeasurePoint() {
    const copy = this.currentStructureMeasurePoints$.value;
    copy.push({
      comment: "",
      index: this.currentStructureMeasurePoints$.value.length + 1,
    });
    this.currentStructureMeasurePoints$.next(copy);
  }

  public saveCommentInModel(index: number, comment: string) {
    const copy = this.currentStructureMeasurePoints$.value;
    copy[index].comment = comment;
    this.currentStructureMeasurePoints$.next(copy);
  }

  private saveInLocalStorage(key: string, value: unknown) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value as string);
    }
  }

  private getJsonKeyFromLocalStorage<T = unknown>(key: string): T | undefined {
    const value = this.getAnyKeyFromLocalStorage(key);
    return value ? JSON.parse(value) : undefined;
  }

  private getAnyKeyFromLocalStorage(key: string): string | undefined {
    const value = localStorage.getItem(key);
    return value === null ? undefined : value;
  }

  private clearInLocalStorage(key: string) {
    localStorage.removeItem(key);
  }
}

export const emptyHeader: PageHeader = {
  showHomeButton: false,
  showBackButton: false,
  showNavLinks: false,
  pageTitle: "",
  pageTitleParams: {},
};

export interface PageHeader {
  showHomeButton: boolean;
  showBackButton: boolean;
  showNavLinks: boolean;
  pageTitle: string;
  pageTitleParams?: Record<string, string>;
}
