<div class="file-viewer-container">
  <div class="actions">
    <div class="release-notes" *ngIf="isAReleaseNotes()">
      <h2>
        {{
          "component.fileviewer.releaseNotesVersion"
            | translate
              : {
                  version: this.getReleaseNotesNameToDisplay(displayedFile.name)
                }
        }}
      </h2>

      <button
        id="file-viewer-release-notes-version-select"
        mat-raised-button
        color="primary"
        class="rounded-border-button"
        [matMenuTriggerFor]="menu"
      >
        {{ "component.fileviewer.releaseNotesSelectVersionLabel" | translate }}
        <mat-icon matSuffix> expand_more </mat-icon>
      </button>
      <mat-menu class="version-select" #menu="matMenu">
        <button
          *ngFor="let releaseNote of releaseNotes$ | async"
          id="file-viewer-release-notes-version-menu-button-{{
            releaseNote.version
          }}"
          mat-menu-item
          routerLink="{{ releaseNote.path }}"
        >
          <span>{{ releaseNote.version }}</span>
        </button>
      </mat-menu>
    </div>

    <button
      id="file-viewer-download-button"
      mat-raised-button
      color="primary"
      class="rounded-border-button"
      (click)="downloadFile()"
    >
      {{ "common.download" | translate }}
      <mat-icon matSuffix> download </mat-icon>
    </button>
  </div>

  <div class="content" #fileContent>
    <div class="markdown">
      <markdown [data]="displayedFile.file"></markdown>
    </div>
  </div>
</div>
