export const environment = {
  production: true,
  apiUrl: "https://api-dev-shm.sercel.com",
  translationsUrl:
    "https://shm-sercel-translations.s3.eu-west-3.amazonaws.com/dev/",
  sendInvoiceEmailEnabled: true,
  changeInvoiceDayEnabled: true,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: require("../../package.json").version,
};
