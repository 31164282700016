<app-modal-spinner
  [diameter]="75"
  [displaySpinner]="isDownloadingMeasureRaws$ | async"
></app-modal-spinner>
<div class="body-content">
  <table mat-table [dataSource]="rows" aria-label="list of measures">
    <ng-container cdkColumnDef="requestedDate">
      <th mat-header-cell *cdkHeaderCellDef>
        <structure-measures-table-requested-date-header-cell>
        </structure-measures-table-requested-date-header-cell>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [attr.rowspan]="
          measureRow.isRequestedDate ? measureRow.rowspanRaw : undefined
        "
        class="date-cell"
        [hidden]="!measureRow.isRequestedDate"
      >
        <ng-container *ngIf="measureRow.isRequestedDate">
          <structure-measures-table-requested-date-cell
            mode="raw"
            [row]="measureRow"
            (amcSelected)="onAmcSelected($event)"
            (downloadAllRaws)="downloadAllRaws($event)"
            (toggle)="onToggleMode($event)"
          ></structure-measures-table-requested-date-cell>
        </ng-container>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="measuringPoint">
      <th mat-header-cell *cdkHeaderCellDef>
        <select-clear-button
          [buttonId]="'select-clear-button-measure-point'"
          [form]="form.get('measurePoints')"
          [options]="measurePoints"
          [labelTranslationKey]="'common.point'"
          [optionTranslations]="measurePointsTranslations"
          [width]="'80px'"
          selectStyle="shrinked"
          class="blue-dark-background"
        ></select-clear-button>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          measureRow.measurePointNumber === 0
            ? ("common.synthesis" | translate)
            : ("common.measurePoint"
              | translate
                : {
                    measurePointNumber: measureRow.measurePointNumber
                  })
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="active">
      <th mat-header-cell *cdkHeaderCellDef>
        <select-clear-button
          [buttonId]="'select-clear-button-is-measure-point-active'"
          [form]="form.get('isMeasurePointActive')"
          [options]="measurePointActiveOptions"
          [labelTranslationKey]="'common.status'"
          [optionTranslations]="measurePointActiveTranslations"
          [width]="'80px'"
          selectStyle="shrinked"
          class="blue-dark-background"
        ></select-clear-button>
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          measureRow.active === undefined
            ? "-"
            : measureRow.active
            ? ("common.activated" | translate)
            : ("common.deactivated" | translate)
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="temperature">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "component.measuresTable.temperature" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          (measureRow.sensors?.temperature ?? undefined) !== undefined
            ? (measureRow.sensors.temperature | number: "1.0-2") + "°C"
            : "-"
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="humidity">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "component.measuresTable.humidity" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          (measureRow.sensors?.humidity ?? undefined) !== undefined
            ? (measureRow.sensors.humidity | number: "1.0-2") + "%"
            : "-"
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="tiltPitch">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "component.measuresTable.rollTilt" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        [hidden]="measureRow.isRequestedDate"
      >
        {{
          (measureRow.sensors?.tilt ?? undefined) !== undefined
            ? (measureRow.sensors.tilt.roll | number: "1.0-2") +
              "°/" +
              (measureRow.sensors.tilt.pitch | number: "1.0-2") +
              "°"
            : "-"
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="raw">
      <th mat-header-cell *cdkHeaderCellDef>
        {{ "common.raws" | translate }}
      </th>
      <td
        mat-cell
        *cdkCellDef="let measureRow"
        class="raw-button"
        [hidden]="measureRow.isRequestedDate"
      >
        <ng-container *ngIf="measureRow.sensors?.raw">
          <ng-container
            *ngxPermissionsOnly="'structure:measure:raw:unlimited:read'"
          >
            <unlocked-icon
              class="unlocked"
              *ngIf="measureRow.sensors?.raw.customerDownloadAuthorization"
            ></unlocked-icon>

            <mat-icon
              class="locked"
              *ngIf="!measureRow.sensors?.raw.customerDownloadAuthorization"
              >lock</mat-icon
            >

            <button
              [id]="
                'structure-measures-table-download-raw-button-' +
                measureRow.requestedDate.toISOString() +
                '-' +
                measureRow.measurePointNumber
              "
              mat-mini-fab
              color="primary"
              (click)="downloadRaw(measureRow)"
              [matTooltip]="'component.measuresTable.downloadRaw' | translate"
              [attr.aria-label]="
                'component.measuresTable.downloadRaw' | translate
              "
            >
              <icon-download></icon-download>
            </button>
          </ng-container>
          <ng-container
            *ngxPermissionsExcept="'structure:measure:raw:unlimited:read'"
          >
            <ng-container
              *ngIf="
                measureRow.sensors?.raw.customerDownloadAuthorization;
                else elseTemplate
              "
            >
              <button
                [id]="
                  'structure-measures-table-download-raw-button-' +
                  measureRow.requestedDate.toISOString() +
                  '-' +
                  measureRow.measurePointNumber
                "
                mat-mini-fab
                color="primary"
                (click)="downloadRaw(measureRow)"
                [matTooltip]="'component.measuresTable.downloadRaw' | translate"
                [attr.aria-label]="
                  'component.measuresTable.downloadRaw' | translate
                "
              >
                <icon-download></icon-download>
              </button>
            </ng-container>
            <ng-template #elseTemplate>
              <button
                mat-mini-fab
                [id]="
                  'structure-measures-table-authorize-raw-button-' +
                  measureRow.requestedDate.toISOString() +
                  '-' +
                  measureRow.measurePointNumber
                "
                color="accent"
                (click)="onAuthorizationDateSelected(measureRow.requestedDate)"
                [disabled]="!hasAuthorizeRawPermission"
                [matTooltip]="
                  'component.measuresTable.authorizeRaw' | translate
                "
                [attr.aria-label]="
                  'component.measuresTable.authorizeRaw' | translate
                "
              >
                <mat-icon>lock</mat-icon>
              </button>
            </ng-template>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <ng-container mat-row *cdkRowDef="let measureRow; columns: columns">
      <tr *ngIf="measureRow.isRequestedDate">
        <td class="border-cell" [attr.colspan]="columns.length"></td>
      </tr>
      <tr mat-row></tr>
    </ng-container>
  </table>
</div>
