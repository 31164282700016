import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnChanges } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { map, mergeMap, startWith } from "rxjs/operators";
import { Structure } from "src/app/models/structure.model";

@Component({
  selector: "measure-points-table-full",
  templateUrl: "./measure-points-table-full.component.html",
  styleUrls: ["./measure-points-table-full.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ transform: "scale(0.5)", opacity: 0 }),
        animate("0.5s cubic-bezier(.2, -0.6, 0.2, 1.5)"),
        style({ transform: "scale(1)", opacity: 1 }),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MeasurePointTableFullComponent implements OnChanges {
  @Input() public structure!: Structure;
  // provide a reactive form to make the component editable
  @Input() public deploymentReportForm?: DeploymentReportForm;

  public readOnly!: boolean;
  public availableSerialNumbersCount$?: Observable<number>;
  public serialNumbersAutocompletes?: Array<Observable<string[]>>;
  public canSeeDevices: boolean;
  public displayedTables!: string[];

  private allSerialNumbers: string[] = [];

  public constructor(permissionsService: NgxPermissionsService) {
    this.canSeeDevices = !!permissionsService.getPermission(
      "structure:device:read",
    );
  }

  public ngOnChanges() {
    this.readOnly = !this.deploymentReportForm;
    if (!this.deploymentReportForm && this.canSeeDevices) {
      this.deploymentReportForm = this.createFormFromStructure();
    }
    if (this.deploymentReportForm) {
      this.initDeploymentReportData(this.deploymentReportForm);
    }
    this.displayedTables = ["measurePoints"];
    if (this.canSeeDevices) {
      this.displayedTables.push("deploymentReport");
    }
    if (this.structure.generalInfo.status === "monitored") {
      this.displayedTables.push("activation");
    }
  }

  public isSerialNumberCorrect(input: string) {
    return this.allSerialNumbers.some((serialNumber) => input === serialNumber);
  }

  private createFormFromStructure(): DeploymentReportForm {
    return new FormGroup({
      devices: new FormArray(
        this.structure.measuringPoints
          .filter((point) => point.index !== 0)
          .map((point) => {
            const device = this.structure.deploymentReport?.devices.find(
              (d) => d.measuringPoint === point.index,
            ) ?? {
              measuringPoint: point.index,
              serialNumber: "",
              reportComment: "",
            };

            return new FormGroup({
              measuringPoint: new FormControl(device.measuringPoint, {
                nonNullable: true,
              }),
              serialNumber: new FormControl(device.serialNumber, {
                nonNullable: true,
              }),
              reportComment: new FormControl(device.reportComment, {
                nonNullable: true,
              }),
            });
          }),
      ),
    });
  }

  private initDeploymentReportData(form: DeploymentReportForm) {
    this.allSerialNumbers = this.structure.devices
      .filter((device) => device.type === "node")
      .map((device) => device.serialNumber)
      .sort((serialA, serialB) =>
        serialA.localeCompare(serialB, undefined, {
          numeric: true,
        }),
      );
    const availableSerialNumbers$ = form.controls.devices.valueChanges.pipe(
      startWith(form.value.devices!),
      map((formValue) => formValue.map((device) => device.serialNumber)),
      map((takenSerialNumbers) =>
        this.allSerialNumbers.filter(
          (serialNumber) => !takenSerialNumbers.includes(serialNumber),
        ),
      ),
    );
    this.availableSerialNumbersCount$ = availableSerialNumbers$.pipe(
      map((serialNumbers) => serialNumbers.length),
    );
    this.serialNumbersAutocompletes = form.controls.devices.controls.map(
      (deviceControl) =>
        this.deviceControlToAutocompleteList$(
          deviceControl,
          availableSerialNumbers$,
        ),
    );
  }

  private deviceControlToAutocompleteList$(
    deviceControl: DeviceFormGroup,
    availableSerialNumbers$: Observable<string[]>,
  ) {
    return deviceControl.controls.serialNumber.valueChanges.pipe(
      startWith(deviceControl.value.serialNumber!),
      mergeMap((input) =>
        availableSerialNumbers$.pipe(
          map((availableSerialNumbers) =>
            this.getMatchingSerialNumbers(input, availableSerialNumbers),
          ),
        ),
      ),
    );
  }

  private getMatchingSerialNumbers(
    input: string,
    availableSerialNumbers: string[],
  ) {
    return availableSerialNumbers.filter((serialNumber) =>
      serialNumber.toLocaleLowerCase().includes(input.toLocaleLowerCase()),
    );
  }
}

type DeploymentReportForm = FormGroup<{
  devices: FormArray<DeviceFormGroup>;
}>;

type DeviceFormGroup = FormGroup<{
  measuringPoint: FormControl<number>;
  serialNumber: FormControl<string>;
  reportComment: FormControl<string>;
}>;
