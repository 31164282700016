<side-panel (closePanelEvent)="closePanel()">
  <div class="title">
    {{
      "component.sidePanelOpenSensors.title"
        | translate
          : {
              measuringPointName:
                measure.measurePointNumber === 0
                  ? ("common.synthesis" | translate)
                  : ("common.measurePoint"
                    | translate
                      : { measurePointNumber: measure.measurePointNumber }),
              formattedDate:
                measure.requestedDate | localizedDate: "short" : "UTC"
            }
    }}
  </div>

  <div class="side-panel-body">
    <div class="header-select">
      <mat-checkbox
        id="side-panel-opensensors-toggle-all-checkbox"
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="toggleAll()"
        [checked]="isAllSelected()"
        >{{
          "component.sidePanelOpenSensors.AllRaws" | translate
        }}</mat-checkbox
      >
    </div>

    <mat-selection-list #selection id="side-panel-opensensors-checkbox-list">
      <mat-list-option
        *ngFor="let openSensor of measure.sensors.openSensors"
        [id]="'side-panel-opensensors-' + openSensor.name + '-checkbox'"
        color="primary"
        checkboxPosition="before"
        [value]="openSensor"
      >
        <div
          class="committed"
          *ngxPermissionsOnly="'structure:opensensor:uncommitted:read'"
        >
          <ng-container *ngIf="committedStates[openSensor.id] | async as state">
            <mat-icon *ngIf="state.committed">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!state.committed">pending</mat-icon>
          </ng-container>
        </div>

        <div mat-line>{{ openSensor.name }}</div>
      </mat-list-option>
    </mat-selection-list>
    <div class="footer">
      <button
        id="side-panel-opensensors-download-button"
        mat-mini-fab
        (click)="download()"
        color="primary"
        [matTooltip]="'component.sidePanelOpenSensors.download' | translate"
        [attr.aria-label]="
          'component.sidePanelOpenSensors.download' | translate
        "
      >
        <icon-download></icon-download>
      </button>
    </div>
  </div>
</side-panel>
