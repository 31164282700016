import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable, of, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  startWith,
} from "rxjs/operators";
import { EventStructure } from "../../models/event-structure.model";
import { BusinessService } from "../../services/network/business.service";
import { NotificationService } from "../../services/network/notification.service";

@Component({
  selector: "structure-select-autocomplete",
  templateUrl: "structure-select-autocomplete.component.html",
  styleUrls: ["structure-select-autocomplete.component.scss"],
})
export class StructureSelectAutoCompleteComponent implements OnInit {
  @Input() public form!: FormControl<string>;
  public nameField = new FormControl<EventStructure | string>("");

  public structures$!: Observable<EventStructure[]>;

  private subscriptions = new Subscription();
  constructor(
    private notificationService: NotificationService,
    private businessService: BusinessService,
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          startWith(this.form.value),
          mergeMap((structureId) => {
            if (structureId !== undefined && structureId !== "") {
              return this.businessService.getStructure$(structureId).pipe(
                map((structure) => ({
                  id: structure.generalInfo.id,
                  name: structure.generalInfo.name,
                })),
              );
            }
            return of("");
          }),
        )
        .subscribe((value) => this.nameField.setValue(value)),
    );

    this.structures$ = this.nameField.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((event) => (typeof event === "string" ? event : event?.name)),
      mergeMap((name) =>
        name ? this.notificationService.getEventStructures(name) : of([]),
      ),
    );
  }

  public displayName(structure: EventStructure) {
    return structure?.name ?? "";
  }

  public select($event: MatAutocompleteSelectedEvent) {
    const { id } = $event.option.value;
    this.form.patchValue(id);
  }
}
