import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { StorageService } from "src/app/services/utils/storage.service";
import { AuthService } from "../../../services/auth/auth.service";
import { BusinessService } from "../../../services/network/business.service";
@Component({
  selector: "structure-details-page",
  templateUrl: "./structure-details-page.html",
  styleUrls: ["./structure-details-page.scss"],
})
export class StructureDetailsPageComponent implements OnInit {
  public tabGroup$!: Observable<
    Array<{
      title: string;
      icon: string;
      route: string;
      permissions: string[] | string;
    }>
  >;

  public constructor(
    private authService: AuthService,
    private router: Router,
    private businessService: BusinessService,
    private storageService: StorageService,
  ) {}

  public ngOnInit() {
    const tabOverview = {
      title: "page.structure.overview.title",
      icon: "info",
      route: "overview",
      permissions: [
        "structure:created:read",
        "structure:deployed:read",
        "structure:validated:read",
        "structure:monitored:read",
        "structure:demobilized:read",
      ],
    };
    const tabSensors = {
      title: "page.structure.sensors.title",
      icon: "router",
      route: "sensors",
      permissions: "structure:device:read",
    };
    const tabConfig = {
      title: "page.structure.config.title",
      icon: "settings",
      route: "config",
      permissions: "ui:amc:show",
    };
    const tabMeasureSpots = {
      title: "page.structure.measureSpots.title",
      icon: "control_camera",
      route: "measure-spots",
      permissions: [
        "structure:created:read",
        "structure:deployed:read",
        "structure:validated:read",
        "structure:monitored:read",
        "structure:demobilized:read",
      ],
    };
    const tabMeasures = {
      title: "page.structure.measures.title",
      icon: "measures",
      route: "measures",
      permissions: "structure:measure:read",
    };
    const tabTraffic = {
      title: "page.structure.traffic.title",
      icon: "show_chart",
      route: "traffic",
      permissions: "structure:traffic:read",
    };

    const allTabs: Tab[] = [
      tabOverview,
      tabSensors,
      tabConfig,
      tabMeasureSpots,
      tabMeasures,
      tabTraffic,
    ];

    this.tabGroup$ = this.businessService.structureStream().pipe(
      map((structure) => {
        let tabs: Tab[] = [];
        const scopes = this.authService.getScopes();
        allTabs.forEach((tab) => {
          if (scopes.some((scope) => tab.permissions.includes(scope)))
            tabs.push(tab);
        });
        if (
          structure.generalInfo.status !== "monitored" &&
          structure.generalInfo.status !== "demobilized"
        ) {
          tabs = tabs.filter(
            (tab) => !["config", "measures"].includes(tab.route),
          );
        }

        return tabs;
      }),
    );

    this.businessService
      .structureStream()
      .pipe(take(1))
      .subscribe((structure) => {
        this.storageService.setPageHeader({
          showHomeButton: true,
          showBackButton: false,
          showNavLinks: false,
          pageTitle: structure.generalInfo.name,
        });
      });
  }

  public redirectToLanding() {
    return this.router.navigate(["landing"]);
  }
}

interface Tab {
  title: string;
  icon: string;
  route: string;
  permissions: string | string[];
}
