import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  emptyHeader,
  StorageService,
} from "src/app/services/utils/storage.service";
import { Customer } from "../../../models/customer.model";
import {
  LocationFormValue,
  StructureCreationDTO,
} from "../../../models/structure.model";
import { AuthService } from "../../../services/auth/auth.service";
import { BillingService } from "../../../services/network/billing.service";
import { createStructureFormGroup } from "../../../services/utils/createStructureFormGroup";

@Component({
  selector: "general-info-page",
  templateUrl: "./general-info-page.html",
  styleUrls: ["./general-info-page.scss"],
})
export class GeneralInfoPage implements OnInit {
  public formGeneralInfo = createStructureFormGroup();

  public constructor(
    private router: Router,
    private location: Location,
    private storage: StorageService,
    private authService: AuthService,
    private billingService: BillingService,
  ) {}

  public ngOnInit() {
    this.storage.setPageHeader(emptyHeader);
    const customerId = this.authService.getCustomerId();
    this.billingService
      .getCustomer(customerId)
      .subscribe((customer: Customer) => {
        this.formGeneralInfo.controls["customerCode"].setValue(
          customer.defaultCustomerCode,
        );
        this.formGeneralInfo.controls["purchaseOrder"].setValue(
          customer.defaultPurchaseOrder,
        );
      });
  }

  public redirectToMeasurePoints() {
    const {
      hourOfDayToOversight,
      dayOfWeekToOversight,
      name,
      contact,
      regionId,
      address,
      location,
      type,
      businessType,
      deploymentFile,
      customerCode,
      purchaseOrder,
    } = this.formGeneralInfo.getRawValue();
    this.storage.updateCurrentStructureGeneralInfo({
      hourOfDayToOversight,
      dayOfWeekToOversight,
      name,
      contact,
      regionId,
      address,
      location: location?.lat ? (location as LocationFormValue) : undefined,
      type,
      businessType,
      deploymentFile,
      customerCode,
      purchaseOrder,
      // this cast is required because the form properties are nullable
      // at this point, they should all be provided
    } as StructureCreationDTO);
    return this.router.navigate(["structure-creation-points"]);
  }

  public backToPreviousPage() {
    this.location.back();
  }
}
