<mat-form-field class="white-background">
  <mat-label>
    {{ "common.type" | translate }}
  </mat-label>
  <mat-select
    [formControl]="form"
    disableOptionCentering
    panelClass="filter-type-component-select-panel"
    #select
    multiple
  >
    <div class="select-all">
      <mat-checkbox
        [(ngModel)]="allSelected"
        ngDefaultControl
        [ngModelOptions]="{ standalone: true }"
        (change)="toggleAllSelection()"
      >
        {{ "common.all" | translate }}
      </mat-checkbox>
    </div>
    <mat-option
      *ngFor="let type of types"
      class="option-type"
      [value]="type"
      (onSelectionChange)="optionClick()"
      >{{ "common." + type | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
