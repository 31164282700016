import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { AmcModeType } from "../../../../models/amc.model";
import { AmcLocation, MeasureItem } from "../../../../models/measure.model";
import {
  ProcessingRequest,
  ProcessingRequestStatusesFilter,
} from "../../../../models/processing-request.model";
import { Structure } from "../../../../models/structure.model";

@Injectable({ providedIn: "root" })
export class StructureMeasuresPageStore {
  private filterForm = new FormGroup({
    requestedDate: new FormGroup({
      start: new FormBuilder().nonNullable.control(""),
      end: new FormBuilder().nonNullable.control(""),
    }),
    measurePoints: new FormBuilder().nonNullable.control<number[]>([]),
    isAmcSync: new FormBuilder().nonNullable.control<boolean[]>([]),
    amcMode: new FormBuilder().nonNullable.control<AmcModeType[]>([]),
    processingRequestStatuses: new FormBuilder().nonNullable.control<
      ProcessingRequestStatusesFilter[]
    >([]),
    isMeasurePointActive: new FormBuilder().nonNullable.control<boolean[]>([]),
  });

  public structure!: Structure;
  private rows$ = new BehaviorSubject<MeasureRow[]>([]);
  private displayMode$ = new BehaviorSubject<DisplayMode>("processed");
  private scrollToRequestedDate$ = new BehaviorSubject<Date>(new Date());

  public getStructureId(): string {
    return this.structure.generalInfo.id;
  }

  public getFilterForm() {
    return this.filterForm;
  }

  public getDisplayMode$(): Observable<DisplayMode> {
    return this.displayMode$.asObservable();
  }

  public getDisplayMode(): DisplayMode {
    return this.displayMode$.value;
  }

  public getScrollToRequestedDate$(): Observable<Date> {
    return this.scrollToRequestedDate$.asObservable();
  }

  public toggleDisplayMode(requestedDate?: Date) {
    if (requestedDate !== undefined)
      this.updateScrollToRequestedDate(requestedDate);
    this.displayMode$.next(
      this.getDisplayMode() === "processed" ? "raw" : "processed",
    );
  }

  public updateScrollToRequestedDate(requestedDate: Date) {
    this.scrollToRequestedDate$.next(requestedDate);
  }

  public getRows$() {
    return this.rows$.asObservable();
  }

  public getRows() {
    return this.rows$.value;
  }

  public updateRows(newRows: MeasureRow[]) {
    this.rows$.next(newRows);
  }

  public getRowGroupForDate(requestedDate: Date): RowsGroup | undefined {
    const group = this.rows$.value.filter(
      (row) => row.requestedDate.getTime() === requestedDate.getTime(),
    ) as RowsGroup;
    return group.length > 0 ? group : undefined;
  }

  public resetRowsAndScroll() {
    this.rows$.next([]);
    this.scrollToRequestedDate$ = new BehaviorSubject<Date>(new Date());
  }

  public getStructureMeasurePointNumbers() {
    return [
      ...new Set(
        this.structure.deploymentReport.devices.map((d) => d.measuringPoint),
      ),
    ].sort((a, b) => a - b);
  }
}

export function isReceivedRow(row: MeasureRow): row is ReceivedRow {
  return "isReceived" in row && row.isReceived;
}

export function isRequestedDateRow(row: MeasureRow): row is RequestedDateRow {
  return "isRequestedDate" in row && row.isRequestedDate;
}

export type RowsGroup = [
  RequestedDateRow,
  ...Array<ReceivedRow | NotReceivedRow>,
];

export interface ReceivedRow extends Omit<MeasureItem, "active"> {
  isReceived: true;
  active: boolean;
  displayedInRaw: boolean;
  displayedInProcessed: boolean;
}

export interface RequestedDateRow {
  isRequestedDate: true;
  requestedDate: Date;
  isOld?: boolean;
  isAmcSync: boolean;
  receivedRawMeasurePointNumbers: number[];
  allMeasures: MeasureItem[];
  // set to false if not yet fetched
  lastProcessingRequest?: false | ProcessingRequest;
  amc: AmcLocation;
  displayedInProcessed: boolean;
  rowspanProcessed: number;
  displayedInRaw: boolean;
  rowspanRaw: number;
}

export interface NotReceivedRow {
  isReceived: false;
  requestedDate: Date;
  measurePointNumber: number;
  active: boolean;
  displayedInProcessed: boolean;
  displayedInRaw: boolean;
}

export type MeasureRow = RequestedDateRow | ReceivedRow | NotReceivedRow;

export type DisplayMode = "processed" | "raw";

export interface MeasureTableFilters {
  requestedDate: { start: string; end: string };
  measurePoints: number[];
  isAmcSync: boolean[];
  amcMode: AmcModeType[];
  processingRequestStatuses: ProcessingRequestStatusesFilter[];
  isMeasurePointActive: boolean[];
}
