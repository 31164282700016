<div class="retrieve-panel">
  <button
    mat-raised-button
    id="structure-sensors-table-retrieve-demand-button"
    class="retrieve-demand-button"
    color="primary"
    (click)="onRetrieve()"
    *ngxPermissionsOnly="'structure:retrieve:create'"
  >
    <mat-icon>router</mat-icon>
    {{ "component.retrievePanel.retrieveDemand" | translate }}
  </button>
  <ng-container *ngIf="(retrieveId$ | async) > 0">
    <card
      id="structure-sensors-table-last-retrieve-date"
      *ngxPermissionsOnly="'structure:retrieve:read'"
    >
      <div class="last-retrieve-date">
        <mat-icon>send</mat-icon>
        <span>
          {{
            "component.retrievePanel.demandDoneOn"
              | translate
                : {
                    date: retrieveId$ | async | localizedDate: "short" : "UTC"
                  }
          }}
        </span>
      </div>
    </card>
  </ng-container>
</div>
