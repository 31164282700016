import { animate, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { BehaviorSubject, EMPTY } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
  ConfigEmail,
  ConfigPlatformFormGroup,
} from "../../models/config-platform.model";
import { BillingService } from "../../services/network/billing.service";
import { SnackbarService } from "../../services/utils/snackbar.service";
import { StorageService } from "../../services/utils/storage.service";
@Component({
  selector: "config-page",
  templateUrl: "./config-page.html",
  styleUrls: ["./config-page.scss"],
  animations: [
    trigger("itemAnimation", [
      transition(":enter", [
        style({ transform: "scale(0.8)", opacity: 0 }), // initial
        animate(
          "0.5s cubic-bezier(0, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 }),
        ), // final
      ]),
    ]),
  ],
})
export class ConfigPageComponent implements OnInit {
  @ViewChild("addressEmailInput") private addressEmailInput!: ElementRef;

  public form: ConfigPlatformFormGroup;
  public emailForm?: FormControl<string>;
  public displayAddEmail = false;
  public changeInvoiceDayEnabled: boolean;

  public isLoading$ = new BehaviorSubject(true);

  public constructor(
    private billingService: BillingService,
    private storage: StorageService,
    public dialog: MatDialog,
    private snackBar: SnackbarService,
  ) {
    this.form = new FormGroup({
      invoiceDay: new FormBuilder().nonNullable.control<number>(0, [
        Validators.required,
        Validators.min(1),
        Validators.max(31),
      ]),
      invoiceRecipients: new FormBuilder().nonNullable.control<ConfigEmail[]>(
        [],
      ),
    });

    this.changeInvoiceDayEnabled = environment.changeInvoiceDayEnabled;
  }

  public ngOnInit() {
    this.storage.setPageHeader({
      showHomeButton: true,
      showBackButton: false,
      showNavLinks: false,
      pageTitle: "page.config.title",
    });

    this.billingService.configStream().subscribe((config) => {
      if (config) {
        this.form.setValue(config);
        this.isLoading$.next(false);
      }
    });
  }

  public displayAddEmailInput() {
    this.emailForm = new FormBuilder().nonNullable.control("", [
      Validators.required,
      Validators.email,
    ]);
    this.displayAddEmail = true;
    setTimeout(() => this.addressEmailInput.nativeElement.focus(), 0);
  }
  public cancelAddEmail() {
    this.displayAddEmail = false;
  }

  public addEmail() {
    this.isLoading$.next(true);
    const oldForm = this.form.getRawValue();
    const config = {
      ...oldForm,
      invoiceRecipients: _.uniqBy(
        [...oldForm.invoiceRecipients, { email: this.emailForm!.value }],
        "email",
      ),
    };
    const { invoiceDay, invoiceRecipients } = config;
    this.billingService
      .updateConfigPlatform({ invoiceDay, invoiceRecipients })
      .subscribe(() => {});
    this.displayAddEmail = false;
  }

  public async sendEmail(email: string) {
    this.billingService.testAddressEmail(email).subscribe(() =>
      this.snackBar.open("page.config.snackbar.emailVerificationSended", {
        interpolationParams: { email },
      }),
    );
  }
  public removeEmail(email: string) {
    const dialogRef = this.dialog.open(DialogRemoveEmail, {
      autoFocus: false,
    });

    dialogRef
      .afterClosed()
      .pipe(
        mergeMap((result) => {
          if (result) {
            this.isLoading$.next(true);
            const oldForm = this.form.getRawValue();
            const config = {
              ...oldForm,
              invoiceRecipients: oldForm.invoiceRecipients.filter(
                (e) => e.email !== email,
              ),
            };
            const { invoiceDay, invoiceRecipients } = config;

            return this.billingService.updateConfigPlatform({
              invoiceDay,
              invoiceRecipients,
            });
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public saveConfig() {
    this.isLoading$.next(true);
    const { invoiceDay, invoiceRecipients } = this.form.getRawValue();
    this.billingService
      .updateConfigPlatform({ invoiceDay, invoiceRecipients })
      .subscribe(() => {
        this.snackBar.open("page.config.snackbar.invoiceDayUpdatedWithSuccess");
        this.form.markAsPristine();
      });
  }
}

@Component({
  selector: "dialog-remove-email",
  template: `
    <h2 mat-dialog-title>
      {{ "page.config.dialogRemoveEmail.title" | translate }}
    </h2>
    <div class="dialog-body" mat-dialog-content>
      <p>{{ "page.config.dialogRemoveEmail.content" | translate }}</p>
    </div>
    <mat-dialog-actions class="dialog-actions">
      <button
        mat-raised-button
        id="dialog-remove-email-yes-button"
        class="dark"
        [mat-dialog-close]="true"
      >
        {{ "common.yes" | translate }}
      </button>
      <button
        mat-raised-button
        id="dialog-remove-email-no-button"
        class="dark"
        mat-dialog-close
      >
        {{ "common.no" | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ["./config-page.scss"],
})
export class DialogRemoveEmail {}
