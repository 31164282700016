<div class="side-panel">
  <div class="side-panel-content">
    <div class="title-line">
      <h3><ng-content select=".title"></ng-content></h3>
      <div class="buttons-group">
        <ng-content select="#side-panel-download-button"></ng-content>
        <button
          mat-icon-button
          id="side-panel-close-button"
          color="accent"
          (click)="closePanel()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="side-panel-body">
      <ng-content select=".side-panel-body"></ng-content>
    </div>
  </div>
</div>
