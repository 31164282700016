<form class="date-range-picker" [formGroup]="dateRangeFormGroup">
  <mat-form-field [ngClass]="pickerStyle">
    <mat-icon
      *ngIf="pickerStyle === 'rounded'"
      fontSet="material-icons-outlined"
      >filter_alt</mat-icon
    >
    <mat-label>{{ label | translate }}</mat-label>
    <mat-date-range-input
      [rangePicker]="picker"
      [max]="mostRecentDate$ | async"
    >
      <input
        id="structure-measures-table-date-picker-start"
        matStartDate
        placeholder="{{ 'common.start' | translate }}"
        formControlName="start"
      />
      <input
        id="structure-measures-table-date-picker-end"
        matEndDate
        placeholder="{{ 'common.end' | translate }}"
        formControlName="end"
      />
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>
