import { Injectable } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import {
  eventTypesByCategory,
  logEventTypes,
  notificationEventTypes,
} from "../../models/remarkable-event.model";

@Injectable({ providedIn: "root" })
export class EventsTypesService {
  public constructor(private permissionsService: NgxPermissionsService) {}

  public getLogCategories() {
    return this.getCategories(logEventTypes);
  }

  public getNotificationCategories() {
    return this.getCategories(notificationEventTypes);
  }

  public getNotificationTypes() {
    const authorized = this.getAuthorizedEvents();
    return notificationEventTypes.filter((type) => authorized.includes(type));
  }

  public getCategoryFromEventType(eventType: string) {
    return eventTypesByCategory.find((category) =>
      category.types.includes(eventType),
    )!.name;
  }

  private getCategories(typesFilter: readonly string[]) {
    const authorized = this.getAuthorizedEvents();
    return eventTypesByCategory
      .map((category) => ({
        ...category,
        types: category.types
          .filter((type) => authorized.includes(type))
          .filter((type) => typesFilter.includes(type)),
      }))
      .filter((category) => category.types.length > 0);
  }

  private getAuthorizedEvents() {
    const permissions = Object.keys(this.permissionsService.getPermissions());
    return permissions
      .filter((permission) => permission.startsWith("notification:"))
      .map((permission) => permission.split(":")[1]);
  }
}
