<div class="invoices-page">
  <form class="invoice-day">
    <card>
      <mat-form-field>
        <mat-label>{{ 'page.config.invoiceDay' | translate }}</mat-label>
        <input
          id="invoices-page-invoice-day"
          matInput
          type="number"
          readonly="true"
          [value]="(config | async)?.invoiceDay"
        />
      </mat-form-field>
    </card>
  </form>

  <invoice-table></invoice-table>
</div>
