import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/services/auth/auth.guard";
import { LandingPage } from "./landing-page";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "landing",
        component: LandingPage,
        canActivate: [AuthGuard],
      },
      { path: "**", redirectTo: "/landing" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPageRoutingModule {}
