import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ConfigPlatform } from "../../models/config-platform.model";
import { Customer } from "../../models/customer.model";
import { Invoice } from "../../models/invoice.model";
import { Pagination } from "../../models/pagination.model";
import { DownloadFileService } from "../utils/download-file.service";
import { ERROR_INTERCEPTOR_SKIP_HEADER } from "./http-error.interceptor";
@Injectable({
  providedIn: "root",
})
export class BillingService {
  private readonly serviceUrl: string = "api/v1/billing";
  private readonly config$: ReplaySubject<ConfigPlatform>;

  public constructor(
    private http: HttpClient,
    private downloadFileService: DownloadFileService,
  ) {
    this.config$ = new ReplaySubject<ConfigPlatform>(1);
  }

  public configStream(): Observable<ConfigPlatform> {
    return this.config$.asObservable();
  }

  public getCustomer(customerId: string) {
    return this.http.get<Customer>(
      `${environment.apiUrl}/${this.serviceUrl}/customer/${customerId}`,
    );
  }

  public getInvoices(fromMonth: string): Observable<Invoice[]> {
    return this.http
      .get<Pagination<Invoice>>(
        `${environment.apiUrl}/${this.serviceUrl}/invoice`,
        {
          params: new HttpParams().set("fromMonth", fromMonth),
        },
      )
      .pipe(map((result) => result.data));
  }

  public getInvoice(
    id: string,
    skipErrorInterceptor = false,
  ): Observable<Invoice> {
    const headers = skipErrorInterceptor
      ? new HttpHeaders({
          [ERROR_INTERCEPTOR_SKIP_HEADER]: "",
        })
      : undefined;
    return this.http.get<Invoice>(
      `${environment.apiUrl}/${this.serviceUrl}/invoice/${id}`,
      { headers },
    );
  }

  public doesInvoiceExist(id: string) {
    return this.getInvoice(id, true).pipe(
      map(() => true),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of(false);
        } else {
          throw error;
        }
      }),
    );
  }

  public downloadInvoiceFile(invoice: Invoice) {
    const url = `${environment.apiUrl}/api/v1${invoice.file}`;
    return this.http
      .get(url, { responseType: "blob", observe: "response" })
      .pipe(
        tap((res) => {
          const contentType = res.headers.get("Content-Type")!;
          const filename = /filename="(.*)"/.exec(
            res.headers.get("Content-Disposition")!,
          )![1];
          const file = res.body;

          this.downloadFileService.downloadFile(file, contentType, filename);
        }),
      );
  }

  public emailInvoice(invoiceId: string) {
    return this.http.post<undefined>(
      `${environment.apiUrl}/${this.serviceUrl}/invoice/${invoiceId}/sendEmail`,
      {},
    );
  }

  public createInvoice(month: string) {
    return this.http.post<Invoice>(
      `${environment.apiUrl}/${this.serviceUrl}/invoice`,
      {
        month,
      },
    );
  }

  public updateConfigPlatform(config: ConfigPlatform) {
    return this.http
      .put<ConfigPlatform>(
        `${environment.apiUrl}/${this.serviceUrl}/config`,
        config,
      )
      .pipe(
        tap((newConfig) => {
          this.config$.next(newConfig);
        }),
      );
  }

  public getConfigPlatform() {
    this.http
      .get<ConfigPlatform>(`${environment.apiUrl}/${this.serviceUrl}/config`)
      .subscribe((newConfig) => {
        this.config$.next(newConfig);
      });
  }

  public testAddressEmail(email: string) {
    return this.http.post<string>(
      `${environment.apiUrl}/${this.serviceUrl}/config/email/test`,
      {
        email,
      },
    );
  }

  public getCustomers(
    offset = 0,
    limit = 20,
  ): Observable<Pagination<Customer>> {
    const queryParams = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
      },
    };
    return this.http.get<Pagination<Customer>>(
      `${environment.apiUrl}/${this.serviceUrl}/customer`,
      queryParams,
    );
  }
}
