import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Pagination } from "../../models/pagination.model";
import { ProcessingRequest } from "../../models/processing-request.model";
import { StructureProcessingConfig } from "../../models/structure-processing-config.model";
import {
  mapProcessingRequest,
  mapProcessingRequestPagination,
} from "../mapping/mapProcessingRequestPagination";
import { removeNullishValues } from "../utils/utils";

@Injectable({
  providedIn: "root",
})
export class ProcessingService {
  private readonly serviceUrl: string = `${environment.apiUrl}/api/v1/processing`;

  public constructor(private http: HttpClient) {}

  public getProcessingRequests(
    filters: ProcessingRequestFilters,
  ): Observable<Pagination<ProcessingRequest>> {
    return this.http
      .get<Pagination<ProcessingRequest>>(
        `${this.serviceUrl}/processingRequest`,
        {
          params: new HttpParams({ fromObject: removeNullishValues(filters) }),
        },
      )
      .pipe(map((response) => mapProcessingRequestPagination(response)));
  }

  public createProcessingRequest(
    structureId: string,
    requestedDate: Date,
  ): Observable<ProcessingRequest> {
    return this.http
      .post<ProcessingRequest>(`${this.serviceUrl}/processingRequest`, {
        structure: { id: structureId },
        requestedDate: requestedDate.toISOString(),
      })
      .pipe(map(mapProcessingRequest));
  }

  public getStructureProcessingConfig(
    structureId: string,
  ): Observable<StructureProcessingConfig> {
    return this.http.get(
      `${this.serviceUrl}/structure/${structureId}/processingConfig`,
    );
  }

  public updateStructureProcessingConfig(
    structureId: string,
    config: StructureProcessingConfig,
  ): Observable<StructureProcessingConfig> {
    return this.http.put(
      `${this.serviceUrl}/structure/${structureId}/processingConfig`,
      config,
    );
  }
}

export type ProcessingRequestFilters = {
  offset?: number;
  limit?: number;
  structureId?: string;
  requestedDate?: string;
  fromRequestedDate?: string;
  toRequestedDate?: string;
};
