import { Component, Input } from "@angular/core";
import { StructureStatus } from "../../models/structure.model";

@Component({
  selector: "structure-workflow-status",
  templateUrl: "./structure-workflow-status.component.html",
  styleUrls: ["./structure-workflow-status.component.scss"],
})
export class StructureWorkflowStatusComponent {
  @Input() public status: StructureStatus;

  public constructor() {
    this.status = "created";
  }

  public getStatusLabel() {
    switch (this.status) {
      case "created":
        return "component.workflowStatus.waitingForDeployment";
      case "deployed":
        return "component.workflowStatus.waitingForValidation";
      case "validated":
        return "component.workflowStatus.waitingForApproval";
      case "approved":
        return "component.workflowStatus.approved";
      case "monitored":
        return "component.workflowStatus.monitored";
      case "demobilized":
        return "component.workflowStatus.demobilized";
      default:
        return "component.workflowStatus.unknown";
    }
  }
}
