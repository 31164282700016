import { OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import {
  Component,
  DoCheck,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { OverlayService } from "../../services/utils/overlay.service";

@Component({
  selector: "app-modal-spinner",
  templateUrl: "./modal-spinner.component.html",
  styleUrls: ["./modal-spinner.component.scss"],
})
export class ModalSpinnerComponent implements OnInit, DoCheck {
  @Input() public diameter?: number = 50;
  @Input() private backdropEnabled = true;
  @Input() private positionGloballyCenter = true;
  @Input() private displaySpinner!: boolean;

  @ViewChild("progressSpinnerRef", { read: TemplateRef, static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private progressSpinnerRef!: TemplateRef<any>;
  private progressSpinnerOverlayConfig!: OverlayConfig;
  private overlayRef!: OverlayRef;

  public constructor(
    private vcRef: ViewContainerRef,
    private overlayService: OverlayService,
  ) {}

  public ngOnInit() {
    // Config for Overlay Service
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled,
    };
    if (this.positionGloballyCenter) {
      this.progressSpinnerOverlayConfig["positionStrategy"] =
        this.overlayService.positionGloballyCenter();
    }
    // Create Overlay for progress spinner
    this.overlayRef = this.overlayService.createOverlay(
      this.progressSpinnerOverlayConfig,
    );
  }

  public ngDoCheck() {
    // Based on status of displaySpinner attach/detach overlay to progress spinner template
    if (this.displaySpinner && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(
        this.overlayRef,
        this.progressSpinnerRef,
        this.vcRef,
      );
    } else if (!this.displaySpinner && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
