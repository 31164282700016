import { Injectable, NgZone } from "@angular/core";
import { marker } from "leaflet";
import { SnackbarService } from "./snackbar.service";

@Injectable({ providedIn: "root" })
export class ClickableMarker {
  public constructor(
    private snackbar: SnackbarService,
    private zone: NgZone,
  ) {}

  createMarker(lat: number, lng: number) {
    return marker([lat, lng])
      .bindTooltip(`${lat}, ${lng}`)
      .addEventListener("click", () => {
        void navigator.clipboard.writeText(`${lat}, ${lng}`);
        this.zone.run(() => this.snackbar.open("snackbar.locationCopied"));
      });
  }
}
