import { Injectable } from "@angular/core";

import { BillingService } from "../../services/network/billing.service";

@Injectable()
export class ConfigPageResolver {
  public constructor(private billingService: BillingService) {}

  public resolve() {
    return this.billingService.getConfigPlatform();
  }
}
