import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, interval, Observable, of, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import { Pagination } from "../../models/pagination.model";
import { RemarkableEvent } from "../../models/remarkable-event.model";
import { NotificationUnreadService } from "../../services/network/notification-unread.service";
import {
  EventFiltersForm,
  NotificationService,
} from "../../services/network/notification.service";
import { EventsTypesService } from "../../services/utils/events-types.service";
import { EVERY_10_SECONDS } from "../../services/utils/globals";
import { SnackbarService } from "../../services/utils/snackbar.service";

const SCROLL_STEP = 20;

@Component({
  selector: "event-table",
  templateUrl: "event-table.component.html",
  styleUrls: ["event-table.component.scss"],
})
export class EventTableComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() public form!: EventFiltersForm;

  @ViewChild("scrollableDiv")
  scrollableDiv!: ElementRef<HTMLElement>;

  public loading$ = new BehaviorSubject(false);
  public eventsColumns$!: Observable<string[]>;
  public eventsList$ = new BehaviorSubject<EventRow[]>([]);
  public eventSelected?: RemarkableEvent;

  private subscriptions = new Subscription();
  private total = Infinity;
  private isFetchingEvents = false;

  public constructor(
    private permissionsService: NgxPermissionsService,
    private notificationService: NotificationService,
    private notificationUnreadService: NotificationUnreadService,
    private snackBar: SnackbarService,
    private eventsTypesService: EventsTypesService,
  ) {}

  public ngOnInit() {
    const columns = ["date", "customer", "structure", "type"];
    this.eventsColumns$ = this.permissionsService.permissions$.pipe(
      map((permissions) => {
        return columns.filter(
          (column) =>
            column !== "customer" || permissions["structure:foreign:read"],
        );
      }),
    );

    this.notificationUnreadService.setZero();

    this.subscriptions.add(
      this.notificationUnreadService.getUnread$().subscribe((unread) => {
        if (unread > 0) {
          this.snackBar.openReloadSnackbar("page.events.snackbarReload");
        }
      }),
    );

    this.subscriptions.add(
      interval(EVERY_10_SECONDS).subscribe(() => {
        this.notificationUnreadService.forceRefresh();
      }),
    );
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => this.eventsList$.next([])),
          mergeMap(() => this.fetchMoreEvents()),
        )
        .subscribe(),
    );
  }

  /**
   * Run after each rendering: if there are more events and the scrollbar is
   * not visible (the screen is big enough to show everything) and not already retrieving more events, we fetch more events
   */
  public ngAfterViewChecked() {
    if (this.hasMoreEvents() && !this.hasScrollbar()) {
      this.fetchMoreEvents().subscribe();
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onScroll() {
    if (this.hasMoreEvents()) {
      this.fetchMoreEvents().subscribe();
    }
  }

  public onRowSelected(row: RemarkableEvent) {
    this.eventSelected = row;
  }

  public closePanel() {
    this.eventSelected = undefined;
  }

  private fetchMoreEvents(): Observable<
    Pagination<RemarkableEvent> | undefined
  > {
    if (this.isFetchingEvents) {
      // prevent fetching the same thing multiple times in parallel
      return of(undefined);
    }
    this.isFetchingEvents = true;
    const events = this.eventsList$.value;
    return this.notificationService
      .getEvents(events.length, SCROLL_STEP, this.form.value)
      .pipe(
        tap((result) => {
          this.total = result.total;
          const newRows = result.data.map((event) => ({
            ...event,
            category: this.eventsTypesService.getCategoryFromEventType(
              event.type,
            ),
          }));
          this.eventsList$.next(events.concat(newRows));
        }),
        finalize(() => {
          this.isFetchingEvents = false;
        }),
      );
  }

  private hasMoreEvents() {
    return this.total > this.eventsList$.value.length;
  }

  private hasScrollbar() {
    const { clientHeight, scrollHeight } = this.scrollableDiv.nativeElement;
    return clientHeight < scrollHeight;
  }
}

interface EventRow extends RemarkableEvent {
  category: string;
}
