import { Component, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-snackbar-reload",
  templateUrl: "snackbar-reload.component.html",
  styleUrls: ["snackbar-reload.component.scss"],
  animations: [],
})
export class SnackbarReloadComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private _snackRef: MatSnackBarRef<SnackbarReloadComponent>,
  ) {}

  public reload() {
    this._snackRef.dismissWithAction();
  }

  public dismiss() {
    this._snackRef.dismiss();
  }
}
