import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { HashId, Structure } from "src/app/models/structure.model";
import { BusinessService } from "src/app/services/network/business.service";
import { StorageService } from "../../../../services/utils/storage.service";

export abstract class AbstractStructureWorkflowPage {
  public currentStructure$!: Observable<Structure>;
  public structureId: HashId = "";

  public constructor(
    protected location: Location,
    protected businessService: BusinessService,
    protected route: ActivatedRoute,
    protected storage: StorageService,
  ) {
    this.currentStructure$ = this.businessService.structureStream();
    if (this.route.parent) {
      this.route.parent.params.pipe(take(1)).subscribe((params) => {
        this.structureId = params["id"];
      });
    }
  }

  protected setHeader(pageTitle: string) {
    const structureName =
      this.businessService.getStructureValue().generalInfo.name;
    this.storage.setPageHeader({
      showHomeButton: false,
      showBackButton: true,
      showNavLinks: false,
      pageTitle,
      pageTitleParams: { structureName },
    });
  }

  public backToPreviousPage() {
    this.location.back();
  }
}
