<div class="outer-content">
  <div class="body-content">
    <div class="workflow">
      <span
        class="workflow-step"
        [ngClass]="{ 'active-step': status === 'created' }"
        >1</span
      >
      <span
        class="workflow-step"
        [ngClass]="{ 'active-step': status === 'deployed' }"
        >2</span
      >
      <span
        class="workflow-step"
        [ngClass]="{ 'active-step': status === 'validated' }"
        >3</span
      >
      <span
        class="workflow-step"
        [ngClass]="{ 'active-step': status === 'approved' }"
        >4</span
      >
      <span
        class="workflow-step last-step"
        [ngClass]="{ 'active-step': status === 'monitored' }"
        >5</span
      >
    </div>

    <span class="status">{{ getStatusLabel() | translate }}</span>
  </div>
</div>
