import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentsModule } from "../../components/components.module";
import { SharedModule } from "../../shared/shared.module";
import { EventsPage } from "./events-page";
import { EventsPageRoutingModule } from "./events-page-routing.module";

const COMPONENTS = [EventsPage];
@NgModule({
  imports: [
    SharedModule,
    ComponentsModule,
    BrowserAnimationsModule,
    EventsPageRoutingModule,
    MatButtonModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class EventsPageModule {}
