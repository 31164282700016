import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "measure-point-active-toggle-activate-dialog",
  templateUrl: "measure-point-active-toggle-activate-dialog.component.html",
})
export class MeasurePointActiveToggleActivateDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public measurePointNumber: number) {}
}
