import { Component, Input, OnChanges } from "@angular/core";
import { ProcessingRequest } from "../../models/processing-request.model";
import { RequestedDateRow } from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";

@Component({
  selector: "processing-requested-status",
  templateUrl: "./processing-requested-status.component.html",
  styleUrls: ["./processing-requested-status.component.scss"],
})
export class ProcessingRequestedStatusComponent implements OnChanges {
  @Input() public row!: RequestedDateRow;

  public icon!: string;
  public iconContainerClass!: string;
  public statusTranslationKey!: string;

  public ngOnChanges(): void {
    this.updateIcon();
    this.updateIconContainerClass();
    this.updateStatusTranslationKey();
  }

  private updateIcon() {
    this.icon =
      !!this.row.receivedRawMeasurePointNumbers.length &&
      this.row.lastProcessingRequest
        ? "check"
        : "horizontal_rule";
  }

  private updateIconContainerClass() {
    if (
      !this.row.receivedRawMeasurePointNumbers.length ||
      !this.row.lastProcessingRequest
    )
      this.iconContainerClass = "no-measures-available";
    else if (
      this.newMeasureReceivedSinceLastProcessingRequested(
        this.row.lastProcessingRequest,
      )
    )
      this.iconContainerClass = "new-measures-available";
    else this.iconContainerClass = "no-new-measures-available";
  }

  private updateStatusTranslationKey() {
    let baseTranslationKey = "component.processingRequestedStatus.status";
    if (
      this.row.allMeasures.every((m) => m.measurePointNumber !== 0) &&
      !this.row.lastProcessingRequest
    ) {
      this.statusTranslationKey = `${baseTranslationKey}.waitingForSynthesisPoint`;
      return;
    }
    if (!this.row.receivedRawMeasurePointNumbers.length) {
      this.statusTranslationKey = `${baseTranslationKey}.waitingForRaws`;
      return;
    } else if (!this.row.lastProcessingRequest) {
      this.statusTranslationKey = `${baseTranslationKey}.waitingForProcessingRequest`;
      return;
    } else if (
      this.newMeasureReceivedSinceLastProcessingRequested(
        this.row.lastProcessingRequest,
      )
    ) {
      baseTranslationKey = `${baseTranslationKey}.executedBeforeNewRaws`;
    }
    this.statusTranslationKey = `${baseTranslationKey}.${this.row.lastProcessingRequest?.cause}`;
  }

  private newMeasureReceivedSinceLastProcessingRequested(
    lastProcessingRequest: ProcessingRequest,
  ): boolean {
    const lastReceived = lastProcessingRequest.measurePoints
      .filter((m) => m.measure?.raw)
      .map((m) => m.measurePointNumber);
    return this.row.receivedRawMeasurePointNumbers.some(
      (measurePointNumber) => !lastReceived.includes(measurePointNumber),
    );
  }
}
