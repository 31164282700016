<button
  *ngIf="row.lastProcessingRequest !== false"
  [disabled]="!canForceProcessing || loading"
  (click)="onForceProcessing()"
  mat-flat-button
  color="accent"
  [id]="
    'structure-measure-force-processing-button' +
    row.requestedDate.toISOString()
  "
>
  {{ "component.measuresTable.force" | translate }}
  <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
</button>
