<form [formGroup]="form">
  <div class="form-container">
    <div class="file">
      <div class="file-container" *ngIf="!readMode">
        <div class="file-title">
          {{ "component.structureForm.deploymentProtocol" | translate }}
          <span class="red-star">*</span>
        </div>
        <div
          id="structure-form-file-drop-zone"
          class="drop-zone"
          (drop)="onDrop($event)"
          (dragover)="onDragOver($event)"
        >
          <img src="assets/svg/dragAndDrop.svg" alt="" />
          <div>{{ currentFileName }}</div>
        </div>
        <input
          id="structure-form-input-file"
          class="input-file"
          type="file"
          name="file"
          id="file"
          (change)="uploadButtonTrigger($event)"
        />
        <label
          id="structure-form-label-file"
          for="file"
          class="label-file"
          matTooltip="{{ 'component.structureForm.maxFileSize' | translate }}"
        >
          {{ "common.chooseAFile" | translate }}
        </label>
      </div>
      <div class="file-container" *ngIf="readMode">
        <div class="file-title">
          {{ "component.structureForm.deploymentProtocol" | translate }}
        </div>
        <div class="drop-zone-full" (click)="downloadFile()">
          <icon-download></icon-download>
        </div>
      </div>
    </div>
    <div class="details">
      <div class="structure-details-container">
        <div class="customer-follow-up-header">
          {{ "component.structureForm.structureInformations" | translate }}
        </div>

        <div class="structure-fields">
          <div>
            <div
              class="type-block"
              [ngClass]="{
                'type-block__read-mode': readMode,
                'type-block__error-read-mode': typeFieldReadmodeAndError()
              }"
            >
              <div
                class="type-title"
                [ngClass]="{ 'type-title__read-mode': readMode }"
              >
                {{ "common.type" | translate }}
              </div>
              <div
                class="type-buttons"
                [ngClass]="{ 'type-buttons__read-mode': readMode }"
                *ngIf="!typeFieldReadmodeAndError()"
              >
                <div
                  class="button-wrapper"
                  *ngIf="
                    !readMode ||
                    (readMode && form.controls['type'].value === 'bridge')
                  "
                >
                  <div
                    id="structure-form-button-icon-bridge"
                    class="diamond-button"
                    (click)="selectType('bridge')"
                    [ngClass]="{
                      'selected-diamond-button':
                        form.controls['type'].value === 'bridge' || readMode
                    }"
                  >
                    <div class="button-icon-bridge"></div>
                  </div>
                </div>
                <div
                  class="button-wrapper"
                  *ngIf="
                    !readMode ||
                    (readMode && form.controls['type'].value === 'building')
                  "
                >
                  <div
                    id="structure-form-button-icon-building"
                    class="diamond-button"
                    (click)="selectType('building')"
                    [ngClass]="{
                      'selected-diamond-button':
                        form.controls['type'].value === 'building' || readMode
                    }"
                  >
                    <div class="button-icon-building"></div>
                  </div>
                </div>
              </div>
              <div
                class="error-type"
                *ngIf="readMode && typeFieldReadmodeAndError()"
              >
                {{ "component.structureForm.typeUnknown" | translate }}
              </div>
            </div>
            <mat-form-field>
              <mat-label>{{
                "component.structureForm.structureName" | translate
              }}</mat-label>
              <input
                id="structure-form-field-name"
                matInput
                formControlName="name"
              />
              <mat-error *ngIf="form.controls['name'].errors?.required">
                {{
                  "component.structureForm.structureNameRequired" | translate
                }}
              </mat-error>
              <mat-error *ngIf="form.controls['name'].errors?.pattern">
                {{ "component.structureForm.structureNamePattern" | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{
                "component.structureForm.region" | translate
              }}</mat-label>
              <mat-select
                id="structure-form-select-region"
                formControlName="regionId"
              >
                <mat-option
                  *ngFor="let region of authService.getRegions()"
                  [value]="region"
                  >{{ "regions." + region | translate }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="form.controls['regionId'].invalid">{{
                "component.structureForm.regionRequired" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{
                "component.structureForm.contact" | translate
              }}</mat-label>
              <input
                id="structure-form-field-contact"
                matInput
                formControlName="contact"
                type="email"
              />
              <mat-error *ngIf="form.controls['contact'].invalid">{{
                "component.structureForm.contactInvalid" | translate
              }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "common.address" | translate }}</mat-label>
              <input
                id="structure-form-field-address"
                matInput
                formControlName="address"
              />
              <mat-error *ngIf="form.controls.address.invalid">{{
                "component.structureForm.addressRequired" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <structure-location-form
          [form]="form"
          [readMode]="readMode"
          *ngIf="!readMode || form.value.location?.lat"
        ></structure-location-form>
      </div>
      <structure-billing-form
        [form]="form"
        [readMode]="readMode"
        [formHeader]="'component.structureForm.customerFollowUp' | translate"
      ></structure-billing-form>

      <div class="weekly-form-container" *ngIf="!readMode">
        <div class="customer-follow-up-header">
          {{ "component.structureForm.configurationWeeklyMode" | translate }}
          <mat-slide-toggle
            [(ngModel)]="isConfigWeeklyEnabled"
            [ngModelOptions]="{ standalone: true }"
          ></mat-slide-toggle>
        </div>
        <div class="structure-fields" *ngIf="isConfigWeeklyEnabled">
          <div>
            <mat-form-field>
              <mat-label>{{
                "component.structureForm.dayToOversight" | translate
              }}</mat-label>
              <mat-select
                id="structure-form-select-day"
                formControlName="dayOfWeekToOversight"
              >
                <mat-option value="monday">{{ getWeekdayName(1) }}</mat-option>
                <mat-option value="tuesday">{{ getWeekdayName(2) }}</mat-option>
                <mat-option value="wednesday">{{
                  getWeekdayName(3)
                }}</mat-option>
                <mat-option value="thursday">{{
                  getWeekdayName(4)
                }}</mat-option>
                <mat-option value="friday">{{ getWeekdayName(5) }}</mat-option>
                <mat-option value="saturday">{{
                  getWeekdayName(6)
                }}</mat-option>
                <mat-option value="sunday">{{ getWeekdayName(7) }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.controls['dayOfWeekToOversight'].invalid"
                >{{
                  "component.structureForm.dayToOversightRequired" | translate
                }}</mat-error
              >
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{
                "component.structureForm.hourToOversight" | translate
              }}</mat-label>
              <input
                id="structure-form-select-hour"
                matInput
                formControlName="hourOfDayToOversight"
                type="number"
                min="0"
                max="23"
              />
              <mat-error
                *ngIf="form.controls['hourOfDayToOversight'].invalid"
                >{{
                  "component.structureForm.hourToOversightRequired" | translate
                }}</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
