<form>
  <div class="outer-content">
    <structure-measures-table-refresh-snackbar
      [filter]="form.get('requestedDate')!.valueChanges | async"
    ></structure-measures-table-refresh-snackbar>
    <div class="body-content">
      <div class="header-content">
        <switch-button
          [state]="stateDisplayMode$ | async"
          [firstStateTranslationKey]="'common.processeds'"
          [secondStateTranslationKey]="'common.raws'"
          (toggle)="onToggleMode()"
        ></switch-button>
        <structure-measures-chip-list></structure-measures-chip-list>
      </div>
      <div
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      >
        <structure-measures-processed-table
          [rows]="processedRows$ | async"
          [ngClass]="{ active: (displayMode$ | async) === 'processed' }"
          (amcSelected)="onAmcSelected($event)"
          (measureOpenSensorsSelected)="onMeasureOpenSensorsSelected($event)"
          (toggle)="onToggleMode($event)"
          (forceProcessing)="onForceProcessing($event)"
        ></structure-measures-processed-table>
        <structure-measures-raw-table
          [rows]="rawRows$ | async"
          [ngClass]="{ active: (displayMode$ | async) === 'raw' }"
          (amcSelected)="onAmcSelected($event)"
          (authorizationDateSelected)="onAuthorizationDateSelected($event)"
          (toggle)="onToggleMode($event)"
        ></structure-measures-raw-table>
      </div>
    </div>
    <side-panel-amc
      *ngIf="selectedAmc$ | async as selectedAmc"
      id="structure-measures-table-side-panel-amc"
      class="side-panel"
      [amc]="selectedAmc.amc"
      [mode]="selectedAmc.mode"
      (closePanelEvent)="closePanel()"
    ></side-panel-amc>
    <side-panel-opensensors
      *ngIf="measureOpenSensorsSelected"
      id="structure-measures-table-side-panel-opensensors"
      class="side-panel"
      [measure]="measureOpenSensorsSelected"
      (closePanelEvent)="closePanel()"
    ></side-panel-opensensors>
    <side-panel-measures-authorizations
      *ngIf="selectedAuthorizationDate"
      id="structure-measures-table-side-panel-measures-authorizations"
      class="side-panel"
      [requestedDate]="selectedAuthorizationDate"
      (rawsAuthorize)="onRawsAuthorize($event)"
      (closePanelEvent)="closePanel()"
    ></side-panel-measures-authorizations>
  </div>
</form>
