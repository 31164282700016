import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  RequestedDateRow,
  StructureMeasuresPageStore,
} from "../../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";

@Component({
  selector: "structure-measures-table-requested-date-cell",
  templateUrl: "./structure-measures-table-requested-date-cell.component.html",
  styleUrls: ["./structure-measures-table-requested-date-cell.component.scss"],
  providers: [LocalizedDatePipe],
})
export class StructureMeasuresRequestedDateCellComponent
  implements OnInit, OnChanges
{
  @Input() public row!: RequestedDateRow;
  @Input() public mode!: "processed" | "raw";

  @Output() public downloadAllRaws = new EventEmitter<Date>();
  @Output() public amcSelected = new EventEmitter<RequestedDateRow>();
  @Output() public toggle = new EventEmitter<Date>();

  public stateDisplayMode!: string;
  public rowId!: string;
  public structureId: string;

  public constructor(pageStore: StructureMeasuresPageStore) {
    this.structureId = pageStore.getStructureId();
  }

  public ngOnInit(): void {
    this.stateDisplayMode = this.mode === "processed" ? "first" : "second";
  }

  public ngOnChanges(): void {
    this.rowId = `structure-measures-table-${
      this.mode
    }-${this.row.requestedDate.toISOString()}`;
  }

  public onDownloadAllRaws(requestedDate: Date) {
    this.downloadAllRaws.emit(requestedDate);
  }

  public onAmcSelected(row: RequestedDateRow) {
    this.amcSelected.emit(row);
  }

  public onToggleMode(requestedDate: Date) {
    this.toggle.emit(requestedDate);
  }
}
