import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, from } from "rxjs";
import { filter, finalize, mergeMap, tap } from "rxjs/operators";
import { Translation } from "../../../models/translation.model";
import {
  isReceivedRow,
  MeasureRow,
  ReceivedRow,
  RequestedDateRow,
  StructureMeasuresPageStore,
} from "../../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { AuthService } from "../../../services/auth/auth.service";
import { BusinessService } from "../../../services/network/business.service";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";
import { DialogMeasuresTableDownloadAllRawEmpty } from "../dialogs/dialog-measures-table-download-all-raw-empty.component";
import { DialogMeasuresTableDownloadAllRawWarning } from "../dialogs/dialog-measures-table-download-all-raw-warning.component";

@Component({
  selector: "structure-measures-raw-table",
  templateUrl: "./structure-measures-raw-table.component.html",
  styleUrls: ["../structure-measures-table.component.scss"],
  providers: [LocalizedDatePipe],
})
export class StructureMeasuresRawTableComponent implements OnInit {
  public columns = [
    "requestedDate",
    "measuringPoint",
    "active",
    "temperature",
    "humidity",
    "tiltPitch",
    "raw",
  ];
  @Input() public rows!: MeasureRow[];
  @Output() public authorizationDateSelected = new EventEmitter<Date>();
  @Output() public amcSelected = new EventEmitter<RequestedDateRow>();
  @Output() public toggle = new EventEmitter<Date>();

  public form!: FormGroup;
  public isDownloadingMeasureRaws$ = new BehaviorSubject<boolean>(false);
  public measurePointsForm: FormControl<number[]> = new FormControl();
  public measurePointActiveOptions = [true, false];
  public measurePointActiveTranslations = this.measurePointActiveOptions.map(
    (value) =>
      value ? { key: "common.activated" } : { key: "common.deactivated" },
  );
  public hasAuthorizeRawPermission!: boolean;
  public measurePoints!: number[];
  public measurePointsTranslations!: Translation[];

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private businessService: BusinessService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
    this.form = this.pageStore.getFilterForm();
  }

  public ngOnInit(): void {
    this.hasAuthorizeRawPermission = this.authService
      .getScopes()
      .includes("structure:measure:raw:authorization:update");
    this.measurePoints = this.pageStore.getStructureMeasurePointNumbers();
    this.measurePointsTranslations = this.getMeasurePointsTranslations();
  }

  public onAuthorizationDateSelected(requestedDate: Date) {
    this.authorizationDateSelected.emit(requestedDate);
  }

  public downloadRaw(measure: ReceivedRow) {
    this.businessService.downloadRaw(measure);
  }

  public downloadAllRaws(requestedDate: Date) {
    const authorizedMeasures = this.getDownloadableRaws(requestedDate);

    if (authorizedMeasures.length > 0) {
      this.dialog
        .open(DialogMeasuresTableDownloadAllRawWarning, { autoFocus: false })
        .afterClosed()
        .pipe(
          filter((result) => result),
          tap(() => {
            this.isDownloadingMeasureRaws$.next(true);
          }),
          mergeMap(() =>
            from(
              this.businessService.downloadAllRaws(
                authorizedMeasures,
                requestedDate,
              ),
            ),
          ),
          finalize(() => this.isDownloadingMeasureRaws$.next(false)),
        )
        .subscribe();
    } else {
      this.dialog.open(DialogMeasuresTableDownloadAllRawEmpty, {
        autoFocus: false,
      });
    }
  }

  public onAmcSelected(measureRow: RequestedDateRow) {
    this.amcSelected.emit(measureRow);
  }

  public onToggleMode(requestedDate: Date) {
    this.toggle.emit(requestedDate);
  }

  private getMeasurePointsTranslations(): Translation[] {
    return this.pageStore
      .getStructureMeasurePointNumbers()
      .map((measurePointNumber) =>
        measurePointNumber === 0
          ? { key: "common.synthesis" }
          : {
              key: "common.measurePoint",
              interpolateParams: { measurePointNumber },
            },
      );
  }

  private getDownloadableRaws(requestedDate: Date): ReceivedRow[] {
    const hasUnlimitedRawAuth = this.authService
      .getScopes()
      .includes("structure:measure:raw:unlimited:read");
    return this.pageStore
      .getRowGroupForDate(requestedDate)!
      .filter(isReceivedRow)
      .filter((measure) =>
        hasUnlimitedRawAuth
          ? measure.sensors.raw
          : measure.sensors.raw?.customerDownloadAuthorization,
      );
  }
}
