import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ReplaySubject } from "rxjs";
import { Structure } from "../../models/structure.model";
import { BusinessService } from "../../services/network/business.service";
import { DialogOnRetrieveDemand } from "../dialog-on-retrieve-demand/dialog-on-retrieve-demand.component";

@Component({
  selector: "retrieve-panel",
  templateUrl: "./retrieve-panel.component.html",
  styleUrls: ["./retrieve-panel.component.scss"],
})
export class RetrievePanelComponent implements OnChanges {
  @Input() public structure!: Structure;
  public retrieveId$ = new ReplaySubject(1);

  public constructor(
    private businessService: BusinessService,
    private dialog: MatDialog,
  ) {}

  public ngOnChanges(): void {
    this.retrieveId$.next(this.structure.generalInfo.retrieveId);
  }

  public onRetrieve() {
    const newRetrieveId = new Date().getTime();
    this.businessService
      .postRetrieve(this.structure.generalInfo.id, newRetrieveId)
      .subscribe(() => {
        this.dialog.open(DialogOnRetrieveDemand);
        this.retrieveId$.next(newRetrieveId);
      });
  }
}
