import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "src/app/services/auth/auth.guard";
import { StructureAmcResolver } from "./resolvers/structure-amc.resolver";
import { StructureDeploymentReportDraftResolver } from "./resolvers/structure-deployment-report-draft.resolver";
import { StructureResolver } from "./resolvers/structure.resolver";
import { StructureConfigComponent } from "./structure-details/structure-config/structure-config.component";
import { StructureDetailsPageComponent } from "./structure-details/structure-details-page";
import { StructureMeasureSpotsComponent } from "./structure-details/structure-measure-spots/structure-measure-spots.component";
import { StructureMeasuresComponent } from "./structure-details/structure-measures/structure-measures.component";
import { StructureOverviewComponent } from "./structure-details/structure-overview/structure-overview.component";
import { StructureSensorsComponent } from "./structure-details/structure-sensors/structure-sensors.component";
import { StructureTrafficComponent } from "./structure-details/structure-traffic/structure-traffic.component";
import { StructurePageComponent } from "./structure-page";
import { StructureApprovalPage } from "./structure-workflow/structure-approval/structure-approval-page";
import { StructureFinalizationPage } from "./structure-workflow/structure-finalization/structure-finalization-page";
import { StructureValidationPage } from "./structure-workflow/structure-validation/structure-validation-page";

const routes: Routes = [
  {
    path: "structure/:id",
    component: StructurePageComponent,
    resolve: { structure: StructureResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "details",
        data: {
          permissions: {
            only: [
              "structure:created:read",
              "structure:deployed:read",
              "structure:validated:read",
              "structure:monitored:read",
              "structure:demobilized:read",
            ],
          },
        },
      },
      {
        path: "details",
        component: StructureDetailsPageComponent,
        canActivateChild: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [
              "structure:created:read",
              "structure:deployed:read",
              "structure:validated:read",
              "structure:monitored:read",
              "structure:demobilized:read",
            ],
          },
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "overview",
            data: {
              permissions: {
                only: [
                  "structure:created:read",
                  "structure:deployed:read",
                  "structure:validated:read",
                  "structure:monitored:read",
                  "structure:demobilized:read",
                ],
              },
            },
          },
          {
            path: "overview",
            component: StructureOverviewComponent,
            data: {
              permissions: {
                only: [
                  "structure:created:read",
                  "structure:deployed:read",
                  "structure:validated:read",
                  "structure:monitored:read",
                  "structure:demobilized:read",
                ],
              },
            },
          },
          {
            path: "sensors",
            component: StructureSensorsComponent,
            data: {
              permissions: {
                only: "structure:device:read",
              },
            },
          },
          {
            path: "measure-spots",
            component: StructureMeasureSpotsComponent,
            data: {
              permissions: {
                only: [
                  "structure:created:read",
                  "structure:deployed:read",
                  "structure:validated:read",
                  "structure:monitored:read",
                  "structure:demobilized:read",
                ],
              },
            },
          },
          {
            path: "config",
            component: StructureConfigComponent,
            data: {
              permissions: {
                only: "ui:amc:show",
              },
            },
            resolve: { amc: StructureAmcResolver },
          },
          {
            path: "measures",
            component: StructureMeasuresComponent,
            data: {
              permissions: {
                only: "structure:measure:read",
              },
            },
          },
          {
            path: "traffic",
            component: StructureTrafficComponent,
            data: {
              permissions: {
                only: "structure:traffic:read",
              },
            },
          },
        ],
      },
      {
        path: "structure-approval",
        component: StructureApprovalPage,
        data: {
          permissions: {
            only: "structure:approve",
          },
        },
      },
      {
        path: "structure-finalization",
        component: StructureFinalizationPage,
        data: {
          permissions: {
            only: "structure:deployment_report:update",
          },
        },
        resolve: {
          deploymentReportDraft: StructureDeploymentReportDraftResolver,
        },
      },
      {
        path: "structure-validation",
        component: StructureValidationPage,
        data: {
          permissions: {
            only: "structure:deployment_report:validate",
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    StructureResolver,
    StructureAmcResolver,
    StructureDeploymentReportDraftResolver,
  ],
})
export class StructurePageRoutingModule {}
