import { AmcModeType } from "./amc.model";

export interface ProcessingRequest {
  id: string;
  structure: { id: string; href: string };
  creationDate: Date;
  requestedDate: Date;
  amc: {
    id: number;
    mode: AmcModeType;
    href: string;
  };
  cause: ProcessingRequestedCause;
  createdBy: { id: string; username: string };
  measurePoints: ProcessingRequestMeasurePoint[];
}

export interface ProcessingRequestMeasurePoint {
  measurePointNumber: number;
  active: boolean;
  measure?: ProcessingRequestMeasure;
}

export interface ProcessingRequestMeasure {
  measurePointNumber: number;
  id: string;
  href: string;
  raw: { file: { href: string } };
}

export const processingRequestedCauseOptions = [
  "forced",
  "auto",
  "timeout",
] as const;

export type ProcessingRequestedCause =
  (typeof processingRequestedCauseOptions)[number];

export const processingRequestStatusesFilterOptions = [
  ...processingRequestedCauseOptions,
  "waiting",
] as const;

export type ProcessingRequestStatusesFilter =
  (typeof processingRequestStatusesFilterOptions)[number];
