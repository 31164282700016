import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { BusinessService } from "src/app/services/network/business.service";
import { DeploymentService } from "src/app/services/network/deployment.service";
import { StorageService } from "src/app/services/utils/storage.service";
import { AbstractStructureWorkflowPage } from "../abstract/abstract-structure-workflow-page";

@Component({
  selector: "structure-validation-page",
  templateUrl: "./structure-validation-page.html",
  styleUrls: ["./structure-validation-page.scss"],
})
export class StructureValidationPage
  extends AbstractStructureWorkflowPage
  implements OnInit
{
  private waiting = new BehaviorSubject(false);
  public waiting$ = this.waiting.asObservable();

  public constructor(
    protected location: Location,
    protected businessService: BusinessService,
    protected route: ActivatedRoute,
    private deploymentService: DeploymentService,
    protected storage: StorageService,
  ) {
    super(location, businessService, route, storage);
  }

  public ngOnInit(): void {
    this.setHeader("page.structure.validation.title");
  }

  public validateStructure() {
    this.waiting.next(true);
    this.deploymentService
      .validateStructure$(this.structureId)
      .pipe(
        this.businessService.pollUntilStructureLeftState(
          this.structureId,
          "deployed",
        ),
        finalize(() => {
          this.waiting.next(false);
        }),
      )
      .subscribe(() => {
        this.location.back();
      });
  }

  public disapproveStructure() {
    this.waiting.next(true);
    this.deploymentService
      .rejectStructureDeploymentReport(this.structureId)
      .pipe(
        this.businessService.pollUntilStructureLeftState(
          this.structureId,
          "deployed",
        ),
        finalize(() => {
          this.waiting.next(false);
        }),
      )
      .subscribe(() => {
        this.location.back();
      });
  }
}
