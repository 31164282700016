import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "../../services/auth/auth.guard";
import { InvoicesPageComponent } from "./invoices-page";

const routes: Routes = [
  {
    path: "invoices",
    component: InvoicesPageComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ["invoice:read"],
        redirectTo: "/landing",
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class InvoicesPageRoutingModule {}
