<div class="container">
  <div *ngIf="category === 'sensors'" class="event-type-container">
    <mat-icon
      [alt]="'component.eventTypeSelect.category.' + category | translate"
      >sensors</mat-icon
    >
  </div>
  <img
    *ngIf="category !== 'sensors'"
    [src]="'../../../assets/icons/' + category + '.png'"
    [alt]="'component.eventTypeSelect.category.' + category | translate"
  />
</div>
