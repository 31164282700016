import { TranslateService } from "@ngx-translate/core";

export class DynamicLocaleId extends String {
  public constructor(protected service: TranslateService) {
    super("");
  }

  public toString() {
    return this.service.currentLang;
  }
}
