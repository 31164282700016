<div class="outer-content">
  <div class="logo" *ngIf="!canReadStructures">
    <img src="../../assets/s-lynks.png" alt="S-lynks" />
  </div>

  <button
    id="landing-page-new-structure-button"
    *ngxPermissionsOnly="'structure:create'"
    mat-raised-button
    (click)="redirectToStructureCreation()"
    class="create-button"
  >
    {{ "page.landing.newStructure" | translate}}
    <mat-icon class="create-icon">add</mat-icon>
  </button>

  <div class="body-content" *ngIf="canReadStructures">
    <structure-table
      [form]="form"
      (downloadArchive)="downloadArchive($event)"
      (workflowRequest)="redirectToWorkflowPage($event)"
    ></structure-table>
  </div>
  <footer class="version" #footer>
    {{ "page.landing.version" | translate }} {{ version }} {{
    "page.landing.rights" | translate }}
  </footer>
</div>
<div class="scroll-button">
  <button
    [@fadeInOut]
    *ngIf="!isAtBottom"
    mat-fab
    color="primary"
    (click)="scrollToFooter()"
  >
    <mat-icon>arrow_downward</mat-icon>
  </button>
</div>
