import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({ selector: "[reactiveDisabled]" })
export class ReactiveDisabledDirective implements OnChanges {
  @Input() reactiveDisabled = false;
  public ngOnChanges(changes: SimpleChanges) {
    if (changes["reactiveDisabled"]) {
      if (this.reactiveDisabled) {
        this.ngControl.control?.disable();
      } else {
        this.ngControl.control?.enable();
      }
    }
  }

  constructor(private ngControl: NgControl) {}
}
