import { Location } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  PageHeader,
  StorageService,
} from "../../services/utils/storage.service";

@Component({
  selector: "page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit {
  public header$!: Observable<PageHeader>;

  @Output() public buttonOnClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public constructor(
    private router: Router,
    private location: Location,
    private store: StorageService,
  ) {}

  public ngOnInit() {
    this.header$ = this.store.pageHeader$;
  }

  public redirectToLandingPage() {
    return this.router.navigate(["landing"]);
  }

  public redirectToPreviousPage() {
    return this.location.back();
  }
}
