import { Injectable } from "@angular/core";
import { Icon, icon, Map, Marker } from "leaflet";
import leafletImage from "leaflet-image";

@Injectable({ providedIn: "root" })
export class OpenStreetMapService {
  public static tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  public initialize() {
    // we need to customize the way markers are rendered by default
    // see: https://github.com/bluehalo/ngx-leaflet#a-note-about-markers
    Marker.prototype.options.icon = icon({
      ...Icon.Default.prototype.options,
      iconRetinaUrl: "assets/marker-icon-2x.png",
      iconUrl: "assets/marker-icon.png",
      shadowUrl: "assets/marker-shadow.png",
    });
  }

  public async createScreenshot(leafletMap: Map): Promise<Blob> {
    const canvas = await new Promise<HTMLCanvasElement>((resolve, reject) => {
      leafletImage(leafletMap, (err: unknown, result: HTMLCanvasElement) => {
        if (err) reject(err);
        resolve(result);
      });
    });
    return await new Promise<Blob>((resolve) =>
      canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.5),
    );
  }
}
