<mat-form-field class="white-background">
  <mat-label>
    {{ "common.workflow" | translate }}
  </mat-label>
  <mat-select
    [formControl]="form"
    disableOptionCentering
    panelClass="filter-type-component-select-panel"
    #select
    multiple
  >
    <div class="select-all">
      <mat-checkbox
        [(ngModel)]="allSelected"
        [ngModelOptions]="{ standalone: true }"
        (change)="toggleAllSelection()"
      >
        {{ "common.all" | translate }}
      </mat-checkbox>
    </div>
    <mat-option
      *ngFor="let workflow of workflows"
      class="option-workflow"
      [value]="workflow.value"
      (onSelectionChange)="optionClick()"
      >{{ "component.workflowStatus." + workflow.translationKey | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
