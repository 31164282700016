import { DataSource } from "@angular/cdk/table";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { StructureCreationMeasurePoint } from "src/app/models/structure.model";
import { StorageService } from "src/app/services/utils/storage.service";

@Component({
  selector: "measure-points-table",
  templateUrl: "./measure-points-table.component.html",
  styleUrls: ["./measure-points-table.component.scss"],
})
export class MeasurePointTableComponent implements OnInit {
  // if provided, the table will be read-only
  @Input() public measurePoints$?: Observable<StructureCreationMeasurePoint[]>;

  @Output() public removePoint = new EventEmitter();
  @Output() public saveComment = new EventEmitter();

  public readMode = true;
  public columns = ["name", "comments", "actions"];
  public pointsTable!: MeasurePointsDataSource;
  public measurePointsCopy: StructureCreationMeasurePoint[];
  public currentIndexFocus: number | undefined = 0;

  public constructor(private storage: StorageService) {
    this.measurePointsCopy = [];
  }

  public ngOnInit() {
    this.readMode = !!this.measurePoints$;
    if (this.readMode) {
      this.columns = ["name", "comments"];
    }
    const measurePoints =
      this.measurePoints$ ??
      this.storage.getCurrentStructureMeasurePointsStream();
    this.pointsTable = new MeasurePointsDataSource(measurePoints);
  }

  public saveCommentInModel(index: number, value: string) {
    this.saveComment.emit({ index, value });
    this.currentIndexFocus = undefined;
  }

  public removeMeasurePoint(index: number) {
    this.removePoint.emit({ index });
  }

  public setCurrentFocus(index: number) {
    this.currentIndexFocus = index;
  }
}

class MeasurePointsDataSource extends DataSource<StructureCreationMeasurePoint> {
  public constructor(
    private measurePoints$: Observable<StructureCreationMeasurePoint[]>,
  ) {
    super();
  }

  public connect(): Observable<StructureCreationMeasurePoint[]> {
    return this.measurePoints$;
  }

  public disconnect() {}
}
