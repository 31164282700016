<div class="outer-content">
  <div class="body-content">
    <div class="header-content">
      <span class="blue-previous" (click)="backToPreviousPage()"
        ><mat-icon>arrow_backward</mat-icon></span
      >
      <span id="general-info-back-button" class="header-title"
        >{{ "page.structureCreation.generalInfo.title" | translate }}</span
      >
    </div>
    <div class="form-container">
      <structure-form [form]="formGeneralInfo"></structure-form>
    </div>
  </div>
  <div class="corner">
    <span class="corner-text">1/3</span>
  </div>
  <button
    id="general-info-next-button"
    mat-raised-button
    (click)="redirectToMeasurePoints()"
    [disabled]="!formGeneralInfo.valid"
    class="next-button"
    color="primary"
  >
    {{ "common.nextStep" | translate }}
  </button>
</div>
