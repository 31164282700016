import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "../../shared/shared.module";
import { ConfigPageComponent, DialogRemoveEmail } from "./config-page";
import { ConfigPageRoutingModule } from "./config-page-routing.module";

const COMPONENTS = [ConfigPageComponent, DialogRemoveEmail];
@NgModule({
  imports: [
    ConfigPageRoutingModule,
    SharedModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class ConfigPageModule {}
