import { Component, EventEmitter, HostListener, Output } from "@angular/core";
@Component({
  selector: "side-panel",
  templateUrl: "./side-panel.component.html",
  styleUrls: ["./side-panel.component.scss"],
})
export class SidePanelComponent {
  @Output() public closePanelEvent = new EventEmitter<boolean>();

  public constructor() {}

  @HostListener("window:keyup", ["$event"])
  public handleKeyUp(event: KeyboardEvent) {
    if (event.code === "Escape") {
      this.closePanel();
    }
  }

  public closePanel() {
    this.closePanelEvent.emit(true);
  }
}
