import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  Category,
  RemarkableEventType,
} from "../../models/remarkable-event.model";
import { EventsTypesService } from "../../services/utils/events-types.service";
@Component({
  selector: "event-type-select",
  templateUrl: "event-type-select.component.html",
  styleUrls: ["event-type-select.component.scss"],
})
export class EventTypeSelectComponent implements OnInit {
  @Input() public form!: FormControl<RemarkableEventType[]>;
  public categories!: Category[];

  public constructor(private eventsTypesService: EventsTypesService) {}

  public ngOnInit() {
    this.categories = this.eventsTypesService.getLogCategories();
  }
}
