<div class="outer-content">
  <retrieve-panel
    *ngIf="isMonitored$ | async"
    [structure]="structure$ | async"
  ></retrieve-panel>

  <structure-sensors-table
    [structure]="structure$ | async"
  ></structure-sensors-table>
</div>
