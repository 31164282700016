<side-panel (closePanelEvent)="closePanel()">
  <div class="title">
    {{ "component.sidePanelMeasuresAuthorizations.title" | translate }}
  </div>
  <div class="side-panel-body">
    <div class="panel-header">
      <div class="warning-autorization">
        <div>
          {{
            "component.sidePanelMeasuresAuthorizations.warning"
              | translate
                : {
                    date: requestedDate | localizedDate: "short" : "UTC"
                  }
          }}
        </div>
        <div>
          {{ "component.sidePanelMeasuresAuthorizations.rate" | translate }}
        </div>
      </div>
    </div>
    <mat-expansion-panel class="measures" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="header-select">
            <mat-checkbox
              id="side-panel-measures-authorizations-toggle-all-checkbox"
              (click)="$event.stopPropagation()"
              (change)="toggleAll()"
              [checked]="isAllSelected()"
              >{{
                "component.sidePanelMeasuresAuthorizations.allRaws" | translate
              }}</mat-checkbox
            >
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list
        #selection
        id="side-panel-measures-authorizations-checkbox-list"
        class="measures-list"
        dense
      >
        <mat-list-option
          *ngFor="let measure of measures"
          [id]="
            'side-panel-measures-authorizations-' +
            measure.measurePointNumber +
            '-checkbox'
          "
          checkboxPosition="before"
          [value]="measure"
        >
          {{ "common.measure" | translate }} {{ measure.measurePointNumber }}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <div class="footer">
      <div class="footer-title">
        {{
          "component.sidePanelMeasuresAuthorizations.confirmDemand" | translate
        }}
      </div>

      <div class="footer-body">
        <button
          id="side-panel-measures-authorizations-yes-button"
          mat-raised-button
          color="primary"
          (click)="authorize()"
          [disabled]="!isOneOrMoreSelected() || loading"
        >
          {{ "common.yes" | translate }}
          <mat-icon *ngIf="loading"
            ><mat-spinner diameter="20"></mat-spinner
          ></mat-icon>
        </button>
        <button
          id="side-panel-measures-authorizations-no-button"
          mat-raised-button
          color="primary"
          (click)="closePanel()"
        >
          {{ "common.no" | translate }}
        </button>
      </div>
    </div>
  </div>
</side-panel>
