<header>
  <div class="left-side">
    <button class="menu" (click)="redirectToLanding()">
      <img class="logo" src="../assets/s-lynks-logo.png" alt="" />
      <img class="logo" src="../assets/s-lynks-text.png" alt="S-lynks" />
    </button>

    <button
      id="app-home-button"
      mat-raised-button
      class="header-button"
      routerLink="/landing"
      routerLinkActive="page-active"
    >
      <mat-icon>home</mat-icon>
    </button>
    <div class="buttons-group" *ngxPermissionsOnly="'ui:show'">
      <button
        id="app-events-button"
        mat-raised-button
        class="header-button"
        routerLink="/events"
        routerLinkActive="page-active"
        *ngxPermissionsOnly="['ui:events:show']"
        [matBadge]="getUnreadEventsNumber(eventsUnread$ | async)"
      >
        <mat-icon>history</mat-icon>
      </button>

      <button
        id="app-config-button"
        mat-raised-button
        class="header-button"
        routerLink="/config"
        routerLinkActive="page-active"
        *ngxPermissionsOnly="['config:platform:manage']"
      >
        <img
          src="../assets/icons/platform-config.png"
          [alt]="'app.platformConfig' | translate"
        />
      </button>
      <button
        id="app-invoices-button"
        mat-raised-button
        class="header-button"
        routerLink="/invoices"
        routerLinkActive="page-active"
        *ngxPermissionsOnly="['invoice:read']"
        matTooltip=""
      >
        <mat-icon>attach_money</mat-icon>
      </button>

      <button
        [matMenuTriggerFor]="legalMenu"
        id="app-legal-button"
        mat-raised-button
        class="header-button legal"
        (click)="loadFiles()"
        [class.active]="routerLinkActive('/file/legal')"
        routerLinkActive="page-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ "common.legal" | translate }}
        <mat-menu #legalMenu="matMenu">
          <ng-container *ngIf="loadingFile | async; else elseIsLegalLoaded">
            <div mat-menu-item class="loading" disabled>
              <span>
                {{ "common.loading" | translate }}
              </span>
              <mat-spinner [diameter]="24"></mat-spinner>
            </div>
          </ng-container>
          <ng-template #elseIsLegalLoaded>
            <button
              *ngFor="let legal of legalFiles$ | async"
              id="app-legal-button-{{ legal.name }}"
              mat-menu-item
              routerLink="{{ legal.path }}"
            >
              {{ legal.name }}
            </button>
            <div
              *ngIf="(legalFiles$ | async).length === 0"
              mat-menu-item
              disabled
            >
              <span>
                {{ "common.noDocumentsAvailable" | translate }}
              </span>
            </div>
          </ng-template>
        </mat-menu>
      </button>
    </div>
  </div>

  <div class="right-side">
    <div class="buttons-group">
      <button
        [matMenuTriggerFor]="documentationMenu"
        id="app-documentation-button"
        mat-raised-button
        class="header-button"
        (click)="loadFiles()"
        [class.active]="
          routerLinkActive('/file/documentation') ||
          routerLinkActive('/file/release-notes')
        "
        routerLinkActive="page-active"
        [routerLinkActiveOptions]="{ exact: true }"
        matTooltip=""
        *ngxPermissionsOnly="'ui:show'"
      >
        <mat-icon>description</mat-icon>
        <mat-menu #documentationMenu="matMenu">
          <ng-container
            *ngIf="loadingFile | async; else elseIsDocumentationLoaded"
          >
            <div mat-menu-item class="loading" disabled>
              <span>
                {{ "common.loading" | translate }}
              </span>
              <mat-spinner [diameter]="24"></mat-spinner>
            </div>
          </ng-container>
          <ng-template #elseIsDocumentationLoaded>
            <button
              *ngIf="(releaseNotesFiles$ | async).length > 0"
              id="app-documentation-button-release-notes"
              class="release-notes"
              mat-menu-item
              routerLink="{{ getPathOfCurrentReleaseNotes$() | async }}"
            >
              {{ "common.releaseNotes" | translate }}
            </button>
            <button
              *ngFor="let documentation of documentationFiles$ | async"
              id="app-documentation-button-{{ documentation.name }}"
              mat-menu-item
              routerLink="{{ documentation.path }}"
            >
              {{ documentation.name }}
            </button>
          </ng-template>
        </mat-menu>
      </button>

      <button
        [matMenuTriggerFor]="accountMenu"
        id="app-profile-button"
        mat-raised-button
        class="header-button"
      >
        <mat-icon>manage_accounts</mat-icon>
        <mat-menu #accountMenu="matMenu">
          <span
            id="app-notification-settings-username"
            class="username"
            mat-menu-item
            disabled
          >
            {{ getUsername() }}
          </span>
          <button
            id="app-notification-settings-button"
            mat-menu-item
            routerLink="/notification-settings"
            [disabled]="!(hasSomeNotificationPermissions$ | async)"
            [matTooltip]="
              (hasSomeNotificationPermissions$ | async)
                ? undefined
                : ('page.notificationSettings.notificationMissingPermission'
                  | translate)
            "
          >
            {{ "page.notificationSettings.notificationPreference" | translate }}
          </button>
          <button
            id="app-logout-button"
            class="logout-button"
            mat-menu-item
            (click)="logout()"
          >
            {{ "common.logout" | translate }}<mat-icon>logout</mat-icon>
          </button>
        </mat-menu>
      </button>
    </div>
    <div class="language-select">
      <language-select (setLocale)="setLocale($event)"></language-select>
    </div>
  </div>
</header>

<div class="header-page">
  <page-header></page-header>
</div>

<router-outlet></router-outlet>
