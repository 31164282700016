<div class="outer-content">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <div class="body-content">
    <div class="header-content">
      <span
        id="measure-points-back-button"
        class="blue-previous"
        (click)="backToPreviousPage()"
        ><mat-icon>arrow_backward</mat-icon></span
      >
      <span class="header-title"
        >{{ 'page.structureCreation.measurePoints.title' | translate }}</span
      >
    </div>
    <div class="table-container">
      <div class="table-title">
        {{ "common.measuringPoints" | translate }}<span class="asterisk"
          >*</span
        >
      </div>
      <div class="overlay-container">
        <div class="overlay-form" *ngIf="!createButtonHasBeenClicked">
          <form
            [formGroup]="formPoints"
            (ngSubmit)="onSubmit()"
            class="form-points"
          >
            <mat-form-field class="input-points">
              <mat-label
                >{{ "page.structureCreation.measurePoints.enterNumberPoints" |
                translate }}</mat-label
              >
              <input
                id="measure-points-fied-point-number"
                matInput
                formControlName="numberOfPoints"
                type="number"
                [autofocus]
              />
            </mat-form-field>
            <button
              id="measure-points-create-button"
              class="form-button"
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!formPoints.valid"
            >
              {{ 'common.create' | translate }}
            </button>
          </form>
        </div>
        <measure-points-table
          (removePoint)="removeMeasurePoint($event)"
          (saveComment)="saveCommentInModel($event)"
        ></measure-points-table>
        <div class="table-actions">
          <button
            id="measure-points-add-point-button"
            *ngIf="createButtonHasBeenClicked"
            class="add-button"
            mat-raised-button
            color="primary"
            (click)="addMeasurePoint()"
          >
            {{ 'common.addPoint' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="corner">
    <span class="corner-text">2/3</span>
  </div>
  <button
    id="measure-points-finalize-button"
    class="bottom-page-button"
    mat-raised-button
    (click)="createAndredirectToSynthesis()"
    [disabled]="!formPoints.valid || !createButtonHasBeenClicked"
    color="primary"
  >
    {{ 'common.finalize' | translate }}
  </button>
</div>
