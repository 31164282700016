<h2 mat-dialog-title>
  {{
    "page.structure.details.structureSensors.dialog.retrieveDemand" | translate
  }}
</h2>
<div mat-dialog-content>
  {{
    "page.structure.details.structureSensors.dialog.retrieveDemandWillBeSend"
      | translate
  }}
</div>
<mat-dialog-actions>
  <button
    id="dialog-on-retrieve-demand-ok"
    mat-flat-button
    color="primary"
    mat-dialog-close
  >
    {{ "common.ok" | translate }}
  </button>
</mat-dialog-actions>
