import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "switch-button",
  templateUrl: "./switch-button.component.html",
  styleUrls: ["./switch-button.component.scss"],
})
export class SwitchButtonComponent {
  @Input() public buttonId = "switch-button";
  @Input() public state!: "first" | "second";
  @Output() public toggle = new EventEmitter<void>();
  @Input() public firstStateTranslationKey!: string;
  @Input() public secondStateTranslationKey!: string;
  @Input() public isSmall = false;
  @Input() public disabled = false;
  @Input() public ballColor = "#5e636e";
  @Input() public firstColor = "#dee3ed";
  @Input() public secondColor = "#dee3ed";

  public onToggle() {
    this.toggle.emit();
  }
}
