import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../services/auth/auth.guard";
import { FilePageComponent } from "./file-page";

const routes: Routes = [
  {
    path: "file/:category/:locale/:name",
    component: FilePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "file/:category/:name",
    component: FilePageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FilePageRoutingModule {}
