import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxPermissionsModule } from "ngx-permissions";
import { LocalizedDatePipe } from "../services/utils/LocalizedDate.pipe";
import { LocalizedDecimalPipe } from "../services/utils/LocalizedDecimal.pipe";
import { SnackbarService } from "../services/utils/snackbar.service";
import { TimeAgoPipe } from "../services/utils/TimeAgo.pipe";
@NgModule({
  imports: [MatSnackBarModule],
  exports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgxPermissionsModule,
    MatIconModule,
    LocalizedDatePipe,
    LocalizedDecimalPipe,
    TimeAgoPipe,
    MatProgressSpinnerModule,
  ],
  declarations: [LocalizedDatePipe, LocalizedDecimalPipe, TimeAgoPipe],
  providers: [SnackbarService],
})
export class SharedModule {}
