import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { BusinessService } from "src/app/services/network/business.service";
import { StorageService } from "src/app/services/utils/storage.service";
import { environment } from "../../../environments/environment";
import { HashId } from "../../models/structure.model";
import { AuthService } from "../../services/auth/auth.service";
import { removeNullishValues } from "../../services/utils/utils";

@Component({
  selector: "landing-page",
  templateUrl: "./landing-page.html",
  styleUrls: ["./landing-page.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ transform: "scale(0.5)", opacity: 0 }),
        animate("0.5s cubic-bezier(.2, -0.6, 0.2, 1.5)"),
        style({ transform: "scale(1)", opacity: 1 }),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LandingPage implements OnInit {
  @ViewChild("footer") private footer!: ElementRef;

  public isAtBottom = true;

  @HostListener("window:scroll", ["$event"])
  public onScroll() {
    this.isAtBottom =
      document.documentElement.offsetHeight +
        document.documentElement.scrollTop >=
      document.documentElement.scrollHeight;
  }

  public version: string = environment.version;

  public form = new FormGroup({
    offset: new FormControl<number>(0),
    limit: new FormControl<number>(20),
    nameSearch: new FormControl<string>(""),
    customerSearch: new FormControl<string>(""),
    contactSearch: new FormControl<string>(""),
    types: new FormControl<string[]>([]),
    status: new FormControl<string[]>([]),
    activationDate: new FormGroup({
      start: new FormBuilder().nonNullable.control(""),
      end: new FormBuilder().nonNullable.control(""),
    }),
    creationDate: new FormGroup({
      start: new FormBuilder().nonNullable.control(""),
      end: new FormBuilder().nonNullable.control(""),
    }),
  });

  public canReadStructures!: boolean;

  private subscriptions: Subscription = new Subscription();

  public constructor(
    private businessService: BusinessService,
    private storage: StorageService,
    private authService: AuthService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    setTimeout(() => this.onScroll(), 0);

    this.initCanReadStructures();

    const pageTitle = this.canReadStructures
      ? "component.pageHeader.listOfStructures"
      : "common.home";
    this.storage.setPageHeader({
      showHomeButton: false,
      showBackButton: false,
      showNavLinks: true,
      pageTitle,
    });

    this.subscriptions.add(
      this.form.valueChanges.subscribe(async () =>
        this.putFiltersInQueryParams(),
      ),
    );

    this.subscriptions.add(
      this.route.queryParams
        .pipe(first())
        .subscribe((params) => this.putQueryParamsInFilters(params)),
    );

    this.subscriptions.add(
      this.translate.onLangChange.subscribe(() => {
        this.businessService.getFiles();
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public redirectToStructureCreation() {
    return this.router.navigate(["structure-creation-general"]);
  }

  public downloadArchive($event: DownloadArchiveType) {
    if ($event.type === "deployment") {
      this.downloadDeploymentArchive($event.structureId);
    } else {
      this.downloadConfigurationArchive($event.structureId);
    }
  }

  protected downloadDeploymentArchive(structureId: HashId) {
    this.businessService.getDeploymentArchive(structureId);
  }

  protected downloadConfigurationArchive(structureId: HashId) {
    this.businessService.getConfigurationArchive(structureId);
  }

  public redirectToWorkflowPage($event: StructureWorkflowEvent) {
    if (
      this.authService
        .getScopes()
        .includes("structure:deployment_report:update")
    ) {
      return this.router.navigate(
        ["structure", $event.id, "structure-finalization"],
        {
          queryParams: {
            step: 0,
          },
        },
      );
    } else if (
      this.authService
        .getScopes()
        .includes("structure:deployment_report:validate")
    ) {
      return this.router.navigate([
        "structure",
        $event.id,
        "structure-validation",
      ]);
    } else if (this.authService.getScopes().includes("structure:approve")) {
      return this.router.navigate([
        "structure",
        $event.id,
        "structure-approval",
      ]);
    }
  }

  public scrollToFooter(): void {
    this.footer.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  private initCanReadStructures() {
    const structureScopes = [
      "structure:created:read",
      "structure:demobilized:read",
      "structure:deployed:read",
      "structure:monitored:read",
      "structure:validated:read",
    ];
    const userScopes = Object.keys(this.permissionsService.getPermissions());
    this.canReadStructures = structureScopes.some((scope) =>
      userScopes.includes(scope),
    );
  }

  private async putFiltersInQueryParams() {
    const {
      offset,
      limit,
      nameSearch,
      customerSearch,
      contactSearch,
      types,
      status,
      activationDate,
      creationDate,
    } = this.form.getRawValue();

    const fromDateActivation = activationDate.start;
    const toDateActivation = activationDate.end;
    const fromDateCreation = creationDate.start;
    const toDateCreation = creationDate.end;

    const params = {
      offset,
      limit,
      nameSearch,
      customerSearch,
      contactSearch,
      types,
      status,
      fromDateActivation,
      toDateActivation,
      fromDateCreation,
      toDateCreation,
    };
    const queryParams = removeNullishValues(params);
    await this.router.navigate([], {
      queryParams,
    });
  }

  private putQueryParamsInFilters(params: Params) {
    const types =
      typeof params.types === "string" ? [params.types] : params.types;

    const status =
      typeof params.status === "string" ? [params.status] : params.status;

    const {
      offset,
      limit,
      nameSearch,
      customerSearch,
      contactSearch,
      fromDateActivation,
      toDateActivation,
      fromDateCreation,
      toDateCreation,
    } = params;

    const values = {
      offset: offset ?? 0,
      limit: limit ?? 20,
      nameSearch: nameSearch ?? "",
      customerSearch: customerSearch ?? "",
      contactSearch: contactSearch ?? "",
      types: types ?? [],
      status: status ?? [],
      activationDate: {
        start: fromDateActivation ?? "",
        end: toDateActivation ?? "",
      },
      creationDate: {
        start: fromDateCreation ?? "",
        end: toDateCreation ?? "",
      },
    };
    this.form.setValue(values);
  }
}

interface StructureWorkflowEvent {
  id: string;
}

interface DownloadArchiveType {
  type: string;
  structureId: HashId;
  structureName: string;
}
