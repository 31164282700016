<form [formGroup]="form">
  <div class="form-header">
    <span>{{ formHeader }}</span>
  </div>
  <div class="input-customer-code">
    <mat-form-field>
      <mat-label
        >{{ "component.structureForm.customerCode" | translate }}
      </mat-label>
      <input
        id="structure-billing-form-input-customer-code"
        matInput
        formControlName="customerCode"
      />
      <mat-hint *ngIf="mode === 'approving'">{{ customerCodeHint }}</mat-hint>
      <mat-error *ngIf="form.controls['customerCode'].invalid">{{
        "component.structureForm.customerCodeRequired" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{
        "component.structureForm.purchaseOrder" | translate
      }}</mat-label>
      <input
        id="structure-billing-form-input-purchase-order"
        matInput
        formControlName="purchaseOrder"
      />
      <mat-hint *ngIf="mode === 'approving'">{{ purchaseOrderHint }}</mat-hint>

      <mat-error *ngIf="form.controls['purchaseOrder'].invalid">{{
        "component.structureForm.purchaseOrderRequired" | translate
      }}</mat-error>
    </mat-form-field>
  </div>
</form>
