<mat-chip-listbox>
  <mat-chip-option
    *ngFor="let chip of chips$ | async"
    (removed)="onRemove(chip)"
  >
    {{ chip.text }}
    <button
      matChipRemove
      [id]="'structure-measures-chip-list-remove-' + chip.filter"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-option>
</mat-chip-listbox>
