import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import {
  Category,
  RemarkableEventType,
} from "../../models/remarkable-event.model";
import { NotificationService } from "../../services/network/notification.service";
import { EventsTypesService } from "../../services/utils/events-types.service";
import { SnackbarService } from "../../services/utils/snackbar.service";
import { StorageService } from "../../services/utils/storage.service";
@Component({
  selector: "notification-settings-page",
  templateUrl: "./notification-settings-page.html",
  styleUrls: ["./notification-settings-page.scss"],
})
export class NotificationSettingsPage {
  public form = new FormBuilder().nonNullable.control<RemarkableEventType[]>(
    [],
  );
  public isLoading$ = new BehaviorSubject(true);
  public categories!: Category[];

  public constructor(
    private notificationService: NotificationService,
    private storage: StorageService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private eventsTypesService: EventsTypesService,
  ) {}

  public ngOnInit() {
    this.categories = this.eventsTypesService.getNotificationCategories();

    this.storage.setPageHeader({
      showHomeButton: true,
      showBackButton: false,
      showNavLinks: false,
      pageTitle: "page.notificationSettings.notificationPreference",
    });

    this.notificationService.getUserSettings();

    this.notificationService.userSettingsStream().subscribe((userSettings) => {
      if (userSettings) this.updateForm(userSettings.mailNotifications);
      this.isLoading$.next(false);
    });
  }

  private updateForm(userMailNotifications: string[]) {
    this.form.patchValue(userMailNotifications);
  }

  public onFormSubmit() {
    const userMailAlerts = this.form.value;
    this.isLoading$.next(true);
    this.notificationService
      .putUserSettingsMailAlerts(userMailAlerts)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe(() => {
        this.translate
          .get("page.notificationSettings.mailNotificationsUpdated")
          .subscribe((text) => this.snackBar.open(text));
      });
  }

  public toggleAll($event: MatCheckboxChange) {
    if ($event.checked) {
      this.updateForm(this.eventsTypesService.getNotificationTypes());
    } else {
      this.updateForm([]);
    }
    this.form.markAsDirty();
  }

  public isAllSelected() {
    return this.eventsTypesService
      .getNotificationTypes()
      .every((notificationType) => this.form.value.includes(notificationType));
  }
}
