import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EventStructure } from "../../models/event-structure.model";
import { Pagination } from "../../models/pagination.model";
import {
  RemarkableEvent,
  RemarkableEventType,
  remarkableEventTypes,
} from "../../models/remarkable-event.model";
import { UserSettings } from "../../models/user.model";
import { AuthService } from "../auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private readonly serviceUrl: string = "api/v1/notification";
  private readonly userSettings$ = new BehaviorSubject<
    UserSettings | undefined
  >(undefined);

  public constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public userSettingsStream(): Observable<UserSettings> {
    return this.userSettings$.pipe(
      filter((value) => !!value),
    ) as Observable<UserSettings>;
  }

  public getUserSettings() {
    this.http
      .get<UserSettings>(
        `${environment.apiUrl}/${
          this.serviceUrl
        }/user/${this.authService.getUserId()}/settings`,
      )
      .pipe(tap((userSettings) => this.userSettings$.next(userSettings)))
      .subscribe();
  }

  public putUserSettingsMailAlerts(
    mailNotifications: string[],
  ): Observable<UserSettings> {
    return this.http
      .put<UserSettings>(
        `${environment.apiUrl}/${
          this.serviceUrl
        }/user/${this.authService.getUserId()}/settings`,
        {
          ...this.userSettings$.value!,
          mailNotifications,
        },
      )
      .pipe(
        tap(() =>
          this.userSettings$.next({
            ...this.userSettings$.value!,
            mailNotifications,
          }),
        ),
      );
  }

  public getEvents(
    offset = 0,
    limit = 20,
    filters: EventFilters,
  ): Observable<Pagination<RemarkableEvent>> {
    let url = `${environment.apiUrl}/${
      this.serviceUrl
    }/event?markRead&offset=${offset.toString()}&limit=${limit.toString()}`;

    if (filters.date?.start) url += `&fromDate=${filters.date.start}`;
    if (filters.date?.end) url += `&toDate=${filters.date?.end}`;
    if (filters.structureId) url += `&structureId[]=${filters.structureId}`;
    if (filters.customerId) url += `&customerId[]=${filters.customerId}`;
    if (filters.contains) url += `&contains=${filters.contains}`;

    /* We should always have some types in that request to avoid having some events that are not supposed to be displayed in the front app */
    const types =
      !filters.types || filters.types.length === 0
        ? [...remarkableEventTypes]
        : filters.types;
    types.forEach((type) => (url += `&type[]=${type}`));

    return this.http.get<Pagination<RemarkableEvent>>(url);
  }

  public getEventStructures(nameAutocomplete: string) {
    return this.http.get<EventStructure[]>(
      `${environment.apiUrl}/${this.serviceUrl}/event-structure-autocomplete`,
      { params: { nameAutocomplete } },
    );
  }
}

export interface EventFilters {
  date?: {
    start?: string;
    end?: string;
  };
  structureId?: string;
  customerId?: string;
  types?: RemarkableEventType[];
  contains?: string;
}

export type EventFiltersForm = FormGroup<{
  date: FormGroup<{
    start: FormControl<string>;
    end: FormControl<string>;
  }>;
  structureId: FormControl<string>;
  customerId: FormControl<string>;
  types: FormControl<RemarkableEventType[]>;
  contains: FormControl<string>;
}>;
