<div class="outer-content">
  <div class="body-content" #fileContent>
    <div class="header-content">
      <div class="header-top">
        <div class="header-title">
          {{ 'page.structureCreation.synthesis.title' | translate }}
        </div>
        <div
          id="synthesis-page-header-download-button"
          class="header-download"
          (click)="downloadSynthesis()"
        >
          <mat-icon class="download-icon">download</mat-icon>
        </div>
      </div>
      <div class="header-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1694.322 120.995">
          <path
            id="Path_1108"
            d="M15892-2076.05s168.4-26.594 422.164-11.134 251.912 55.878 726.205 20.041 273.139-21.636 390.877-31.325 154.076 24.21 154.076 24.21v-98.52H15892z"
            class="header-svg"
            data-name="Path 1108"
            transform="translate(-15891.5 2173.278)"
          />
        </svg>
        <div class="header-round-doc">
          <mat-icon>description</mat-icon>
        </div>
      </div>
    </div>
    <div class="form-container">
      <structure-form
        [form]="formGeneralInfo"
        [readMode]="true"
        (download)="downloadStructureAttachedFile()"
      ></structure-form>
    </div>
    <div class="certificate-container">
      <certificate-display
        (download)="downloadArchive($event)"
      ></certificate-display>
    </div>
    <div class="measure-points-container">
      <div class="round-measure-point">
        <mat-icon>pin_drop</mat-icon>
      </div>
      <div class="top-triangle"></div>
      <div class="table-container">
        <div class="table-component">
          <measure-points-table
            [measurePoints$]="measurePoints$"
          ></measure-points-table>
        </div>
      </div>
      <div class="bottom-triangle"></div>
    </div>
  </div>
  <button
    id="synthesis-page-home-button"
    mat-raised-button
    (click)="redirectToLanding()"
    color="primary"
  >
    {{ 'common.home' | translate }}
  </button>
</div>
