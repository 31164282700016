import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: "side-panel-json",
  templateUrl: "./side-panel-json.component.html",
  styleUrls: ["./side-panel-json.component.scss"],
})
export class SidePanelJsonComponent {
  @Input() public title!: string;
  @Input() public jsonBody!: Record<string, unknown>;
  @Output() public closePanelEvent = new EventEmitter<boolean>();

  @Input() public downloadedFileName!: string;

  public constructor(private sanitizer: DomSanitizer) {}

  public getDownloadData() {
    // it's safe to bypass angular's security because we used encodeURIComponent to avoid any scripting to be injected
    return this.sanitizer.bypassSecurityTrustUrl(
      `data:application/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(this.jsonBody, undefined, 2),
      )}`,
    );
  }

  public closePanel() {
    this.closePanelEvent.emit(true);
  }
}
