<mat-form-field>
  <mat-label>
    {{ "component.eventFilter.filterByStructure" | translate }}</mat-label
  >
  <input
    type="text"
    matInput
    [formControl]="nameField"
    [matAutocomplete]="auto"
  />
</mat-form-field>

<mat-autocomplete
  #auto="matAutocomplete"
  id="structure-select-autocomplete"
  [displayWith]="displayName"
  (optionSelected)="select($event)"
>
  <mat-option
    *ngFor="let structure of structures$ | async"
    [id]="'structure-select-option-' + structure.id"
    [value]="structure"
    >{{ structure.name }}</mat-option
  >
</mat-autocomplete>
