<card *ngIf="savedConfig">
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="title">
      {{ "component.timeoutConfig.title" | translate }}
      <switch-button
        [state]="timeoutDefined ? 'second' : 'first'"
        [isSmall]="true"
        [disabled]="!hasUpdateRight || (loading$ | async)"
        (toggle)="toggle()"
        ballColor="white"
        firstColor="#5e636e"
        secondColor="#009de0"
        buttonId="timeout-config-toggle"
      ></switch-button>
    </div>
    <div *ngIf="timeoutDefined">
      <p>
        {{ "component.timeoutConfig.defineTimeout" | translate }}
      </p>
      <div>
        <mat-form-field>
          <mat-label>
            {{ "component.timeoutConfig.fieldLabel" | translate }}
          </mat-label>
          <input
            matInput
            type="number"
            formControlName="timeoutInHours"
            [matAutocomplete]="auto"
            id="timeout-config-input"
            [reactiveDisabled]="!hasUpdateRight || (loading$ | async)"
          />
          <span class="suffix" matSuffix>
            {{ "component.timeoutConfig.fieldUnit" | translate }} &nbsp;
          </span>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions$ | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <ng-container *ngIf="form.dirty">
          <button
            type="submit"
            [disabled]="!form.valid || (loading$ | async)"
            mat-raised-button
            color="primary"
            id="timeout-config-validate"
          >
            {{ "common.validate" | translate }}
          </button>
          <button
            type="button"
            [disabled]="loading$ | async"
            (click)="cancel()"
            mat-raised-button
            color="accent"
            id="timeout-config-cancel"
          >
            {{ "common.cancel" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!timeoutDefined">
      {{ "component.timeoutConfig.noTimeout" | translate }}
    </div>
  </form>
</card>
