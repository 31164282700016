import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "../../services/auth/auth.guard";
import { ConfigPageComponent } from "./config-page";
import { ConfigPageResolver } from "./config-page.resolver";

const routes: Routes = [
  {
    path: "config",
    resolve: { ConfigPageResolver },
    component: ConfigPageComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ["config:platform:manage"],
        redirectTo: "/landing",
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ConfigPageResolver],
})
export class ConfigPageRoutingModule {}
