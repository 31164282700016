import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";

export class PaginatorIntlService extends MatPaginatorIntl {
  private rangeLabel = "";

  public constructor(private translate: TranslateService) {
    super();

    this.getRangeLabel = (page: number, pageSize: number, length: number) =>
      this.getRangeLabelMethod(page, pageSize, length);

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  private getRangeLabelMethod(page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return `0  ${this.rangeLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${length}`;
  }

  private translateLabels() {
    forkJoin([
      this.translate.get("angular.paginator.itemsPerPageLabel"),
      this.translate.get("angular.paginator.nextPageLabel"),
      this.translate.get("angular.paginator.previousPageLabel"),
      this.translate.get("angular.paginator.rangeLabel"),
    ]).subscribe(([itemsPerPage, nextPage, previousPage, range]) => {
      this.itemsPerPageLabel = itemsPerPage;
      this.nextPageLabel = nextPage;
      this.previousPageLabel = previousPage;
      this.rangeLabel = range;
      this.changes.next();
    });
  }
}
