import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { ComponentsModule } from "../../components/components.module";
import { SharedModule } from "../../shared/shared.module";
import {
  DialogInvoiceTableEmail,
  InvoiceTableComponent,
} from "./invoice-table/invoice-table.component";
import { InvoicesPageComponent } from "./invoices-page";
import { InvoicesPageRoutingModule } from "./invoices-page-routing.module";

@NgModule({
  imports: [
    InvoicesPageRoutingModule,
    SharedModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ComponentsModule,
  ],
  declarations: [
    InvoicesPageComponent,
    InvoiceTableComponent,
    DialogInvoiceTableEmail,
  ],
  exports: [InvoicesPageComponent, DialogInvoiceTableEmail],
})
export class InvoicesPageModule {}
