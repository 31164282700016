import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Injectable, TemplateRef, ViewContainerRef } from "@angular/core";

@Injectable()
export class OverlayService {
  public constructor(private overlay: Overlay) {}
  public createOverlay(config: OverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }
  public attachTemplatePortal(
    overlayRef: OverlayRef,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    templateRef: TemplateRef<any>,
    vcRef: ViewContainerRef,
  ) {
    const templatePortal = new TemplatePortal(templateRef, vcRef);
    overlayRef.attach(templatePortal);
  }
  public positionGloballyCenter(): PositionStrategy {
    return this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }
}
