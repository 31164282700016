<div class="table-container">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <mat-table id="structure-table-structures" [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="name-header-cell">
        <search-input
          [form]="form.get('nameSearch')"
          label="common.nameId"
        ></search-input>
      </mat-header-cell>
      <mat-cell
        class="name-cell"
        *matCellDef="let row"
        attr.data-label="{{ 'common.nameId' | translate }} / {{
          'common.id' | translate
        }}"
      >
        <!-- Disable temporarily: https://sercel-shm.atlassian.net/browse/BSFM-2314
          <div
          [ngClass]="{
            'alert-warning': row.maxSohAlarmStatus === 'WARNING',
            'alert-error': row.maxSohAlarmStatus === 'ERROR',
            'alert-none': row.maxSohAlarmStatus === 'NONE'
          }"
        >
          <mat-icon>priority_high</mat-icon>
        </div> -->
        <div class="name-id-container">
          <span class="name">{{ row.name }}</span>
          <span class="id" *ngxPermissionsOnly="'ui:structures:id:show'">
            {{ row.id }}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>
        <filter-structure-type
          [form]="form.get('types')"
        ></filter-structure-type>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        attr.data-label="{{ 'common.type' | translate }}"
      >
        <structure-type-icon [structureType]="row.type"></structure-type-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef>
        {{ "component.structureTable.location" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="location-cell">
        <button (click)="openMapDialog(row)" *ngIf="row.thumbnail">
          <img
            [src]="row.thumbnail | async"
            class="thumbnail"
            [alt]="'component.structureTable.location' | translate"
          />
        </button>
        <span *ngIf="!row.thumbnail">-</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customer">
      <mat-header-cell *matHeaderCellDef>
        <search-input
          [form]="form.get('customerSearch')"
          label="component.structureTable.customer"
        ></search-input>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="customer-cell"
        attr.data-label="{{ 'component.structureTable.customer' | translate }}"
        >{{ row.customerId }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="contact">
      <mat-header-cell *matHeaderCellDef class="contact-header-cell">
        <search-input
          [form]="form.get('contactSearch')"
          label="common.contact"
        ></search-input>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="contact-cell"
        attr.data-label="{{ 'common.contact' | translate }}"
        >{{ row.contact }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="workflow">
      <mat-header-cell *matHeaderCellDef class="workflow-header-cell">
        <div class="workflow-container">
          <filter-structure-workflow
            [form]="form.get('status')"
          ></filter-structure-workflow>
          <button mat-icon-button (click)="openWorkflowDialog()">
            <mat-icon>info</mat-icon>
          </button>
        </div>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="workflow-cell"
        attr.data-label="{{ 'common.workflow' | translate }}"
      >
        <structure-workflow-status
          [status]="row.status"
        ></structure-workflow-status>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <mat-header-cell *matHeaderCellDef class="creation-date-header-cell">
        <app-date-range-picker
          [label]="'component.structureTable.creationDate'"
          pickerStyle="white-background"
          [form]="form.get('creationDate')"
        ></app-date-range-picker>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="creation-date-cell"
        attr.data-label="{{
          'component.structureTable.creationDate' | translate
        }}"
      >
        {{ row.creationDate | localizedDate: "shortDate2" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activationDate">
      <mat-header-cell *matHeaderCellDef class="activation-date-header-cell">
        <app-date-range-picker
          [label]="'component.structureTable.commissioningDate'"
          pickerStyle="white-background"
          [form]="form.get('activationDate')"
        ></app-date-range-picker>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="activation-date-cell"
        attr.data-label="{{
          'component.structureTable.commissioningDate' | translate
        }}"
      >
        {{
          row.commissioningDate
            ? (row.commissioningDate | localizedDate: "shortDate2")
            : "-"
        }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="action-header-cell">{{
        "common.actions" | translate
      }}</mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="action-cell"
        attr.data-label="{{ 'common.actions' | translate }}"
      >
        <div class="actions-block">
          <button
            [id]="'structure-table-reports-download-button-' + row.id"
            mat-mini-fab
            class="action-button action-margin"
            matTooltip="{{
              'component.structureTable.downloadReports' | translate
            }}"
            color="primary"
            [matMenuTriggerFor]="downloadMenu"
          >
            <icon-download></icon-download>
          </button>
          <button
            [id]="'structure-table-edit-button-' + row.id"
            mat-icon-button
            matTooltip="{{ getWorkflowActionTranslationKey(row.status) | translate }}"
            class="action-button"
            color="primary"
            (click)="redirectToWorkflow(row.id)"
            *ngIf="isAllowedToEdit(row)"
          >
            <mat-icon>edit_notes</mat-icon>
          </button>
          <button
            [id]="'structure-table-details-button-' + row.id"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'common.details' | translate }}"
            class="action-button"
            [routerLink]="'/structure/' + row.id"
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </div>
        <mat-menu #downloadMenu="matMenu">
          <button
            [id]="
              'structure-table-deployment-archive-download-button-' + row.id
            "
            mat-menu-item
            (click)="downloadDeploymentArchive(row.id)"
          >
            {{ "common.deploymentArchive" | translate }}
          </button>
          <button
            [id]="
              'structure-table-configuration-archive-download-button-' + row.id
            "
            mat-menu-item
            (click)="downloadConfigurationArchive(row.id)"
          >
            {{ "common.configurationArchive" | translate }}
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="structureListColumns$ | async"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: structureListColumns$ | async"
      [class.demobilized]="row.status === 'demobilized'"
    ></mat-row>
  </mat-table>

  <mat-paginator
    (page)="changePage($event)"
    [length]="(structurePagination$ | async)?.total"
    [pageIndex]="getPageIndex()"
    [pageSize]="this.form.get('limit')!.value"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
  ></mat-paginator>
</div>
