import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "localizedDate",
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  public constructor(private translateService: TranslateService) {}

  public transform(
    value: string | number | Date,
    format?: string,
    timezone?: string,
    locale?: string,
  ) {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    if (format === "shortDate2") {
      format =
        this.translateService.currentLang === "fr-FR" ? "dd/MM/yy" : "MM/dd/yy";
    }
    if (format === "UTCAllDigits") {
      format =
        this.translateService.currentLang === "fr-FR"
          ? "dd/MM/YYYY HH:mm"
          : "YYYY/MM/dd HH:mm";
    }
    return datePipe.transform(value, format, timezone, locale);
  }
}
