<form [formGroup]="form" class="deployment-report-confirmation">
  <div class="panels">
    <div class="panel nodes-panel" [ngClass]="{ disabled: nodesConfirmed }">
      <h2>
        {{ "component.deploymentReportConfirmation.listOfNodes" | translate }}
      </h2>
      <div class="lists flex-all-children-same-size">
        <div class="list associated-nodes-list">
          <div class="list-header">
            <h3>
              {{
                "component.deploymentReportConfirmation.listOfAssociatedNodes"
                  | translate
              }}
            </h3>
            <p>
              {{
                "component.deploymentReportConfirmation.associatedNodesParagraph"
                  | translate
              }}
            </p>
          </div>
          <mat-list>
            <mat-list-item *ngFor="let device of deploymentReport.devices">
              {{ device.serialNumber }}
            </mat-list-item>
          </mat-list>
        </div>
        <div class="list demobilized-nodes-list">
          <div class="list-header">
            <h3>
              {{
                "component.deploymentReportConfirmation.listOfDemobilizedNodes"
                  | translate
              }}
            </h3>
            <p>
              {{
                "component.deploymentReportConfirmation.demobilizedNodesParagraph"
                  | translate
              }}
            </p>
          </div>
          <mat-list>
            <mat-list-item *ngFor="let device of getDemobilizedNodes()">
              {{ device.serialNumber }}
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="buttons-bar">
        <button
          id="deployment-report-confirmation-nodes-cancel-button"
          mat-raised-button
          *ngIf="!nodesConfirmed"
          (click)="handleCancel()"
        >
          {{ "common.cancel" | translate }}
        </button>
        <button
          id="deployment-report-confirmation-nodes-confirm-button"
          mat-raised-button
          *ngIf="!nodesConfirmed"
          (click)="nodesConfirmed = true"
        >
          {{ "common.confirm" | translate }}
        </button>
        <button
          id="deployment-report-confirmation-nodes-edit-button"
          class="edit-button"
          color="primary"
          *ngIf="nodesConfirmed"
          (click)="nodesConfirmed = false"
          mat-raised-button
        >
          {{ "common.edit" | translate }}
        </button>
      </div>
    </div>
    <div
      class="panel gateways-panel"
      [ngClass]="{ disabled: gatewaysConfirmed }"
    >
      <h2>
        {{
          "component.deploymentReportConfirmation.listOfGateways" | translate
        }}
      </h2>
      <div class="lists flex-all-children-same-size">
        <div class="list">
          <div class="list-header">
            <h3>
              {{
                "component.deploymentReportConfirmation.listOfConfirmedGateways"
                  | translate
              }}
            </h3>
            <p>
              {{
                "component.deploymentReportConfirmation.checkGatewaySerialNumber"
                  | translate
              }}
            </p>
          </div>
          <mat-list>
            <mat-list-item *ngFor="let gateway of getGateways()">
              <mat-checkbox
                [id]="
                  'deployment-report-confirmation-gateways-checkbox-' +
                  gateway.serialNumber
                "
                labelPosition="before"
                [disabled]="gatewaysConfirmed"
                [formControlName]="gateway.serialNumber"
              >
                <span>{{ gateway.serialNumber }}</span>
                <span flex></span>
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="buttons-bar">
        <button
          id="deployment-report-confirmation-gateways-confirm-button"
          *ngIf="!gatewaysConfirmed"
          (click)="gatewaysConfirmed = true"
          [disabled]="!form.valid"
          mat-raised-button
        >
          {{ "common.confirm" | translate }}
        </button>
        <button
          id="deployment-report-confirmation-gateways-edit-button"
          class="edit-button dark-blue-button"
          *ngIf="gatewaysConfirmed"
          (click)="gatewaysConfirmed = false"
          mat-raised-button
        >
          {{ "common.edit" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="fixed-footer">
    <div class="footer-container">
      <button
        id="deployment-report-confirmation-validate-button"
        color="primary"
        mat-raised-button
        [disabled]="!(nodesConfirmed && gatewaysConfirmed)"
        (click)="handleValidate()"
      >
        <mat-icon aria-hidden="false" aria-label="Warning icon"
          >warning</mat-icon
        >
        {{
          "component.deploymentReportConfirmation.validateDefinitively"
            | translate
        }}
      </button>
    </div>
  </div>
  <div class="buttons-bar global-validate-bar"></div>
</form>
