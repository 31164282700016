import { Component, Input } from "@angular/core";
import { StructureType } from "../../models/structure.model";

@Component({
  selector: "structure-type-icon",
  templateUrl: "./structure-type-icon.component.html",
  styleUrls: ["./structure-type-icon.component.scss"],
})
export class StructureTypeIconComponent {
  @Input() public structureType: StructureType;

  public constructor() {
    this.structureType = "bridge";
  }
}
