import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageService } from "../utils/storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(public storage: StorageService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.indexOf("/auth/") === -1 &&
      request.url.indexOf("shm-sercel-translations") === -1
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storage.getAuthData()!.access_token}`,
          // This private MIME subtype can be used during content performed made by Express (see res.format())
          // to deliver response specificaly tailored to be consumed by this Angular web app.
          Accept: "application/json, text/plain, */*",
        },
      });
    }
    return next.handle(request);
  }
}
