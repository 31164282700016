<div class="outer-content">
  <div class="header-content">
    <div class="tab-group">
      <div
        class="tab"
        *ngFor="let tab of tabGroup$ | async"
        [id]="'structure-details-tab-' + tab.title"
        [routerLink]="[tab.route]"
        [routerLinkActive]="'active'"
      >
        <mat-icon
          *ngIf="tab.icon === 'measures'"
          [svgIcon]="tab.icon"
        ></mat-icon>
        <mat-icon *ngIf="tab.icon !== 'measures'">{{tab.icon}}</mat-icon>
        {{ tab.title | translate}}
      </div>
    </div>
  </div>
  <div class="body-content">
    <router-outlet></router-outlet>
  </div>
  <button
    id="structure-details-home-button"
    mat-raised-button
    (click)="redirectToLanding()"
    color="primary"
  >
    {{ 'common.home' | translate }}
  </button>
</div>
