<span class="old-measure-icon-container">
  <mat-icon class="main-icon">access_time</mat-icon>

  <mat-icon
    class="secondary-icon"
    matTooltip="{{ 'component.measuresTable.dateTooOld' | translate }}"
    >error</mat-icon
  >
  <span class="secondary-icon background"></span>
</span>
