<div class="dialog-content">
  <div class="header-content">
    <span>{{ "component.workflowStatus.infoWorkflow" | translate }} </span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="workflow-steps">
      <div class="workflow-steps">
        <div>
          <span class="workflow-step">1</span>
          <span class="status">{{
            "component.workflowStatus.waitingForDeployment" | translate
          }}</span>
        </div>
        <div>
          <span class="workflow-step">2</span>
          <span class="status">{{
            "component.workflowStatus.waitingForValidation" | translate
          }}</span>
        </div>
        <div>
          <span class="workflow-step">3</span>
          <span class="status">{{
            "component.workflowStatus.waitingForApproval" | translate
          }}</span>
        </div>
        <div>
          <span class="workflow-step">4</span>
          <span class="status">{{
            "component.workflowStatus.approved" | translate
          }}</span>
        </div>
        <div>
          <span class="workflow-step last-step">5</span>
          <span class="status">{{
            "component.workflowStatus.monitored" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="workflow-footer">
      <div>
        <div class="workflow-maintenance">
          <mat-icon>engineering</mat-icon>
          <span class="status">{{
            "component.workflowStatus.maintenance" | translate
          }}</span>
        </div>
        <div class="workflow-demobilized">
          <mat-icon>close</mat-icon>
          <span class="status">{{
            "component.workflowStatus.demobilized" | translate
          }}</span>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button mat-dialog-close>
          {{ "common.okUnderstood" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
