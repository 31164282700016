<div class="invoice-table">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <table
    mat-table
    [dataSource]="dataSource"
    [attr.aria-label]="'page.invoices.invoices' | translate"
  >
    <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef>
        {{ "page.invoices.invoiceTable.month" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row; let index = index"
        id="invoice-table-month-label-{{ index }}"
      >
        {{ row.month | localizedDate: "MMMM y" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ "page.invoices.invoiceTable.creationDate" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row; let index = index"
        id="invoice-table-date-label-{{ index }}"
      >
        <span *ngIf="row.invoice">
          {{
            "common.dateUTC"
              | translate
                : {
                    date:
                      row.invoice.creationDate | localizedDate: "short" : "UTC"
                  }
          }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="partialOrComplete">
      <th mat-header-cell *matHeaderCellDef>
        {{ "page.invoices.invoiceTable.partialOrComplete" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row; let index = index"
        id="invoice-table-complete-label-{{ index }}"
      >
        <span *ngIf="row.invoice">
          {{
            isInvoiceComplete(row.invoice.creationDate, row.month)
              ? ("page.invoices.invoiceTable.complete" | translate)
              : ("page.invoices.invoiceTable.partial" | translate)
          }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef class="cell-center">
        {{ "common.download" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let index = index" class="cell-center">
        <button
          (click)="downloadInvoice(row.invoice)"
          [disabled]="!row.invoice"
          [id]="'invoice-table-download-button-' + index"
          mat-mini-fab
          color="primary"
          attr.aria-label="{{ 'common.download' | translate }}"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="regenerate">
      <th mat-header-cell *matHeaderCellDef class="cell-center">
        {{ "page.invoices.invoiceTable.regenerate" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let index = index" class="cell-center">
        <button
          (click)="createInvoice(row.month)"
          [id]="'invoice-table-regenerate-button-' + index"
          mat-mini-fab
          color="primary"
          attr.aria-label="{{
            'page.invoices.invoiceTable.regenerate' | translate
          }}"
        >
          <mat-icon>autorenew</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="send">
      <th mat-header-cell *matHeaderCellDef class="cell-center">
        {{ "page.invoices.invoiceTable.send" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let index = index" class="cell-center">
        <button
          (click)="emailInvoice(row.invoice)"
          [disabled]="!row.invoice"
          [id]="'invoice-table-send-button-' + index"
          mat-mini-fab
          color="primary"
          attr.aria-label="{{ 'page.invoices.invoiceTable.send' | translate }}"
        >
          <mat-icon>send</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
