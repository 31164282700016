import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BehaviorSubject, from, Subscription } from "rxjs";
import { first, map } from "rxjs/operators";
import { DateRangePickerFormValue } from "../../components/date-range-picker/date-range-picker.component";
import { RemarkableEventType } from "../../models/remarkable-event.model";
import { StorageService } from "../../services/utils/storage.service";
import { removeNullishValues } from "../../services/utils/utils";
@Component({
  selector: "events-page",
  templateUrl: "events-page.html",
  styleUrls: ["events-page.scss"],
})
export class EventsPage implements OnInit {
  public isLoading$ = new BehaviorSubject(true);

  public form = new FormGroup({
    date: new FormGroup({
      start: new FormBuilder().nonNullable.control("", { updateOn: "blur" }),
      end: new FormBuilder().nonNullable.control("", { updateOn: "blur" }),
    }),
    structureId: new FormBuilder().nonNullable.control("", {
      updateOn: "blur",
    }),
    customerId: new FormBuilder().nonNullable.control("", { updateOn: "blur" }),
    types: new FormBuilder().nonNullable.control<RemarkableEventType[]>([]),
    contains: new FormBuilder().nonNullable.control(""),
  });

  private subscriptions: Subscription = new Subscription();

  public constructor(
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.storage.setPageHeader({
      showHomeButton: true,
      showBackButton: false,
      showNavLinks: false,
      pageTitle: "page.events.title",
    });

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(map(() => this.updateQueryParameters(this.form.getRawValue())))
        .subscribe(),
    );

    this.subscriptions.add(
      this.route.queryParams
        .pipe(first())
        .subscribe((params) => this.initFormValues(params)),
    );
  }

  private updateQueryParameters(values: FormValue) {
    const { date, structureId, customerId, types, contains } = values;

    const fromDate = date.start;
    const toDate = date.end;

    const params = {
      fromDate,
      toDate,
      structureId,
      customerId,
      types,
      contains,
    };
    const queryParams = removeNullishValues(params);
    return from(
      this.router.navigate([], {
        queryParams,
      }),
    );
  }

  private initFormValues(params: Params) {
    const types =
      typeof params.types === "string" ? [params.types] : params.types;

    const { fromDate, toDate, structureId, customerId, contains } = params;

    const values = {
      date: {
        start: fromDate ?? "",
        end: toDate ?? "",
      },
      structureId: structureId ?? "",
      customerId: customerId ?? "",
      types: types ?? [],
      contains: contains ?? "",
    };
    this.form.setValue(values);
  }
}

interface FormValue {
  date: DateRangePickerFormValue;
  structureId: string;
  customerId: string;
  types: string[];
  contains: string;
}
