import { Pagination } from "../../models/pagination.model";
import { ProcessingRequest } from "../../models/processing-request.model";

export function mapProcessingRequestPagination(
  pagination: Pagination<ProcessingRequest>,
): Pagination<ProcessingRequest> {
  return { ...pagination, data: pagination.data.map(mapProcessingRequest) };
}

export function mapProcessingRequest(
  processingRequest: ProcessingRequest,
): ProcessingRequest {
  return {
    ...processingRequest,
    creationDate: new Date(processingRequest.creationDate),
    requestedDate: new Date(processingRequest.requestedDate),
  };
}
