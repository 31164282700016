import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  DayOfTheWeek,
  ShortId,
  StructureType,
} from "../../models/structure.model";

export function createStructureFormGroup(): StructureFormGroup {
  return new FormGroup({
    type: new FormControl<StructureType | null>(null, Validators.required),
    name: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern(/^[^|]*$/),
    ]),
    contact: new FormControl<string | null>(null, [
      Validators.required,
      Validators.email,
    ]),
    regionId: new FormControl<ShortId | null>(null, Validators.required),
    dayOfWeekToOversight: new FormControl<DayOfTheWeek | null>(null),
    hourOfDayToOversight: new FormControl<number | null>(null, [
      Validators.max(23),
      Validators.min(0),
    ]),
    address: new FormControl<string | null>(null, Validators.required),
    location: new FormGroup<StructureFormLocationControls | null>({
      lat: new FormControl<number | null>(null),
      lng: new FormControl<number | null>(null),
      thumbnail: new FormControl<Blob | null>(null),
    }),
    customerCode: new FormControl<string | null>(null, Validators.required),
    purchaseOrder: new FormControl<string | null>(null, Validators.required),
    deploymentFile: new FormControl<Blob | null>(null, Validators.required),
    businessType: new FormControl<string | null>("shm", Validators.required),
  });
}

export type StructureFormGroup = FormGroup<{
  type: FormControl<StructureType | null>;
  name: FormControl<string | null>;
  contact: FormControl<string | null>;
  regionId: FormControl<ShortId | null>;
  dayOfWeekToOversight: FormControl<DayOfTheWeek | null>;
  hourOfDayToOversight: FormControl<number | null>;
  address: FormControl<string | null>;
  location: FormGroup<StructureFormLocationControls | null>;
  customerCode: FormControl<string | null>;
  purchaseOrder: FormControl<string | null>;
  deploymentFile: FormControl<Blob | null>;
  businessType: FormControl<string | null>;
}>;

export interface StructureFormLocationControls {
  lat: FormControl<number | null>;
  lng: FormControl<number | null>;
  thumbnail: FormControl<Blob | null>;
}
