import { Component, Input } from "@angular/core";

@Component({
  selector: "event-category-icon",
  templateUrl: "event-category-icon.component.html",
  styleUrls: ["event-category-icon.component.scss"],
})
export class EventCategoryIconComponent {
  @Input() public category!: string;
}
