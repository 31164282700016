import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SnackbarService } from "../utils/snackbar.service";
import { hasOwnProperty } from "../utils/utils";

export const ERROR_INTERCEPTOR_SKIP_HEADER = "X-Skip-Error-Interceptor";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
    private snackBar: SnackbarService,
    private router: Router,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(ERROR_INTERCEPTOR_SKIP_HEADER)) {
      const headers = request.headers.delete(ERROR_INTERCEPTOR_SKIP_HEADER);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(
      catchError((err) => {
        const body = this.getErrorBody(err);
        if (err.status === 403) {
          this.snackBar.open("snackbar.notHavePermission");
        } else if (err.status === 401) {
          this.snackBar.openReloadSnackbar("snackbar.tokenExpired");
        } else if (
          hasOwnProperty(body, "code") &&
          typeof body.code === "string"
        ) {
          const context =
            hasOwnProperty(body, "context") && typeof body.context === "object"
              ? (body.context as Record<string, unknown>)
              : undefined;
          this.snackBar.open(`api.error.${body.code}`, {
            interpolationParams: context,
          });
        } else {
          this.snackBar.open("snackbar.requestFailed");
        }

        return throwError(err);
      }),
    );
  }

  private getErrorBody(err: unknown) {
    let body = {};
    if (
      typeof err === "object" &&
      err !== null &&
      hasOwnProperty(err, "error")
    ) {
      if (typeof err.error === "string") {
        try {
          body = JSON.parse(err.error);
        } catch (error) {
          // ignore
        }
      } else if (typeof err.error === "object" && err.error !== null) {
        body = err.error;
      }
    }
    return body;
  }
}
