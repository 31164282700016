import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import fr from "javascript-time-ago/locale/fr";

TimeAgo.addDefaultLocale(fr);
TimeAgo.addLocale(en);

@Pipe({
  name: "timeAgo",
  pure: false,
})
export class TimeAgoPipe implements PipeTransform {
  private timeAgo: TimeAgo;
  public constructor(private translateService: TranslateService) {
    this.timeAgo = new TimeAgo(this.translateService.currentLang);
  }

  public transform(value: unknown) {
    const date: Date = new Date(value as Date);
    return this.timeAgo.format(date, "round");
  }
}
