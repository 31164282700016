import { Component, EventEmitter, Output } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { isoLangs } from "./languages";

@Component({
  selector: "language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"],
})
export class LanguageSelectComponent {
  @Output() public setLocale = new EventEmitter<string>();

  public constructor(public translate: TranslateService) {}

  public getLanguageNativeName(lang: string) {
    const language = this.getLanguage(lang);
    return isoLangs[language].nativeName;
  }

  public onLanguageChange($event: MatSelectChange) {
    this.setLocale.emit($event.value);
  }

  public getLanguage(lang: string) {
    return lang.slice(0, 2);
  }
}
