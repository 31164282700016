import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ActivatedRoute } from "@angular/router";
import { isEqual } from "lodash";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { finalize, map, mergeMap, take, tap } from "rxjs/operators";
import { Amc, AmcOnDemandMode, AmcWeeklyMode } from "src/app/models/amc.model";
import { BusinessService } from "src/app/services/network/business.service";
import {
  DayOfTheWeek,
  StructureMeasuringPoint,
} from "../../../../models/structure.model";
import { AuthService } from "../../../../services/auth/auth.service";
import { LocalizedDatePipe } from "../../../../services/utils/LocalizedDate.pipe";
import {
  isOnDemandAmcMode,
  isWeeklyAmcMode,
} from "../../../../services/utils/amc-utils";
import { EVERY_10_SECONDS } from "../../../../services/utils/globals";
import { SnackbarService } from "../../../../services/utils/snackbar.service";

@Component({
  selector: "structure-config",
  templateUrl: "./structure-config.component.html",
  styleUrls: ["./structure-config.component.scss"],
})
export class StructureConfigComponent implements OnInit, OnDestroy {
  public readMode: boolean;
  public structureId!: string;

  public weeklyForm = new FormGroup({
    dayOfWeekToOversight: new FormBuilder().nonNullable.control<DayOfTheWeek>(
      "monday",
      Validators.required,
    ),
    hourOfDayToOversight: new FormBuilder().nonNullable.control(0, [
      Validators.required,
      Validators.max(23),
      Validators.min(0),
    ]),
  });
  public onDemandForm = new FormGroup({
    nodesFilter: new FormBuilder().nonNullable.control<
      StructureMeasuringPoint[]
    >([], [Validators.required, Validators.minLength(1)]),
    rawdataList: new FormBuilder().nonNullable.control([]),
  });

  public weeklyToggleform = new FormControl();

  public amcInitialValue!: Amc;

  public waiting$ = new BehaviorSubject(false);

  public amc$ = new BehaviorSubject<Amc>({ id: 0, modes: [] });
  public now$ = new BehaviorSubject(new Date());
  public weeklyExpanded = true;
  public onDemandExpanded = false;

  private switchDuration = 30 * 60 * 1000; // 30mn
  private structuresWithSwitchDuration = ["2772ac312", "c0be47d5"];
  private subscriptions: Subscription = new Subscription();

  public constructor(
    private authService: AuthService,
    private businessService: BusinessService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private datePipe: LocalizedDatePipe,
    private snackBar: SnackbarService,
  ) {
    this.readMode = !this.authService
      .getScopes()
      .includes("structure:amc:update");
    if (this.readMode) this.weeklyToggleform.disable();
    this.weeklyForm.disable();
    this.onDemandForm.disable();
  }

  public ngOnInit() {
    if (this.route.parent?.parent) {
      this.subscriptions.add(
        this.route.parent.parent.params.pipe(take(1)).subscribe((params) => {
          this.structureId = params["id"];
        }),
      );
    }
    this.subscriptions.add(
      this.amc$.subscribe((amc) => this.updateAmcForm(amc)),
    );
    this.subscriptions.add(
      this.route.data
        .pipe(map((data) => data.amc as Amc))
        .subscribe((amc) => this.amc$.next(amc)),
    );

    this.subscriptions.add(
      interval(EVERY_10_SECONDS)
        .pipe(
          tap(() => this.now$.next(new Date())),
          mergeMap(() => this.businessService.getLastAmc(this.structureId)),
        )
        .subscribe((lastAmc) => {
          if (!isEqual(lastAmc, this.amc$.value) && !this.waiting$.value) {
            this.snackBar.openReloadSnackbar("page.config.snackbarReload");
          }
        }),
    );
    this.subscriptions.add(
      this.businessService.structureStream().subscribe((structure) => {
        if (structure) {
          const activeMeasuringPoints = structure.measuringPoints.filter(
            (m) => m.active,
          );
          this.initOnDemandForm(activeMeasuringPoints, this.amc$.value);
        }
      }),
    );
  }

  private updateAmcForm(amc: Amc) {
    this.amcInitialValue = amc;
    if (amc?.modes.find(isWeeklyAmcMode)) {
      this.weeklyToggleform.setValue(true);
      this.weeklyForm.enable();
      const weeklyMode = amc.modes.find(isWeeklyAmcMode)!;
      this.weeklyForm.controls.dayOfWeekToOversight.patchValue(
        weeklyMode.phase.dayOfWeek,
      );
      this.weeklyForm.controls.hourOfDayToOversight.patchValue(
        weeklyMode.phase.hourOfDay,
      );
    } else {
      this.weeklyToggleform.setValue(false);
    }
    this.disableWeeklyForm();
  }

  public getWeeklyMode(amc: Amc): AmcWeeklyMode | undefined {
    return amc.modes.find(isWeeklyAmcMode);
  }

  public getOnDemandMode(amc: Amc): AmcOnDemandMode | undefined {
    return amc.modes.find(isOnDemandAmcMode);
  }

  public initOnDemandForm(
    activeMeasuringPoints: StructureMeasuringPoint[],
    amc: Amc,
  ) {
    const onDemandMode = this.getOnDemandMode(amc);

    const measurePointNumbers =
      onDemandMode?.measuringPoints ??
      activeMeasuringPoints.map((m) => m.index);

    const measuringPoints = activeMeasuringPoints.filter((m) =>
      measurePointNumbers.includes(m.index),
    );

    this.onDemandForm.controls.nodesFilter.patchValue(measuringPoints);
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public submitWeekly(amc: Amc, enableWeekly = true) {
    this.waiting$.next(true);
    this.disableWeeklyForm();

    const startDateSeconds = Math.floor(Date.now() / 1000);

    const newWeekly: AmcWeeklyMode = {
      name: "weekly",

      priority: 1,
      startDateSeconds,
      periodMinutes: 10080,
      durationSeconds: 1200,
      samplingRateMilliseconds: 4,
      measuringPoints: [],
      rawCustomerDownloadAuthorization: false,
      phase: {
        dayOfWeek: this.weeklyForm.controls.dayOfWeekToOversight.value,
        hourOfDay: this.weeklyForm.controls.hourOfDayToOversight.value,
      },
    };
    let modes = amc.modes.filter((mode) => !isWeeklyAmcMode(mode));
    if (enableWeekly) {
      modes = modes.concat(newWeekly);
    }
    const newAmc: Amc = { ...amc, modes };
    this.businessService
      .postAmc(this.structureId, newAmc)
      .pipe(finalize(() => this.waiting$.next(false)))
      .subscribe((savedAmc) => {
        this.amc$.next(savedAmc);
      });

    this.dialog.open(DialogWeeklyInfo, {
      autoFocus: false,
    });
  }

  public submitOnDemand($event: MouseEvent, amc: Amc) {
    $event.stopPropagation();
    const measuringPoints: StructureMeasuringPoint[] =
      this.onDemandForm.controls.nodesFilter.value;
    const startDelay = this.structuresWithSwitchDuration.includes(
      this.structureId,
    )
      ? this.switchDuration
      : 0;
    const onDemandMode: AmcOnDemandMode = {
      name: "on_demand",
      priority: 2,
      startDateSeconds: Math.round((new Date().getTime() + startDelay) / 1000),
      periodMinutes: 60,
      durationSeconds: 1200,
      samplingRateMilliseconds: 4,
      occurences: 1,
      measuringPoints: measuringPoints
        .map((m) => m.index)
        .sort((a, b) => a - b),
      rawCustomerDownloadAuthorization: !this.authService
        .getScopes()
        .includes("structure:measure:raw:unlimited:read"),
    };
    const newAmc: Amc = {
      ...amc,
      modes: amc.modes
        .filter((mode) => !isOnDemandAmcMode(mode))
        .concat(onDemandMode),
      lastOnDemandSwitchDate: new Date(),
    };

    this.sendOnDemandAmc(newAmc);
    this.onClickCancelOnDemand($event);
  }

  private sendOnDemandAmc(newAmc: Amc) {
    this.waiting$.next(true);

    this.businessService
      .postAmc(this.structureId, newAmc)
      .pipe(finalize(() => this.waiting$.next(false)))
      .subscribe((savedAmc) => {
        this.amc$.next(savedAmc);
        this.dialog.open(DialogOnDemandInfo, { autoFocus: false });
      });
  }

  public getWeekdayName(dayNumber: number) {
    return this.datePipe.transform(`2020-06-0${dayNumber}`, "EEEE");
  }

  public disableWeeklyForm(cancel = false) {
    if (cancel) this.amc$.next(this.amcInitialValue);
    this.weeklyForm.disable();
  }

  public onClickEditOnDemand($event: MouseEvent) {
    $event.stopPropagation();
    if (!this.onDemandExpanded) this.onDemandExpanded = true;
    this.onDemandForm.enable();
  }

  public onClickCancelOnDemand($event: MouseEvent) {
    $event.stopPropagation();
    if (this.onDemandExpanded) this.onDemandExpanded = false;
    this.onDemandForm.disable();
  }

  public toggleWeekly($event: MatSlideToggleChange, amc: Amc) {
    const { checked } = $event;
    this.submitWeekly({ ...amc }, checked);
  }
}

@Component({
  selector: "dialog-weekly-info",
  template: `
    <h2 mat-dialog-title>
      {{ "page.structure.config.weekly.title" | translate }}
    </h2>
    <div mat-dialog-content>
      {{ "page.structure.config.weekly.dialogContent" | translate }}
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        id="dialog-on-demand-info-ok-button"
        color="primary"
        mat-dialog-close
      >
        {{ "common.ok" | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogWeeklyInfo {}

@Component({
  selector: "dialog-on-demand-info",
  template: `
    <h2 mat-dialog-title>
      {{ "page.structure.config.onDemandMode" | translate }}
    </h2>
    <div mat-dialog-content>
      {{ "page.structure.config.dialogOnDemand" | translate }}
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        id="dialog-on-demand-info-ok-button"
        color="primary"
        mat-dialog-close
      >
        {{ "common.ok" | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogOnDemandInfo {}
