import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "src/app/services/auth/auth.guard";
import { GeneralInfoPage } from "./general-info/general-info-page";
import { MeasurePointsPage } from "./measure-points/measure-points-page";
import { SynthesisPage } from "./synthesis/synthesis-page";

const routes: Routes = [
  {
    path: "",
    canActivateChild: [NgxPermissionsGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: "structure-creation-general",
        component: GeneralInfoPage,
        data: {
          permissions: {
            only: "structure:create",
          },
        },
      },
      {
        path: "structure-creation-points",
        component: MeasurePointsPage,
        data: {
          permissions: {
            only: "structure:create",
          },
        },
      },
      {
        path: "structure-creation-synthesis/:id",
        component: SynthesisPage,
        data: {
          permissions: {
            only: "structure:create",
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class StructureCreationPageRoutingModule {}
