import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StructureMeasuringPoint } from "src/app/models/structure.model";
import { BusinessService } from "src/app/services/network/business.service";

@Component({
  selector: "measure-points-table-select",
  templateUrl: "./measure-points-table-select.component.html",
  styleUrls: ["./measure-points-table-select.component.scss"],
})
export class MeasurePointTableSelectComponent {
  @Input() public readMode = false;
  @Input() public form!: FormControl<StructureMeasuringPoint[]>;

  public measurePoints$: Observable<StructureMeasuringPoint[]>;

  public constructor(businessService: BusinessService) {
    this.measurePoints$ = businessService
      .structureStream()
      .pipe(map((structure) => structure.measuringPoints));
  }

  public isAllSelected(measurePoints: StructureMeasuringPoint[]) {
    return (
      this.form.value.length === measurePoints.filter((m) => m.active).length
    );
  }

  public isIndeterminate(measurePoints: StructureMeasuringPoint[]) {
    return this.form.value.length > 0 && !this.isAllSelected(measurePoints);
  }

  public masterToggle(measurePoints: StructureMeasuringPoint[]) {
    this.isAllSelected(measurePoints)
      ? this.form.setValue([])
      : this.form.setValue(measurePoints.filter((m) => m.active));
  }
}
