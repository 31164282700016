import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "certificate-display",
  templateUrl: "./certificate-display.component.html",
  styleUrls: ["./certificate-display.component.scss"],
})
export class CertificateDisplayComponent {
  @Output() public download = new EventEmitter();

  public constructor() {}

  public downloadDeploymentArchive() {
    this.download.emit({
      type: "deployment",
    });
  }

  public downloadConfigurationArchive() {
    this.download.emit({
      type: "configuration",
    });
  }
}
