import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { SnackbarService } from "../utils/snackbar.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard {
  public constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private authService: AuthService,
    private snackBar: SnackbarService,
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated || this.keycloakAngular.isTokenExpired()) {
      // no valid token, we redirect to the login page
      await this.authService.login();
    }
    if (!this.authService.hasAuthorizationToken()) {
      // a valid token from keycloak but no auth token, we retrieve the auth token
      await this.authService.loadAuthorizationToken().toPromise();
    }
    if (!this.authService.getScopes().includes("ui:show")) {
      this.snackBar.open("snackbar.notHaveUiPermission");
      if (route.url.toString() !== "landing") {
        await this.router.navigate(["landing"]);
      }
    }
    return true;
  }
}
