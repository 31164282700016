import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeviceType } from "../../models/structure.model";

@Component({
  selector: "update-serial-number-dialog",
  templateUrl: "update-serial-number-dialog.component.html",
  styleUrls: ["./update-serial-number-dialog.component.scss"],
})
export class UpdateSerialNumberDialogComponent {
  public serialNumber: string;
  public measurePointNumber: string;
  public deviceType: DeviceType;
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateSerialNumberDialogData,
  ) {
    this.serialNumber = data.serialNumber;
    this.measurePointNumber = data.measurePointNumber;
    this.deviceType = data.deviceType;

    this.form = new FormGroup({
      serialNumber: new FormControl(undefined, Validators.required),
      comment: new FormControl(undefined, Validators.required),
    });

    if (this.deviceType === "gateway") {
      this.form.addControl(
        "modemId",
        new FormControl(undefined, Validators.required),
      );
    }
  }
}

export interface UpdateSerialNumberDialogData {
  serialNumber: string;
  measurePointNumber: string;
  deviceType: DeviceType;
}
