<div class="outer-content">
  <div
    class="table-container"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    #scrollableDiv
  >
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <mat-table id="event-table" [dataSource]="eventsList$">
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>
          {{ "common.date" | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{
            "common.dateUTC"
              | translate: { date: row.date | localizedDate: "short" : "UTC" }
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>{{
          "common.type" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <event-category-icon [category]="row.category"></event-category-icon>
          <span class="truncate-text">{{
            "component.eventTypeSelect.type." + row.type | translate
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="structure">
        <mat-header-cell *matHeaderCellDef>{{
          "common.structure" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="name-id-container">
            <span class="name">{{ row.extra?.structure?.name ?? "-" }}</span>
            <span class="id" *ngxPermissionsOnly="'ui:structures:id:show'">
              {{
                row.extra?.structure?.id ? " / " + row.extra.structure.id : ""
              }}
            </span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef>{{
          "common.customer" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncate-text">{{ row.extra?.customerId ?? "-" }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="eventsColumns$ | async"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: eventsColumns$ | async"
        [id]="'event-row-' + row.id"
        (click)="onRowSelected(row)"
        [ngClass]="{ 'selected-row': eventSelected === row }"
      ></mat-row>
    </mat-table>
  </div>
  <side-panel-json
    *ngIf="eventSelected"
    [title]="eventSelected.id"
    [jsonBody]="eventSelected"
    (closePanelEvent)="closePanel()"
    class="side-panel"
  ></side-panel-json>
</div>
