import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as md5 from "md5";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OperationService {
  private readonly serviceUrl: string = "api/v1/operation";

  public constructor(private http: HttpClient) {}

  public authorizeMeasureRaw(regionId: string, measureId: string) {
    const verificationCode = md5(measureId);
    return this.http.patch(
      `${environment.apiUrl}/${this.serviceUrl}/region/${regionId}/measure/${measureId}?verificationCode=${verificationCode}`,
      { sensors: { raw: { customerDownloadAuthorization: true } } },
    );
  }
}
