<div
  class="measure-point-header"
  *ngIf="measurePoints$ | async as measurePoints"
>
  <mat-checkbox
    id="measure-points-table-select-measure-point-all-checkbox"
    [checked]="isAllSelected(measurePoints)"
    [indeterminate]="isIndeterminate(measurePoints)"
    (change)="masterToggle(measurePoints)"
    [disabled]="readMode || form.disabled ? 'disabled' : null"
  >
  </mat-checkbox>
  <div class="select-measure-point-title">
    {{
      "component.measurePointsTableSelect.selectAllMeasurePoints" | translate
    }}
  </div>
</div>

<div class="list-container">
  <mat-selection-list class="measure-point-list" [formControl]="form">
    <mat-list-option
      class="measure-point"
      *ngFor="let measurePoint of measurePoints$ | async"
      checkboxPosition="before"
      [value]="measurePoint"
      [disabled]="
        form.disabled || readMode || !measurePoint.active ? 'disabled' : null
      "
    >
      {{
        measurePoint.active
          ? ("common.measurePoint"
            | translate: { measurePointNumber: measurePoint.index })
          : ("component.measurePointsTableSelect.measurePointDeactivated"
            | translate: { measurePointNumber: measurePoint.index })
      }}
    </mat-list-option>
  </mat-selection-list>
</div>
