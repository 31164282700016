<div class="config-page">
  <div *ngIf="isLoading$ | async; else elseReady" class="spinner-container">
    <mat-icon class="spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </mat-icon>
  </div>
  <ng-template #elseReady>
    <form class="config-container" [formGroup]="form">
      <h2>{{ 'page.config.invoiceSection' | translate }}</h2>

      <card class="invoice-day">
        <div class="invoice-day-form-field">
          <div>{{ 'page.config.invoiceDayAutoTrigger' | translate }}</div>
          <mat-form-field>
            <mat-label>{{ 'page.config.invoiceDay' | translate }}</mat-label>
            <input
              id="config-page-invoice-day"
              matInput
              formControlName="invoiceDay"
              type="number"
              [readonly]="!changeInvoiceDayEnabled"
            />
          </mat-form-field>
        </div>
        <button
          id="config-page-invoice-day-save"
          mat-raised-button
          color="accent"
          class="rounded-border-button"
          (click)="saveConfig()"
          [disabled]="form.invalid || (!form.invalid && !form.dirty) || !changeInvoiceDayEnabled"
        >
          <span>{{ 'common.save' | translate }}</span>
          <mat-icon>save</mat-icon>
        </button>
      </card>

      <card class="address-email">
        <div class="address-email-add">
          <div>
            {{ 'page.config.invoiceEmailNotfificationRecipients' | translate }}
          </div>

          <button
            id="config-page-address-email-add-button"
            mat-raised-button
            color="accent"
            class="rounded-border-button"
            (click)="displayAddEmailInput()"
          >
            <span>{{ 'page.config.addEmail' | translate }}</span>
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <div class="dark-divider"></div>

        <mat-list class="mailing-list">
          <mat-list-item
            *ngIf="displayAddEmail"
            class="invoice-recipient add-email"
            @itemAnimation
          >
            <div class="add-email-field-container">
              <mat-form-field class="add-email-field blue-dark-background">
                <mat-label>{{ 'common.addressEmail' | translate }}</mat-label>
                <input
                  id="config-page-adress-email-input"
                  type="email"
                  [formControl]="emailForm"
                  matInput
                  #addressEmailInput
                />
              </mat-form-field>
              <div class="align-center">
                <button
                  id="config-page-adress-email-validate"
                  mat-flat-button
                  color="accent"
                  [disabled]="emailForm.invalid"
                  (click)="addEmail()"
                >
                  {{ "common.validate" | translate}}
                </button>
                <button
                  id="config-page-adress-email-cancel"
                  mat-icon-button
                  color="primary"
                  (click)="cancelAddEmail()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
          <mat-list-item
            class="invoice-recipient"
            *ngFor="let recipient of form.controls['invoiceRecipients'].value; let index=index"
            @itemAnimation
          >
            <div class="invoice-recipient-content">
              <p id="config-page-address-email-label-{{index}}">
                {{recipient.email}}
              </p>
              <div>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="sendEmail(recipient.email)"
                  id="config-page-address-email-test-{{index}}"
                >
                  <mat-icon>email</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="removeEmail(recipient.email)"
                  id="config-page-address-email-remove-{{index}}"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </card>
    </form>
  </ng-template>
</div>
