import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { ProcessingRequest } from "../../models/processing-request.model";
import {
  RequestedDateRow,
  StructureMeasuresPageStore,
} from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { ProcessingService } from "../../services/network/processing.service";

@Component({
  selector: "structure-measure-force-processing",
  templateUrl: "./structure-measure-force-processing.component.html",
  styleUrls: ["./structure-measure-force-processing.component.scss"],
})
export class StructureMeasureForceProcessingComponent implements OnChanges {
  @Input() public row!: RequestedDateRow;
  @Output() public forceProcessing = new EventEmitter<ProcessingRequest>();

  public canForceProcessing!: boolean;
  public loading = false;

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private processingService: ProcessingService,
  ) {}

  public ngOnChanges(): void {
    this.canForceProcessing = this.getCanForceProcessing();
  }

  public onForceProcessing() {
    this.loading = true;
    this.processingService
      .createProcessingRequest(
        this.pageStore.structure.generalInfo.id,
        this.row.requestedDate,
      )
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((processingRequest) => {
        this.forceProcessing.emit(processingRequest);
      });
  }

  private getCanForceProcessing() {
    if (this.row.allMeasures.every((m) => m.measurePointNumber !== 0)) {
      return false;
    }
    if (this.row.lastProcessingRequest === false) {
      return false;
    }
    const lastProcessedPoints = this.row.lastProcessingRequest?.measurePoints
      .filter((m) => m.measure?.raw)
      .map((m) => m.measurePointNumber);
    return this.row.allMeasures
      .filter((m) => !!m.sensors.raw)
      .map((m) => m.measurePointNumber)
      .some((measurePoint) => !lastProcessedPoints?.includes(measurePoint));
  }
}
