<ng-container #fileContent [ngSwitch]="displayedFile.extension">
  <app-pdf-viewer
    *ngSwitchCase="'pdf'"
    [displayedFile]="displayedFile"
  ></app-pdf-viewer>
  <app-markdown-viewer
    *ngSwitchCase="'md'"
    [displayedFile]="displayedFile"
  ></app-markdown-viewer>
  <app-markdown-viewer
    *ngSwitchCase="'release-notes'"
    [displayedFile]="displayedFile"
  ></app-markdown-viewer>
  <app-swagger-viewer
    *ngSwitchCase="'yaml'"
    [displayedFile]="displayedFile"
  ></app-swagger-viewer>
  <app-default-viewer
    *ngSwitchDefault
    [displayedFile]="displayedFile"
  ></app-default-viewer>
</ng-container>
