import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, merge, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { File } from "./models/file.model";
import { AuthService } from "./services/auth/auth.service";
import { BusinessService } from "./services/network/business.service";
import { NotificationUnreadService } from "./services/network/notification-unread.service";
import { CustomIconService } from "./services/utils/custom-icon.service";
import { StorageService } from "./services/utils/storage.service";

@Component({
  selector: "app-root",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public enableHeaderPage = false;
  public locale: string;

  public loadingFile = new BehaviorSubject<boolean>(false);
  public legalFiles$!: Observable<File[]>;
  public documentationFiles$!: Observable<File[]>;
  public releaseNotesFiles$!: Observable<File[]>;
  public eventsUnread$: Observable<string>;

  public hasSomeNotificationPermissions$: Observable<boolean>;

  public constructor(
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
    private translate: TranslateService,
    private businessService: BusinessService,
    private notificationUnreadService: NotificationUnreadService,
    private customIconService: CustomIconService,
  ) {
    this.locale = this.storage.getLocale() || "fr-FR";
    this.translate.addLangs(["fr-FR", "en-GB"]);
    this.translate.setDefaultLang("fr-FR");
    this.translate.use(this.locale);
    this.setLocale(this.locale);
    this.eventsUnread$ = merge(
      of(0), // immediately display something, dont wait for the http request
      this.notificationUnreadService.getUnread$(),
    ).pipe(map((unread) => (unread > 0 ? String(unread) : "")));
    this.customIconService.init();

    this.hasSomeNotificationPermissions$ = this.authService
      .getPermissionScopes$()
      .pipe(
        map((permissions) =>
          permissions.some((permission) =>
            permission.startsWith("notification"),
          ),
        ),
      );
  }

  public ngOnInit() {
    this.businessService
      .getFilesStream()
      .subscribe(() => this.loadingFile.next(false));

    this.legalFiles$ = this.businessService.getLegalFilesStream();
    this.documentationFiles$ =
      this.businessService.getDocumentationFilesStream();
    this.releaseNotesFiles$ = this.businessService.getReleaseNotesFilesStream();
  }

  public setLocale(locale: string) {
    if (locale !== this.storage.getLocale()) {
      this.storage.saveLocale(locale);
      this.translate.use(locale);
    }
  }

  public getUsername() {
    return this.authService.getUsername();
  }

  public redirectToLanding() {
    return this.router.navigate(["landing"]);
  }

  public logout() {
    return this.authService.logout();
  }

  public loadFiles() {
    if (!(this.businessService.getFilesValue().length > 0)) {
      this.loadingFile.next(true);
      this.businessService.getFiles();
    }
  }

  public routerLinkActive(page: string) {
    return this.router.isActive(page, {
      paths: "exact",
      queryParams: "ignored",
      fragment: "ignored",
      matrixParams: "ignored",
    });
  }

  public getPathOfCurrentReleaseNotes$() {
    return this.releaseNotesFiles$.pipe(
      map((releaseNotes) => {
        const releaseNote =
          releaseNotes.find(
            (file) =>
              file.name.toLowerCase() === `v${environment.version}` ||
              `v${environment.version}`.startsWith(file.name.toLowerCase()),
          ) ?? releaseNotes[0];
        return releaseNote.path;
      }),
    );
  }

  public getUnreadEventsNumber(eventsUnread: number) {
    return eventsUnread > 99 ? "99+" : eventsUnread;
  }
}
