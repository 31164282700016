<mat-form-field>
  <mat-label>
    {{ "component.eventFilter.filterByCustomer" | translate }}</mat-label
  >
  <mat-select id="customer-select" [formControl]="form">
    <mat-option
      *ngFor="let customer of customers$ | async"
      [id]="'customer-select-option-' + customer.id"
      [value]="customer.id"
      >{{ customer.id }}</mat-option
    >
  </mat-select>
</mat-form-field>
