import { DataSource } from "@angular/cdk/collections";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { Temporal } from "@js-temporal/polyfill";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, merge, Observable, Subject } from "rxjs";
import { finalize, map, mergeMap } from "rxjs/operators";
import { Invoice } from "../../../models/invoice.model";
import { BillingService } from "../../../services/network/billing.service";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";
import { pollUntil$ } from "../../../services/utils/utils";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: "invoice-table",
  templateUrl: "./invoice-table.component.html",
  styleUrls: ["./invoice-table.component.scss"],
  providers: [LocalizedDatePipe, MatButton, MatProgressSpinner],
})
export class InvoiceTableComponent implements AfterViewInit {
  public displayedColumns: string[];

  public isLoading$: Observable<boolean>;
  public dataSource: InvoiceTableDataSource;

  private init$ = new Subject<void>();
  private clickRegenerate$ = new Subject<string>();
  private loadingTasks$ = new BehaviorSubject<number>(0);

  private emailList?: string[];

  public constructor(
    private billingService: BillingService,
    private permissionsService: NgxPermissionsService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {
    const months = this.getMonthsToDisplay();

    const invoiceCreated$ = this.clickRegenerate$.pipe(
      mergeMap((month) =>
        this.asLoadingTask(this.billingService.createInvoice(month)),
      ),
      mergeMap((invoice) =>
        this.asLoadingTask(
          pollUntil$(() => this.billingService.doesInvoiceExist(invoice.id)),
        ).pipe(map(() => invoice)),
      ),
    );
    const invoicesFetched$ = merge(this.init$, invoiceCreated$).pipe(
      mergeMap(() =>
        this.asLoadingTask(
          this.billingService.getInvoices(months[months.length - 1]),
        ),
      ),
      map((invoices) => this.getRowsToDisplay(months, invoices)),
    );
    this.isLoading$ = this.loadingTasks$.pipe(map((n) => n > 0));
    this.dataSource = new InvoiceTableDataSource(invoicesFetched$);

    this.displayedColumns = this.getDisplayedColumns();

    this.billingService.getConfigPlatform();
    this.billingService.configStream().subscribe((config) => {
      if (config !== undefined) {
        this.emailList = config.invoiceRecipients.map((email) => email.email);
      }
    });
  }

  private getDisplayedColumns() {
    const canGenerateInvoice =
      !!this.permissionsService.getPermission("invoice:manage");
    const sendInvoiceEmailEnabled = environment.sendInvoiceEmailEnabled;
    const displayedColumns: string[] = [
      "month",
      "creationDate",
      "partialOrComplete",
      "download",
    ];
    if (canGenerateInvoice) displayedColumns.push("regenerate");
    if (sendInvoiceEmailEnabled) displayedColumns.push("send");
    return displayedColumns;
  }

  public ngAfterViewInit(): void {
    this.init$.next();
    // For some reason, without this line Angular doesnt always update the view when isLoading$ emits a new value
    this.changeDetectorRef.detectChanges();
  }

  public downloadInvoice(invoice: Invoice) {
    this.asLoadingTask(
      this.billingService.downloadInvoiceFile(invoice),
    ).subscribe();
  }

  public createInvoice(month: string) {
    this.clickRegenerate$.next(month);
  }

  public emailInvoice(invoice: Invoice) {
    this.asLoadingTask(this.billingService.emailInvoice(invoice.id)).subscribe(
      () => {
        this.dialog.open(DialogInvoiceTableEmail, {
          data: {
            month: invoice.month,
            emailList: this.emailList,
          },
        });
      },
    );
  }

  public isInvoiceComplete(date: string, month: string) {
    const dateStartingMonth = new Date(month + "-01");
    const dateEndingMonth = new Date(
      dateStartingMonth.setMonth(dateStartingMonth.getMonth() + 1),
    );
    return new Date(date).getTime() > dateEndingMonth.getTime();
  }

  private asLoadingTask<T>(observable$: Observable<T>) {
    this.loadingTasks$.next(this.loadingTasks$.value + 1);
    return observable$.pipe(
      finalize(() => {
        this.loadingTasks$.next(this.loadingTasks$.value - 1);
      }),
    );
  }

  /**
   * @returns the last 30 months, for example ["2021-07", "2021-06", ...]
   */
  private getMonthsToDisplay() {
    const months: string[] = [];
    const date = Temporal.PlainYearMonth.from(Temporal.Now.plainDateISO());
    for (let i = 0; i < 30; i++) {
      const month = date.subtract({ months: i });
      months.push(month.toString());
    }
    return months;
  }

  /**
   * @returns for each month, either the list of existing invoices, or an empty row if no invoice was found
   */
  private getRowsToDisplay(monthsToDisplay: string[], invoices: Invoice[]) {
    const rows: InvoiceRow[] = [];
    for (const month of monthsToDisplay) {
      const foundInvoices = invoices.filter(
        (invoice) => invoice.month === month,
      );
      if (foundInvoices.length > 0) {
        rows.push(
          ...foundInvoices.map((invoice) => ({
            month: invoice.month,
            invoice,
          })),
        );
      } else {
        rows.push({ month });
      }
    }
    return rows;
  }
}

class InvoiceTableDataSource extends DataSource<InvoiceRow> {
  public constructor(private rows$: Observable<InvoiceRow[]>) {
    super();
  }

  public connect(): Observable<InvoiceRow[]> {
    return this.rows$;
  }

  public disconnect(): void {}
}

interface InvoiceRow {
  month: string;
  invoice?: Invoice;
}

@Component({
  selector: "dialog-invoice-table-email",
  template: `
    <h2 mat-dialog-title>
      {{ "page.invoices.invoiceTable.dialog.title" | translate }}
    </h2>
    <div mat-dialog-content>
      <p>
        {{
          "page.invoices.invoiceTable.dialog.content"
            | translate: { month: month | localizedDate: "MMMM y" }
        }}
        {{ emailList }}
      </p>
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        id="dialog-invoice-table-email-button"
        color="primary"
        mat-dialog-close
      >
        {{ "common.ok" | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogInvoiceTableEmail {
  public month: string;
  public emailList: string;
  constructor(
    public dialogRef: MatDialogRef<DialogInvoiceTableEmail>,
    @Inject(MAT_DIALOG_DATA)
    public data: { month: string; emailList: string[] },
  ) {
    this.month = data.month;

    this.emailList = data.emailList.join(", ");
  }
}
