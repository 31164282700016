export interface RemarkableEvent {
  id: string;
  type: RemarkableEventType;
  date: Date;
  extra: Record<string, unknown>;
}

export interface Category {
  name: string;
  types: string[];
}

export const logEventTypes: Readonly<string[]> = [
  "AmcActivated",
  "DeploymentReportCreated",
  "DeploymentReportRejected",
  "DeploymentReportValidated",
  "InvoiceCreated",
  "MeasurePointActivated",
  "MeasurePointDeactivated",
  "RetrievePosted",
  "StructureApproved",
  "StructureDeleted",
  "StructureDemobilized",
  "StructureMonitored",
  "StructureRegistered",
  "StructureRejected",
  "DeviceUpdated",
];

// same list as "subscribableEventTypes" in "Events.types.ts" file
export const notificationEventTypes: Readonly<string[]> = [
  "AmcActivated",
  "DeploymentReportCreated",
  "DeploymentReportRejected",
  "DeploymentReportValidated",
  "InvoiceCreated",
  "MeasurePointActivated",
  "MeasurePointDeactivated",
  "OpenSensorsCommitted",
  "RetrievePosted",
  "StructureApproved",
  "StructureDeleted",
  "StructureDemobilized",
  "StructureMonitored",
  "StructureRegistered",
  "StructureRejected",
  "DeviceUpdated",
];

export const remarkableEventTypes: Readonly<string[]> = [
  "StructureRegistered",
  "StructureMonitored",
  "StructureDemobilized",
  "StructureDeleted",
  "DeploymentReportCreated",
  "DeploymentReportValidated",
  "DeploymentReportRejected",
  "StructureApproved",
  "StructureRejected",
  "RetrievePosted",
  "AmcActivated",
  "InvoiceCreated",
  "MeasurePointActivated",
  "MeasurePointDeactivated",
  "DeviceUpdated",
] as const;

export type RemarkableEventType = (typeof remarkableEventTypes)[number];

export const structureEventCategory: RemarkableEventType[] = [
  "StructureRegistered",
  "StructureMonitored",
  "StructureDemobilized",
  "StructureDeleted",
  "DeploymentReportCreated",
  "DeploymentReportValidated",
  "DeploymentReportRejected",
  "StructureApproved",
  "StructureRejected",
];

export const measureEventCategory: RemarkableEventType[] = [
  "RetrievePosted",
  "MeasurePointActivated",
  "MeasurePointDeactivated",
];

export const openSensorEventCategory: RemarkableEventType[] = [
  "OpenSensorsCommitted",
  "DeviceUpdated",
];

export const sohAvailabilityEventCategory: RemarkableEventType[] = [
  "SOHStored",
];

export const amcEventCategory: RemarkableEventType[] = ["AmcActivated"];

export const billingEventCategory: RemarkableEventType[] = ["InvoiceCreated"];

export const eventTypesByCategory: Category[] = [
  { name: "structures", types: structureEventCategory },
  { name: "measures", types: measureEventCategory },
  { name: "sensors", types: openSensorEventCategory },
  { name: "amcs", types: amcEventCategory },
  { name: "billing", types: billingEventCategory },
  { name: "soh_availability", types: sohAvailabilityEventCategory },
];
