import { OverlayModule } from "@angular/cdk/overlay";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MarkdownModule } from "ngx-markdown";
import { DirectivesModule } from "../directives/directives.module";
import { OverlayService } from "../services/utils/overlay.service";
import { SharedModule } from "../shared/shared.module";
import { BatteryComponent } from "./battery/battery.component";
import { CertificateDisplayComponent } from "./certificate-display/certificate-display.component";
import { CustomerSelectComponent } from "./customer-select/customer-select.component";
import { DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";
import { DefaultViewerComponent } from "./default-viewer/default-viewer.component";
import { DeploymentReportConfirmationComponent } from "./deployment-report-confirmation/deployment-report-confirmation.component";
import { DialogOnRetrieveDemand } from "./dialog-on-retrieve-demand/dialog-on-retrieve-demand.component";
import { EquipmentStatusComponent } from "./equipment-status/equipment-status.component";
import { EventCategoryCheckboxComponent } from "./event-category-checkbox/event-category-checkbox.component";
import { EventCategoryIconComponent } from "./event-category-icon/event-category-icon.component";
import { EventFilterComponent } from "./event-filter/event-filter.component";
import { EventTableComponent } from "./event-table/event-table.component";
import { EventTypeSelectComponent } from "./event-type-select/event-type-select.component";
import { FileViewerComponent } from "./file-viewer/file-viewer.component";
import { FilterStructureTypeComponent } from "./filter-structure-type/filter-structure-type.component";
import { FilterStructureWorkflowComponent } from "./filter-structure-workflow/filter-structure-workflow.component";
import { IconDownloadComponent } from "./icon-download/icon-download.component";
import { InputNumberComponent } from "./input-number/input-number.component";
import { LanguageSelectComponent } from "./language-select/language-select.component";
import { LoadingSpinnerMaskComponent } from "./loading-spinner-mask/loading-spinner-mask.component";
import { MapDialogComponent } from "./map-dialog/map-dialog.component";
import { MarkdownViewerComponent } from "./markdown-viewer/markdown-viewer.component";
import { MeasurePointActiveToggleActivateDialogComponent } from "./measure-point-active-toggle/measure-point-active-toggle-activate-dialog.component";
import { MeasurePointActiveToggleDeactivateDialogComponent } from "./measure-point-active-toggle/measure-point-active-toggle-deactivate-dialog.component";
import { MeasurePointActiveToggleComponent } from "./measure-point-active-toggle/measure-point-active-toggle.component";
import { MeasurePointTableFullComponent } from "./measure-points-table-full/measure-points-table-full.component";
import { MeasurePointTableSelectComponent } from "./measure-points-table-select/measure-points-table-select.component";
import { MeasurePointTableComponent } from "./measure-points-table/measure-points-table.component";
import { ModalSpinnerComponent } from "./modal-spinner/modal-spinner.component";
import { OldMeasureIconComponent } from "./old-measure-icon/old-measure-icon.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { ProcessingRequestedStatusComponent } from "./processing-requested-status/processing-requested-status.component";
import { RetrievePanelComponent } from "./retrieve-panel/retrieve-panel.component";
import { SearchInputComponent } from "./search-input/search-input.component";
import { SelectClearButtonComponent } from "./select-clear-button/select-clear-button.component";
import { SidePanelAmcComponent } from "./side-panel-amc/side-panel-amc.component";
import { SidePanelJsonComponent } from "./side-panel-json/side-panel-json.component";
import { SidePanelMeasuresAuthorizationsComponent } from "./side-panel-measures-authorizations/side-panel-measures-authorizations.component";
import { SidePanelOpenSensorsComponent } from "./side-panel-opensensors/side-panel-opensensors.component";
import { SnackbarReloadComponent } from "./snackbar-reload/snackbar-reload.component";
import { StructureBillingFormComponent } from "./structure-billing-form/structure-billing-form.component";
import { StructureFormComponent } from "./structure-form/structure-form.component";
import { StructureLocationFormComponent } from "./structure-location-form/structure-location-form.component";
import { StructureMeasureForceProcessingComponent } from "./structure-measure-force-processing/structure-measure-force-processing.component";
import { DialogMeasuresTableDownloadAllRawEmpty } from "./structure-measures-table/dialogs/dialog-measures-table-download-all-raw-empty.component";
import { DialogMeasuresTableDownloadAllRawWarning } from "./structure-measures-table/dialogs/dialog-measures-table-download-all-raw-warning.component";
import { StructureMeasuresChipListComponent } from "./structure-measures-table/structure-measures-chip-list/structure-measures-chip-list.component";
import { StructureMeasuresProcessedTableComponent } from "./structure-measures-table/structure-measures-processed-table/structure-measures-processed-table.component";
import { StructureMeasuresRawTableComponent } from "./structure-measures-table/structure-measures-raw-table/structure-measures-raw-table.component";
import { StructureMeasureTableRefreshSnackbarComponent } from "./structure-measures-table/structure-measures-table-refresh-snackbar.component";
import { StructureMeasuresRequestedDateCellComponent } from "./structure-measures-table/structure-measures-table-requested-date-cell/structure-measures-table-requested-date-cell.component";
import { StructureMeasuresRequestedDateHeaderCellComponent } from "./structure-measures-table/structure-measures-table-requested-date-header-cell/structure-measures-table-requested-date-header-cell.component";
import { StructureMeasuresTableComponent } from "./structure-measures-table/structure-measures-table.component";
import { StructureSelectAutoCompleteComponent } from "./structure-select-autocomplete/structure-select-autocomplete.component";
import { StructureTableComponent } from "./structure-table/structure-table.component";
import { StructureTypeIconComponent } from "./structure-type-icon/structure-type-icon.component";
import { StructureWorkflowStatusComponent } from "./structure-workflow-status/structure-workflow-status.component";
import { SwaggerViewerComponent } from "./swagger-viewer/swagger-viewer.component";
import { SwitchButtonComponent } from "./switch-button/switch-button.component";
import { TimeoutConfigComponent } from "./timeout-config/timeout-config.component";
import { UnlockedIconComponent } from "./unlocked-icon/unlocked-icon.component";
import { WorkflowInformationComponent } from "./workflow-information/workflow-information.component";
import { SidePanelComponent } from "./side-panel/side-panel.component";
import { CardComponent } from "./card/card.component";
import { UpdateSerialNumberDialogComponent } from "./structure-sensors-table/update-serial-number-dialog.component";

const COMPONENTS = [
  BatteryComponent,
  StructureMeasuresTableComponent,
  StructureMeasureTableRefreshSnackbarComponent,
  StructureMeasureForceProcessingComponent,
  StructureTypeIconComponent,
  StructureWorkflowStatusComponent,
  WorkflowInformationComponent,
  StructureFormComponent,
  CertificateDisplayComponent,
  MeasurePointTableComponent,
  StructureTableComponent,
  MeasurePointTableFullComponent,
  MeasurePointTableSelectComponent,
  PageHeaderComponent,
  EquipmentStatusComponent,
  SidePanelAmcComponent,
  SidePanelJsonComponent,
  SidePanelOpenSensorsComponent,
  LanguageSelectComponent,
  LoadingSpinnerMaskComponent,
  DeploymentReportConfirmationComponent,
  StructureBillingFormComponent,
  StructureLocationFormComponent,
  SidePanelMeasuresAuthorizationsComponent,
  UnlockedIconComponent,
  DialogMeasuresTableDownloadAllRawWarning,
  DialogMeasuresTableDownloadAllRawEmpty,
  ModalSpinnerComponent,
  EventTableComponent,
  EventCategoryIconComponent,
  EventFilterComponent,
  CustomerSelectComponent,
  StructureSelectAutoCompleteComponent,
  EventTypeSelectComponent,
  FilterStructureTypeComponent,
  FilterStructureWorkflowComponent,
  FileViewerComponent,
  DefaultViewerComponent,
  PdfViewerComponent,
  MarkdownViewerComponent,
  SwaggerViewerComponent,
  DateRangePickerComponent,
  SnackbarReloadComponent,
  SearchInputComponent,
  RetrievePanelComponent,
  MapDialogComponent,
  OldMeasureIconComponent,
  DialogOnRetrieveDemand,
  MeasurePointActiveToggleComponent,
  MeasurePointActiveToggleActivateDialogComponent,
  MeasurePointActiveToggleDeactivateDialogComponent,
  InputNumberComponent,
  ProcessingRequestedStatusComponent,
  IconDownloadComponent,
  SwitchButtonComponent,
  SwitchButtonComponent,
  StructureMeasuresChipListComponent,
  StructureMeasuresRawTableComponent,
  StructureMeasuresProcessedTableComponent,
  StructureMeasuresRequestedDateCellComponent,
  EventCategoryCheckboxComponent,
  SelectClearButtonComponent,
  StructureMeasuresRequestedDateHeaderCellComponent,
  TimeoutConfigComponent,
  SidePanelComponent,
  CardComponent,
  UpdateSerialNumberDialogComponent,
];

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    InfiniteScrollModule,
    HighlightModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    OverlayModule,
    MatRippleModule,
    MatSelectModule,
    CommonModule,
    PdfViewerModule,
    MarkdownModule.forRoot(),
    LeafletModule,
    MatSlideToggleModule,
    MatChipsModule,
    DirectivesModule,
  ],
  providers: [
    MatDatepickerModule,
    OverlayService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          json: () => import("highlight.js/lib/languages/json"),
        },
      },
    },
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class ComponentsModule {}
