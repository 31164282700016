import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, combineLatest, from, of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import { DeploymentService } from "src/app/services/network/deployment.service";
import {
  emptyHeader,
  StorageService,
} from "src/app/services/utils/storage.service";
import { BusinessService } from "../../../services/network/business.service";
import { pollUntil$ } from "../../../services/utils/utils";

@Component({
  selector: "measure-points-page",
  templateUrl: "./measure-points-page.html",
  styleUrls: ["./measure-points-page.scss"],
})
export class MeasurePointsPage implements OnInit {
  public formPoints: FormGroup<{ numberOfPoints: FormControl<number> }>;
  public createButtonHasBeenClicked = false;
  public isLoading$ = new BehaviorSubject(false);

  public constructor(
    private router: Router,
    private location: Location,
    private storage: StorageService,
    private deploymentService: DeploymentService,
    private businessService: BusinessService,
  ) {
    this.formPoints = new FormGroup({
      numberOfPoints: new FormBuilder().nonNullable.control(
        0,
        Validators.required,
      ),
    });
  }

  public ngOnInit() {
    this.storage.clearMeasurePointsArray();
    this.storage.setPageHeader(emptyHeader);
  }

  public onSubmit() {
    const { numberOfPoints } = this.formPoints.getRawValue();
    this.storage.buildMeasurePointsArray(numberOfPoints);
    this.createButtonHasBeenClicked = true;
  }

  public backToPreviousPage() {
    this.location.back();
  }

  public async createAndredirectToSynthesis() {
    this.isLoading$.next(true);
    return this.deploymentService
      .registerStructure(
        this.storage.getCurrentStructureGeneralInfo(),
        this.storage.getCurrentStructureMeasurePoints(),
      )
      .pipe(
        mergeMap(({ id }) =>
          combineLatest([
            of(id),
            pollUntil$(
              () =>
                this.businessService
                  .getStructure$(id, true)
                  .pipe(catchError(() => of(false))),
              10000,
            ),
          ]),
        ),

        mergeMap(([id]) => {
          const thumbnail =
            this.storage.getCurrentStructureGeneralInfo().location?.thumbnail;
          const deploymentFile =
            this.storage.getCurrentStructureGeneralInfo().deploymentFile;

          if (thumbnail) {
            return combineLatest([
              of(id),
              this.deploymentService.uploadDeploymentFile(id, deploymentFile!),
              this.deploymentService.uploadLocationThumbnail(id, thumbnail),
            ]);
          } else {
            return combineLatest([
              of(id),
              this.deploymentService.uploadDeploymentFile(id, deploymentFile!),
            ]);
          }
        }),
        mergeMap(([id]) =>
          from(this.router.navigate([`structure-creation-synthesis/${id}`])),
        ),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }

  public removeMeasurePoint($event: RemoveMeasurePoint) {
    this.storage.removePointFromList($event.index);
  }

  public addMeasurePoint() {
    this.storage.addMeasurePoint();
  }

  public saveCommentInModel($event: SaveComment) {
    this.storage.saveCommentInModel($event.index, $event.value);
  }
}

interface RemoveMeasurePoint {
  index: number;
}

interface SaveComment {
  index: number;
  value: string;
}
