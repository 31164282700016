import { MeasureItem } from "../../models/measure.model";

export function mapMeasureItemDates(measures: MeasureItem[]): MeasureItem[] {
  return measures.map((measure) => {
    return {
      ...measure,
      requestedDate: new Date(measure.requestedDate),
    };
  });
}
