import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { mergeMap, take, tap } from "rxjs/operators";
import {
  HashId,
  StructureCreationMeasurePoint,
} from "src/app/models/structure.model";
import { BusinessService } from "src/app/services/network/business.service";
import {
  emptyHeader,
  StorageService,
} from "src/app/services/utils/storage.service";
import { createStructureFormGroup } from "../../../services/utils/createStructureFormGroup";
import { DownloadFileService } from "src/app/services/utils/download-file.service";
@Component({
  selector: "synthesis-page",
  templateUrl: "./synthesis-page.html",
  styleUrls: ["./synthesis-page.scss"],
})
export class SynthesisPage implements OnInit, OnDestroy {
  @ViewChild("fileContent", { static: false })
  private fileContent!: ElementRef<HTMLElement>;

  public formGeneralInfo = createStructureFormGroup();
  public measurePoints$ = new BehaviorSubject<StructureCreationMeasurePoint[]>(
    [],
  );
  private structureId!: string;
  public subscriptions: Subscription = new Subscription();

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService,
    private businessService: BusinessService,
    private downloadFileService: DownloadFileService,
  ) {
    this.formGeneralInfo.disable();
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.route.params
        .pipe(
          take(1),
          tap((params) => (this.structureId = params["id"])),
          mergeMap(() => this.businessService.getStructure$(this.structureId)),
        )
        .subscribe((structure) => {
          this.formGeneralInfo.patchValue({
            ...structure.generalInfo,
            location: undefined,
          });
          this.formGeneralInfo.patchValue({
            name: this.formGeneralInfo.controls.name
              .value!.replace(/\s+/g, " ")
              .trim(),
            regionId: structure.generalInfo.region.id,
          });

          this.measurePoints$.next(
            structure.measuringPoints.map((measurePoint) => ({
              comment: measurePoint.comment,
              index: measurePoint.index,
            })),
          );
        }),
    );

    this.storage.setPageHeader(emptyHeader);
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public redirectToLanding() {
    return this.router.navigate(["landing"]);
  }

  public downloadStructureAttachedFile() {
    this.businessService.getStructureDeploymentFile(this.structureId);
  }

  public downloadArchive($event: DownloadArchiveType) {
    if ($event.type === "deployment") {
      this.downloadDeploymentArchive(this.structureId);
    } else {
      this.downloadConfigurationArchive(this.structureId);
    }
  }

  private downloadDeploymentArchive(structureId: HashId) {
    this.businessService.getDeploymentArchive(structureId);
  }

  private downloadConfigurationArchive(structureId: HashId) {
    this.businessService.getConfigurationArchive(structureId);
  }

  public async downloadSynthesis() {
    const fileContent = this.fileContent.nativeElement;
    await this.downloadFileService.downloadHTMLToPdf(
      fileContent,
      `structure-synthesis-${this.structureId}`,
    );
  }
}

interface DownloadArchiveType {
  type: string;
}
