import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigPlatform } from "../../models/config-platform.model";
import { BillingService } from "../../services/network/billing.service";
import { StorageService } from "../../services/utils/storage.service";

@Component({
  selector: "invoices-page",
  templateUrl: "./invoices-page.html",
  styleUrls: ["./invoices-page.scss"],
})
export class InvoicesPageComponent implements OnInit {
  public config = new Observable<ConfigPlatform>();
  public constructor(
    private storage: StorageService,
    private billingService: BillingService,
  ) {}

  public ngOnInit() {
    this.storage.setPageHeader({
      showHomeButton: true,
      showBackButton: false,
      showNavLinks: false,
      pageTitle: "page.invoices.invoices",
    });

    this.config = this.billingService.configStream();

    this.billingService.getConfigPlatform();
  }
}
