import { DataSource } from "@angular/cdk/table";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { NgxPermissionsService } from "ngx-permissions";
import {
  BehaviorSubject,
  from,
  interval,
  Observable,
  ReplaySubject,
  Subscription,
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
} from "rxjs/operators";
import {
  HashId,
  StructureInfo,
  StructureStatus,
} from "src/app/models/structure.model";
import { Pagination } from "../../models/pagination.model";
import { AuthService } from "../../services/auth/auth.service";
import { BusinessService } from "../../services/network/business.service";
import { EVERY_10_SECONDS } from "../../services/utils/globals";
import { SnackbarService } from "../../services/utils/snackbar.service";
import { MapDialogComponent } from "../map-dialog/map-dialog.component";
import { WorkflowInformationComponent } from "../workflow-information/workflow-information.component";

@Component({
  selector: "structure-table",
  templateUrl: "./structure-table.component.html",
  styleUrls: ["./structure-table.component.scss"],
})
export class StructureTableComponent implements OnInit {
  @Output() public downloadArchive = new EventEmitter();
  @Output() public workflowRequest = new EventEmitter();
  @Input() public structurePagination$: ReplaySubject<
    Pagination<StructureInfo>
  > = new ReplaySubject<Pagination<StructureInfo>>(1);

  @Input() public form!: FormGroup<{
    offset: FormControl<number>;
    limit: FormControl<number>;
    nameSearch: FormControl<string>;
    types: FormControl<string[]>;
    status: FormControl<string[]>;
    activationDate: FormGroup<{
      start: FormControl<string>;
      end: FormControl<string>;
    }>;
    creationDate: FormGroup<{
      start: FormControl<string>;
      end: FormControl<string>;
    }>;
  }>;

  public structureListColumns$ = new Observable<string[]>();
  public dataSource!: StructureListDataSource;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public maxSohAlarmStatus = "none";

  private subscriptions: Subscription = new Subscription();

  public constructor(
    private permissionsService: NgxPermissionsService,
    private authService: AuthService,
    private businessService: BusinessService,
    private snackBar: SnackbarService,
    public dialog: MatDialog,
  ) {
    this.structureListColumns$ = this.permissionsService.permissions$.pipe(
      map((permissions) => {
        const columns = ["name", "type", "location"];
        if (permissions["structure:foreign:read"]) columns.push("customer");
        if (permissions["ui:structures:contact:show"]) columns.push("contact");
        columns.push("workflow");
        if (permissions["ui:structures:creation_date:show"])
          columns.push("creationDate");
        if (permissions["ui:structures:commissioning_date:show"])
          columns.push("activationDate");
        columns.push("action");

        return columns;
      }),
    );

    this.businessService
      .structureInfoListStream()
      .subscribe(this.structurePagination$);

    this.dataSource = new StructureListDataSource(
      this.structurePagination$,
      this.businessService,
    );
  }

  public ngOnInit() {
    this.loadStructuresPage();

    this.subscriptions.add(
      this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.loadStructuresPage();
      }),
    );

    this.subscriptions.add(
      interval(EVERY_10_SECONDS)
        .pipe(
          filter(() => !this.loading$.value),
          mergeMap(() =>
            this.businessService.isStructureInfoListOutdated(this.form.value),
          ),
          filter((isOutdated) => isOutdated && !this.loading$.value),
        )
        .subscribe(() => {
          this.snackBar.openReloadSnackbar(
            "component.structureTable.snackbarReload",
          );
        }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public downloadDeploymentArchive(structureId: HashId) {
    this.downloadArchive.emit({
      type: "deployment",
      structureId,
    });
  }

  public downloadConfigurationArchive(structureId: HashId) {
    this.downloadArchive.emit({
      type: "configuration",
      structureId,
    });
  }

  public redirectToWorkflow(id: string) {
    this.workflowRequest.emit({
      id,
    });
  }

  public isAllowedToEdit(structure: StructureInfo) {
    if (
      this.authService
        .getScopes()
        .includes("structure:deployment_report:update") &&
      structure.status === "created"
    ) {
      return true;
    }
    if (
      this.authService
        .getScopes()
        .includes("structure:deployment_report:validate") &&
      structure.status === "deployed"
    ) {
      return true;
    }
    if (
      this.authService.getScopes().includes("structure:approve") &&
      structure.status === "validated"
    ) {
      return true;
    }
    return false;
  }

  public loadStructuresPage() {
    this.loading$.next(true);
    this.businessService
      .getStructureInfoList(this.form.value)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe();
  }

  public openWorkflowDialog() {
    this.dialog.open(WorkflowInformationComponent, { autoFocus: false });
  }

  public changePage($event: PageEvent) {
    this.form.get("offset")!.setValue($event.pageSize * $event.pageIndex);
    this.form.get("limit")!.setValue($event.pageSize);
  }

  public getPageIndex() {
    return Math.floor(
      this.form.get("offset")!.value / this.form.get("limit")!.value,
    );
  }

  public openMapDialog(structureInfo: StructureInfo) {
    this.dialog.open(MapDialogComponent, {
      data: { name: structureInfo.name, location: structureInfo.location },
      width: "80vw",
      height: "80vh",
      autoFocus: false,
    });
  }

  public getWorkflowActionTranslationKey(structureStatus: StructureStatus) {
    if (structureStatus === "created")
      return "component.structureTable.createReport";
    if (structureStatus === "deployed")
      return "component.structureTable.validateReport";
    if (structureStatus === "validated")
      return "component.structureTable.approveStructure";
    return;
  }
}

class StructureListDataSource extends DataSource<DisplayedStructure> {
  public constructor(
    private structuresPagination$: Observable<Pagination<StructureInfo>>,
    private businessService: BusinessService,
  ) {
    super();
  }

  public connect(): Observable<DisplayedStructure[]> {
    return this.structuresPagination$.pipe(
      map((pagination) => pagination?.data),
      map(
        (structures) =>
          structures?.map((structure) => {
            if (structure.location?.thumbnail) {
              return {
                ...structure,
                thumbnail: this.businessService
                  .getBlobByHref(structure.location.thumbnail.href)
                  .pipe(mergeMap((blob) => from(blobToDataURL(blob)))),
              };
            }
            return structure;
          }),
      ),
    );
  }
  public disconnect(): void {}
}

interface DisplayedStructure extends StructureInfo {
  thumbnail?: Observable<string>;
}

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.onabort = () => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}
