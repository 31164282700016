<mat-form-field [style.width]="width" [class]="selectStyle">
  <mat-label>{{ labelTranslationKey | translate }}</mat-label>
  <mat-select [formControl]="selectionForm" multiple disableOptionCentering>
    <mat-option *ngFor="let option of options" [value]="option">
      {{
        getOptionTranslationKey(option).key
          | translate: getOptionTranslationKey(option).interpolateParams
      }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="!!selectionForm.value?.length"
    matSuffix
    mat-icon-button
    [id]="buttonId"
    class="clear-button"
    [aria-label]="'common.clear' | translate"
    (click)="clearSelection(); $event.stopPropagation()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
