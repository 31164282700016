import { Component, Input } from "@angular/core";
import { DisplayedFile } from "../../models/file.model";

@Component({
  selector: "app-file-viewer",
  templateUrl: "file-viewer.component.html",
  styleUrls: ["file-viewer.component.scss"],
})
export class FileViewerComponent {
  @Input() public displayedFile!: DisplayedFile;
}
