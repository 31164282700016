import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import localeEn from "@angular/common/locales/en";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { KeycloakAngularModule } from "keycloak-angular";
import { NgxPermissionsModule } from "ngx-permissions";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { DirectivesModule } from "./directives/directives.module";
import { ConfigPageModule } from "./pages/config-page/config-page.module";
import { EventsPageModule } from "./pages/events-page/events-page.module";
import { FilePageModule } from "./pages/file-page/file-page.module";
import { InvoicesPageModule } from "./pages/invoices-page/invoices-page.module";
import { LandingPageModule } from "./pages/landing/landing-page.module";
import { NotificationSettingsPageModule } from "./pages/notification-settings-page/notification-settings-page.module";
import { StructureCreationPageModule } from "./pages/structure-creation/structure-creation-page.module";
import { StructurePageModule } from "./pages/structure-page/structure-page.module";
import { AuthService } from "./services/auth/auth.service";
import { AuthInterceptor } from "./services/network/auth.interceptor";
import { HttpErrorInterceptor } from "./services/network/http-error.interceptor";
import { OpenStreetMapService } from "./services/network/open-street-map.service";
import { CustomIconService } from "./services/utils/custom-icon.service";
import {
  CustomDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "./services/utils/CustomDatePickerAdapter";
import { DynamicLocaleId } from "./services/utils/DynamicLocaleId";
import { LocalizedDatePipe } from "./services/utils/LocalizedDate.pipe";
import { PaginatorIntlService } from "./services/utils/paginatorIntl.service";
import { TimeAgoPipe } from "./services/utils/TimeAgo.pipe";
import { WebpackTranslateLoader } from "./services/utils/webpack-translate-loader";
import { WindowRef } from "./services/utils/window";
import { SharedModule } from "./shared/shared.module";

// Register Locales for DatePipe / Material
registerLocaleData(localeEn, "en");
registerLocaleData(localeFr, "fr", localeFrExtra);
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        useFactory:
          environment.translationsUrl !== "none"
            ? (http: HttpClient) =>
                new TranslateHttpLoader(
                  http,
                  environment.translationsUrl,
                  ".json",
                )
            : undefined,
        deps: [HttpClient],
      },
    }),
    NgxPermissionsModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatBadgeModule,
    MatMenuModule,
    MatButtonModule,
    HttpClientModule,
    ComponentsModule,
    DirectivesModule,
    StructurePageModule,
    StructureCreationPageModule,
    MatTooltipModule,
    SharedModule,
    InvoicesPageModule,
    ConfigPageModule,
    NotificationSettingsPageModule,
    EventsPageModule,
    FilePageModule,
    KeycloakAngularModule,
    LandingPageModule, // Need to be loaded after all others routing modules (for not found wildcard: https://stackoverflow.com/a/54999387)
  ],
  providers: [
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    LocalizedDatePipe,
    TimeAgoPipe,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) =>
        new PaginatorIntlService(translate),
      deps: [TranslateService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => () => auth.init(),
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (openStreetMapService: OpenStreetMapService) => () => {
        openStreetMapService.initialize();
      },
      multi: true,
      deps: [OpenStreetMapService],
    },
    CustomIconService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
