import { LatLngLiteral } from "leaflet";

// for device memory constraint reasons, structureId should be a 32bits hash (Example: 18cdefad)
export type HashId = string;
// regionId and customerId should be limited to alphanumerical with max 10chars (Example: france, apave)
export type ShortId = string;

export type StructureType = "bridge" | "building";

export type StructureAlert = "NONE" | "WARNING" | "ERROR";

export type StructureStatus =
  | "created"
  | "deployed"
  | "validated"
  | "approved"
  | "monitored"
  | "maintenance"
  | "demobilized";

export type DayOfTheWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface LocationObject extends LatLngLiteral {
  thumbnail?: { href: string };
}

export interface StructureInfoBase {
  name: string;
  type: StructureType;
  address: string;
  contact: string; // mail
  regionId: ShortId;
}

export interface StructureInfo extends StructureInfoBase {
  id: HashId;
  customerId: ShortId;
  status: StructureStatus;
  creationDate: Date;
  commissioningDate?: Date;
  maxSohAlarmStatus: StructureAlert;
  location?: LocationObject;
}

export interface StructureInfoNew extends StructureInfoBase {
  id: HashId;
  customerId: ShortId;
  businessType: string;
  lastAmc?: {
    id: number;
    location: string;
  };
  retrieveId?: number;
  region: {
    id: string;
    label: string;
  };
  status: StructureStatus;
  creationDate: Date;
  commissioningDate: Date;
  customerCode: string;
  purchaseOrder: string;
  location?: LocationObject;
}

export interface Status {
  version: number;
  status: string;
  details: string;
}

export type SOH = NodeSOH | GatewaySOH;

export interface BaseSOH {
  serialNumber: string;

  timestampSecond?: number;
  amcId?: number | null;
  firmware?: SoftwareVersion;
  inOperation?: boolean;

  listAlarms: Alarm[];

  battery?: Battery;
  led: Led;

  lora?: Lora;
  wifi?: Wifi;
  lastSeen: Date;
}

export interface NodeSOH extends BaseSOH {
  qs3?: Qs3;
  tilt?: Tilt;
}

export interface GatewaySOH extends BaseSOH {
  gnss?: Gnss;
  temperature?: Temperature;
  humidity?: Humidity;
  ltem?: Ltem;
}

export enum AlarmStatusEnum {
  WARNING = 0,
  ERROR = 1,
}

export enum BaseStatusEnum {
  UNDEFINED = 0,
  OK = 1,
  NOK = 2,
}

export interface SoftwareVersion {
  vmajor: number;
  vminor: number;
  vpatch: number;
}

export interface Alarm {
  id: number;
  status: AlarmStatusEnum;
  timestampSecond: number;
  details: string;
}

export interface Battery {
  chargePercent?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}
export interface Led {
  status?: BaseStatusEnum;
}
export interface Qs3 {
  firmwareVersion?: SoftwareVersion;
  status?: BaseStatusEnum;
  details?: string;
}
export interface Lora {
  firmwareVersion?: SoftwareVersion;
  rssi?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}
export interface Gnss {
  firmwareVersion?: SoftwareVersion;
  quality?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}

export interface Wifi {
  rssi?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}

export interface Ltem {
  firmwareVersion?: SoftwareVersion;
  rssi?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}

export interface Tilt {
  pitch?: number | string;
  roll?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}
export interface Temperature {
  value?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}
export interface Humidity {
  value?: number | string;
  status?: BaseStatusEnum;
  details?: string;
}

export interface NodeSohMeasuringPoint extends Partial<NodeSOH> {
  measuringPoint?: number;
}

export interface GatewaySohMeasuringPoint
  extends Partial<GatewaySOH>,
    NodeSohMeasuringPoint {}

export type DeviceSohMeasuringPoint =
  | NodeSohMeasuringPoint
  | GatewaySohMeasuringPoint;

export interface Structure {
  generalInfo: StructureInfoNew;
  sohNode: NodeSOH[];
  sohGateway: GatewaySOH[];
  measuringPoints: StructureMeasuringPoint[];
  devices: StructureDevice[];
  deploymentReport: {
    devices: StructureDeploymentReportDevice[];
  };
  deploymentFileLocation: string;
  dataUse?: {
    down: number;
    up: number;
  };
}

export interface LocationFormValue extends LatLngLiteral {
  thumbnail?: Blob;
}

export interface StructureCreationDTO extends StructureInfoBase {
  hourOfDayToOversight?: number;
  dayOfWeekToOversight?: string;
  businessType: string;
  deploymentFile?: Blob;
  customerCode: string;
  purchaseOrder: string;
  location?: LocationFormValue;
}

export const measurePointDeactivationReasonOptions = [
  "DEVICE_NOT_INSTALLED",
  "DEVICE_OUT_OF_ORDER",
  "DEVICE_DEMOBILIZED",
  "OTHER",
] as const;
export type MeasurePointDeactivationReason =
  (typeof measurePointDeactivationReasonOptions)[number];

export interface StructureMeasuringPoint {
  comment: string;
  index: number;
  active: boolean;
  deactivationReason?: MeasurePointDeactivationReason;
  deactivationComment?: string;
  deactivationDate?: Date;
}

export interface StructureCreationMeasurePoint {
  comment: string;
  index: number;
}

export interface DeploymentReport {
  devices: StructureDeploymentReportDevice[];
}

export interface StructureDeploymentReportDevice {
  measuringPoint: number;
  serialNumber: string;
  reportComment: string;
}

export interface MeasuringPointComment extends StructureDeploymentReportDevice {
  comment: string;
}

export interface StructureDevice {
  measurePointNumber?: number;
  serialNumber: string;
  type: DeviceType;
  lastSeen: Date;
}

export type DeviceType = "gateway" | "node";

export interface StructureListFilters {
  offset?: number;
  limit?: number;
  nameSearch?: string;
  customerSearch?: string;
  contactSearch?: string;
  types?: string[];
  status?: string[];
  activationDate?: {
    start?: string;
    end?: string;
  };
  creationDate?: {
    start?: string;
    end?: string;
  };
}

export interface PatchMeasurePointDTO {
  active: boolean;
  deactivationReason?: MeasurePointDeactivationReason;
  deactivationComment?: string;
}

export interface PatchMeasurePointResponseDTO {
  measurePointNumber: number;
  active: boolean;
  deactivationReason?: MeasurePointDeactivationReason;
  deactivationComment?: string;
  deactivationDate?: Date;
}
