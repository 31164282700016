import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { KeycloakService } from "keycloak-angular";
import { KeycloakLoginOptions } from "keycloak-js";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { TokenResponse } from "src/app/models/auth.model";
import { environment } from "../../../environments/environment";
import { AppUser } from "../../models/user.model";
import { StorageService } from "../utils/storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly serviceUrl: string = "auth";
  private jwtHandler = new JwtHelperService();
  private token?: AppUser;

  public constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient,
    private storage: StorageService,
    private permissionsService: NgxPermissionsService,
  ) {
    const storedAuthData = this.storage.getAuthData();
    if (storedAuthData) {
      this.token = this.decodeToken(storedAuthData.access_token);
    }
  }

  public async init() {
    await this.keycloakService.init({
      config: {
        url: `${environment.apiUrl}/auth/`,
        realm: "shm",
        clientId: "front",
      },
      initOptions: {
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/assets/silent-check-sso.html",
      },
      shouldAddToken: (request) => {
        const { url } = request;

        const isRetrieveTokenRoute =
          url ===
          `${environment.apiUrl}/auth/realms/shm/protocol/openid-connect/token`;

        return isRetrieveTokenRoute;
      },
    });
    if (this.hasAuthorizationToken()) {
      this.permissionsService.loadPermissions(this.getScopes());
    }
  }

  public async login() {
    this.storage.deleteAuthData();
    this.token = undefined;
    const keycloakLoginOptions: KeycloakLoginOptions = {
      redirectUri: window.location.href,
    };
    await this.keycloakService.login(keycloakLoginOptions);
  }

  public loadAuthorizationToken(): Observable<TokenResponse> {
    const umaBody = new URLSearchParams();
    umaBody.set("grant_type", "urn:ietf:params:oauth:grant-type:uma-ticket");
    umaBody.set("audience", "api-authorization");
    const umaHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${this.keycloakService.getToken()}`,
    });
    return this.http
      .post<TokenResponse>(
        `${environment.apiUrl}/${this.serviceUrl}/realms/shm/protocol/openid-connect/token`,
        umaBody.toString(),
        { headers: umaHeaders },
      )
      .pipe(
        tap((res) => {
          this.storage.storeAuthData(res);
          this.token = this.decodeToken(res.access_token);
          this.permissionsService.loadPermissions(this.getScopes());
        }),
      );
  }

  public async logout() {
    this.storage.deleteAuthData();
    this.token = undefined;
    await this.keycloakService.logout();
  }

  public hasAuthorizationToken() {
    return (
      !!this.token &&
      new Date(this.token.exp * 1000).getTime() > new Date().getTime()
    );
  }

  public getUsername(): string {
    if (!this.hasAuthorizationToken()) {
      return "";
    }
    return this.token!.preferredUsername;
  }

  public getUserId(): string {
    if (!this.hasAuthorizationToken()) {
      return "";
    }
    return this.token!.id;
  }

  public getCustomerId(): string {
    if (!this.hasAuthorizationToken()) {
      return "";
    }
    return this.token!.company;
  }

  public getScopes(): string[] {
    if (!this.hasAuthorizationToken()) {
      return [];
    }
    return this.token!.scopes;
  }

  public getPermissionScopes$(): Observable<string[]> {
    return this.permissionsService.permissions$.pipe(
      map((permissions) => Object.keys(permissions)),
    );
  }

  public getRegions(): string[] {
    if (!this.hasAuthorizationToken()) {
      return [];
    }
    return this.token!.regions;
  }

  private decodeToken(accessToken: string): AppUser {
    const decoded = this.jwtHandler.decodeToken(accessToken);
    const scopes = decoded.resource_access["realm-management"]
      ? [
          ...decoded.resource_access["realm-management"].roles,
          ...decoded.authorization.permissions[0].scopes,
        ]
      : decoded.authorization.permissions[0].scopes;
    return {
      id: decoded.sub,
      email: decoded.email,
      name: decoded.name,
      roles: decoded.realm_access.roles,
      scopes,
      givenName: decoded.given_name,
      familyName: decoded.family_name,
      preferredUsername: decoded.preferred_username,
      company: decoded.groups[0],
      regions: decoded.regions,
      exp: decoded.exp,
    };
  }
}
