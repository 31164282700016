import { Component, OnDestroy, OnInit } from "@angular/core";
import { interval, Observable, Subscription } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { Structure } from "../../../../models/structure.model";
import { BusinessService } from "../../../../services/network/business.service";
import { EVERY_10_SECONDS } from "../../../../services/utils/globals";

@Component({
  selector: "structure-sensors",
  templateUrl: "./structure-sensors.component.html",
  styleUrls: ["./structure-sensors.component.scss"],
})
export class StructureSensorsComponent implements OnInit, OnDestroy {
  public structure$!: Observable<Structure>;
  public isMonitored$!: Observable<boolean>;

  private subscriptions = new Subscription();

  public constructor(private businessService: BusinessService) {}

  public ngOnInit() {
    this.structure$ = this.businessService.structureStream();

    this.isMonitored$ = this.structure$.pipe(
      map((structure) => structure.generalInfo.status === "monitored"),
    );

    // the sensors table is updated in real time
    this.subscriptions.add(
      interval(EVERY_10_SECONDS)
        .pipe(
          mergeMap(() =>
            this.businessService.getStructure(
              this.businessService.getStructureValue().generalInfo.id,
            ),
          ),
        )
        .subscribe(),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
