import { NgModule } from "@angular/core";
import { ComponentsModule } from "../../components/components.module";
import { SharedModule } from "../../shared/shared.module";
import { FilePageComponent } from "./file-page";
import { FilePageRoutingModule } from "./file-page-routing.module";

const COMPONENTS = [FilePageComponent];
@NgModule({
  imports: [FilePageRoutingModule, SharedModule, ComponentsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class FilePageModule {}
