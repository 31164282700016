<div class="notification-settings-page">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <form
    class="settings-container"
    [formGroup]="form"
    (ngSubmit)="onFormSubmit()"
  >
    <div class="title-container">
      <h2>{{ 'page.notificationSettings.emailNotifications' | translate }}</h2>
      <mat-checkbox
        color="primary"
        class="slide-toggle"
        [checked]="isAllSelected()"
        (change)="toggleAll($event)"
      >
      </mat-checkbox>
    </div>

    <mat-selection-list [formControl]="form">
      <ng-container *ngFor="let category of categories">
        <event-category-checkbox
          [form]="form"
          [category]="category"
        ></event-category-checkbox>
        <mat-list-option
          *ngFor="let type of category.types"
          color="primary"
          [value]="type"
        >
          {{ "component.eventTypeSelect.type." + type | translate }}
        </mat-list-option>
      </ng-container>
    </mat-selection-list>

    <button
      color="primary"
      mat-raised-button
      class="submit-button"
      type="submit"
      [disabled]="!form.dirty"
    >
      {{ "common.save"| translate}}
    </button>
  </form>
</div>
