<div class="outer-content" *ngIf="amc$ | async as amc">
  <div class="body-content">
    <h2>{{ "page.structure.config.amcTitle" | translate }}</h2>
    <form
      class="weekly"
      [formGroup]="weeklyForm"
      (ngSubmit)="submitWeekly(amc)"
    >
      <mat-expansion-panel
        id="structure-config-expansion-panel-weekly"
        class="amc-panel"
        [expanded]="weeklyExpanded"
      >
        <mat-expansion-panel-header (click)="$event.preventDefault()">
          <mat-panel-title>
            <div>
              {{ "page.structure.config.weekly.title" | translate }}
              <mat-slide-toggle
                [formControl]="weeklyToggleform"
                (change)="toggleWeekly($event, amc)"
                (click)="$event.stopPropagation()"
              ></mat-slide-toggle>
            </div>
            <mat-card-subtitle>
              {{ "page.structure.config.weekly.subtitle" | translate }}
            </mat-card-subtitle>
          </mat-panel-title>

          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="weekly-expansion-panel-body">
          <mat-form-field>
            <mat-label>{{
              "component.structureForm.dayToOversight" | translate
            }}</mat-label>
            <mat-select
              id="structure-config-week-day-select"
              formControlName="dayOfWeekToOversight"
            >
              <mat-option value="monday">{{ getWeekdayName(1) }}</mat-option>
              <mat-option value="tuesday">{{ getWeekdayName(2) }}</mat-option>
              <mat-option value="wednesday">{{ getWeekdayName(3) }}</mat-option>
              <mat-option value="thursday">{{ getWeekdayName(4) }}</mat-option>
              <mat-option value="friday">{{ getWeekdayName(5) }}</mat-option>
              <mat-option value="saturday">{{ getWeekdayName(6) }}</mat-option>
              <mat-option value="sunday">{{ getWeekdayName(7) }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="weeklyForm.controls['dayOfWeekToOversight'].invalid"
              >{{
                "component.structureForm.dayToOversightIsRequired" | translate
              }}</mat-error
            >
          </mat-form-field>

          <div class="input-number-field">
            <input-number
              [form]="weeklyForm.get('hourOfDayToOversight')"
              [id]="'structure-config-weekly-hours-input'"
              [label]="'component.structureForm.hourToOversight' | translate"
              [error]="
                'component.structureForm.hourToOversightRequired' | translate
              "
            ></input-number>
          </div>
          <div class="buttons">
            <button
              *ngIf="weeklyForm.enabled"
              id="structure-config-weekly-submit-button"
              mat-flat-button
              color="accent"
              type="submit"
              [disabled]="readMode || weeklyForm.invalid || (waiting$ | async)"
              (click)="$event.stopPropagation()"
            >
              {{ "common.validate" | translate }}
              <mat-icon>check</mat-icon>
            </button>

            <button
              *ngIf="weeklyForm.enabled"
              id="structure-config-weekly-cancel-button"
              color="primary"
              mat-flat-button
              (click)="disableWeeklyForm(true); $event.stopPropagation()"
            >
              {{ "common.cancel" | translate }}
            </button>

            <button
              *ngIf="weeklyForm.disabled"
              id="structure-config-weekly-edit-button"
              mat-flat-button
              color="accent"
              [disabled]="readMode || (waiting$ | async)"
              (click)="weeklyForm.enable(); $event.stopPropagation()"
            >
              {{ "common.edit" | translate }}

              <mat-icon *ngIf="waiting$ | async"
                ><mat-spinner diameter="20"> </mat-spinner
              ></mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </form>
    <form class="on-demand" [formGroup]="onDemandForm">
      <mat-expansion-panel
        id="structure-config-expansion-panel-on-demand"
        class="amc-panel"
        [expanded]="onDemandExpanded"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "page.structure.config.onDemand.title" | translate }}
            <mat-card-subtitle>
              {{ "page.structure.config.onDemand.subtitle" | translate }}
            </mat-card-subtitle>
          </mat-panel-title>

          <mat-panel-description>
            <div class="last-on-demand-date" *ngIf="amc.lastOnDemandSwitchDate">
              {{
                "page.structure.config.lastOnDemandActivation"
                  | translate
                    : {
                        date:
                          amc.lastOnDemandSwitchDate
                          | localizedDate: "short" : "UTC"
                      }
              }}
            </div>
            <div class="buttons">
              <button
                *ngIf="onDemandForm.disabled"
                id="structure-config-on-demand-edit-panel-button"
                mat-flat-button
                color="accent"
                (click)="onClickEditOnDemand($event)"
                [disabled]="readMode || (waiting$ | async)"
              >
                {{ "common.edit" | translate }}
              </button>

              <button
                *ngIf="onDemandForm.enabled"
                id="structure-config-on-demand-submit-button"
                mat-flat-button
                class="on-demand-submit-button"
                color="accent"
                (click)="submitOnDemand($event, amc); $event.stopPropagation()"
                [disabled]="readMode || (waiting$ | async)"
              >
                {{ "page.structure.config.trigger" | translate }}

                <mat-icon>check</mat-icon>
                <mat-icon *ngIf="waiting$ | async"
                  ><mat-spinner diameter="20"> </mat-spinner
                ></mat-icon>
              </button>

              <button
                *ngIf="onDemandForm.enabled"
                id="structure-config-on-demand-cancel-button"
                mat-flat-button
                class="icon-button"
                color="primary"
                (click)="onClickCancelOnDemand($event)"
              >
                {{ "common.cancel" | translate }}
              </button>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <measure-points-table-select
          [form]="onDemandForm.get('nodesFilter')"
        ></measure-points-table-select>
      </mat-expansion-panel>
    </form>
    <h2>{{ "page.structure.config.timeoutTitle" | translate }}</h2>
    <timeout-config></timeout-config>
  </div>
</div>
