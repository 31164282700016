import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { BusinessService } from "../../../services/network/business.service";

@Injectable()
export class StructureAmcResolver {
  public constructor(private businessService: BusinessService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    const id = route.parent!.parent!.paramMap.get("id");
    if (id) {
      return this.businessService.getLastAmc(id);
    }
  }
}
