<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input [id]="id" matInput [formControl]="form" type="number" />
  <button
    mat-icon-button
    matSuffix
    type="button"
    [id]="id + '-decrement-button'"
    class="decrement-button"
    (click)="decrement()"
    [disabled]="isDecrementDisabled()"
  >
    <mat-icon class="decrement-icon">keyboard_arrow_down</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    type="button"
    [id]="id + '-increment-button'"
    class="increment-button"
    (click)="increment()"
    [disabled]="isIncrementDisabled()"
  >
    <mat-icon class="increment-icon">keyboard_arrow_up</mat-icon>
  </button>
  <mat-error *ngIf="form.invalid">{{ error }}</mat-error>
</mat-form-field>
