import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AmcModeType } from "../../../models/amc.model";
import { StructureMeasuresPageStore } from "../../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";

@Component({
  selector: "structure-measures-table-requested-date-header-cell",
  templateUrl:
    "./structure-measures-table-requested-date-header-cell.component.html",
  styleUrls: [
    "./structure-measures-table-requested-date-header-cell.component.scss",
  ],
  providers: [LocalizedDatePipe],
})
export class StructureMeasuresRequestedDateHeaderCellComponent {
  public form!: FormGroup;

  public rowId!: string;
  public amcSyncOptions = [true, false];
  public syncTranslations = this.amcSyncOptions.map((isSync) =>
    isSync
      ? { key: "component.measuresTable.amcSync.options.sync" }
      : { key: "component.measuresTable.amcSync.options.notSync" },
  );
  public amcModeOptions: AmcModeType[] = ["weekly", "on_demand"];
  public amcModeTranslations = this.amcModeOptions.map((mode) => ({
    key: `component.measuresTable.amcMode.${mode}`,
  }));

  public constructor(private pageStore: StructureMeasuresPageStore) {
    this.form = this.pageStore.getFilterForm();
  }
}
