<div class="container">
  <mat-select
    [value]="translate.currentLang"
    class="lang"
    #langSelect
    (selectionChange)="onLanguageChange($event)"
  >
    <mat-select-trigger class="lang-code">
      {{ getLanguage(langSelect.value) }}
    </mat-select-trigger>
    <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
      <div class="lang-name">
        <span>{{ getLanguageNativeName(lang) }}</span>
      </div>
    </mat-option>
  </mat-select>
</div>
