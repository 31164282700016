import { DOCUMENT } from "@angular/common";
import {
  AfterViewChecked,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { isEqual } from "lodash";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { delay, distinctUntilChanged, first, map } from "rxjs/operators";
import { Amc, AmcModeType } from "src/app/models/amc.model";
import {
  ProcessingRequest,
  ProcessingRequestStatusesFilter,
} from "../../models/processing-request.model";
import {
  DisplayMode,
  MeasureRow,
  MeasureTableFilters,
  ReceivedRow,
  RequestedDateRow,
  StructureMeasuresPageStore,
} from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { AuthService } from "../../services/auth/auth.service";
import { BusinessService } from "../../services/network/business.service";
import { LocalizedDatePipe } from "../../services/utils/LocalizedDate.pipe";
import {
  parseArrayQueryParam,
  removeNullishValues,
} from "../../services/utils/utils";
import { StructureMeasureRowsManager } from "./structure-measure-rows.manager";

@Component({
  selector: "structure-measures-table",
  templateUrl: "./structure-measures-table.component.html",
  styleUrls: ["./structure-measures-table.component.scss"],
  providers: [LocalizedDatePipe, StructureMeasureRowsManager],
})
export class StructureMeasuresTableComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  public form: FormGroup;
  public processedRows$!: Observable<MeasureRow[]>;
  public rawRows$!: Observable<MeasureRow[]>;
  public selectedAmc$ = new BehaviorSubject<SelectedAmc | undefined>(undefined);
  public measureOpenSensorsSelected?: ReceivedRow;
  public selectedAuthorizationDate?: Date;
  public displayMode$: Observable<DisplayMode>;
  public stateDisplayMode$: Observable<string>;

  private subscriptions = new Subscription();

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private businessService: BusinessService,
    private translate: TranslateService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private route: ActivatedRoute,
    private rowsManager: StructureMeasureRowsManager,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.displayMode$ = this.pageStore.getDisplayMode$();
    this.stateDisplayMode$ = this.displayMode$.pipe(
      map((displayMode) => (displayMode === "processed" ? "first" : "second")),
    );
    this.form = this.pageStore.getFilterForm();
  }

  public ngOnInit() {
    this.processedRows$ = this.pageStore
      .getRows$()
      .pipe(map((rows) => rows.filter((row) => row.displayedInProcessed)));
    this.rawRows$ = this.pageStore
      .getRows$()
      .pipe(map((rows) => rows.filter((row) => row.displayedInRaw)));
    this.dateAdapter.setLocale(this.translate.currentLang);

    this.subscriptions.add(
      this.rowsManager.getFetchingObservable().subscribe(),
    );

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          map((value: MeasureTableFilters) => {
            // ignore requestedDate filter, it is handled below
            const { requestedDate, ...rest } = value;
            return rest;
          }),
          distinctUntilChanged((prev, next) => isEqual(prev, next)),
        )
        .subscribe(() => {
          this.rowsManager.refilterRows();
        }),
    );

    this.subscriptions.add(
      this.form
        .get("requestedDate")!
        .valueChanges.pipe(
          distinctUntilChanged((prev, next) => isEqual(prev, next)),
        )
        .subscribe(() => {
          this.rowsManager.fetchMoreMeasures({ resetRows: true });
        }),
    );

    this.subscriptions.add(
      // to dynamically update the date in the filter
      this.translate.onLangChange.subscribe(
        (langChangeEvent: LangChangeEvent) => {
          this.dateAdapter.setLocale(langChangeEvent.lang);
        },
      ),
    );

    this.subscriptions.add(
      combineLatest([
        this.form.valueChanges,
        this.pageStore.getDisplayMode$(),
      ]).subscribe(async () => this.putFiltersInQueryParams()),
    );

    this.subscriptions.add(
      this.route.queryParams
        .pipe(first())
        .subscribe((params) => this.putQueryParamsInFilters(params)),
    );

    this.subscriptions.add(
      this.pageStore
        .getScrollToRequestedDate$()
        .pipe(delay(0)) // wait row to be displayed
        .subscribe((requestedDate) => {
          const mode = this.pageStore.getDisplayMode();
          const id = `structure-measures-table-${mode}-${requestedDate.toISOString()}`;
          document?.getElementById(id)?.scrollIntoView();
        }),
    );
  }

  /**
   * Run after each rendering: if there are more dates and the scrollbar is
   * not visible (the screen is big enough to show everything), we fetch more dates
   */
  public async ngAfterViewChecked(): Promise<void> {
    // without the await 1ms, the document scrollheight isnt always up to date :/
    await new Promise((resolve) => setTimeout(resolve, 1));
    if (!this.hasScrollbar()) {
      this.rowsManager.fetchMoreMeasures();
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.pageStore.resetRowsAndScroll();
  }

  public onScroll() {
    this.rowsManager.fetchMoreMeasures();
  }

  public onAmcSelected(measure: RequestedDateRow) {
    this.closePanel();
    this.businessService
      .getAmc(this.pageStore.getStructureId(), measure.amc.id)
      .subscribe((amc) => {
        this.selectedAmc$.next({ amc, mode: measure.amc.mode });
      });
  }

  public onMeasureOpenSensorsSelected(measure: ReceivedRow) {
    this.closePanel();
    this.measureOpenSensorsSelected = measure;
  }

  public onAuthorizationDateSelected(requestedDate: Date) {
    this.closePanel();
    this.selectedAuthorizationDate = requestedDate;
  }

  public closePanel() {
    this.measureOpenSensorsSelected = undefined;
    this.selectedAmc$.next(undefined);
    this.selectedAuthorizationDate = undefined;
  }

  public hasAuthorizeRawPermission() {
    return this.authService
      .getScopes()
      .includes("structure:measure:raw:authorization:update");
  }

  public onToggleMode(requestedDate?: Date) {
    this.closePanel();
    this.pageStore.toggleDisplayMode(requestedDate);
  }

  public onForceProcessing(processingRequest: ProcessingRequest) {
    this.rowsManager.addProcessingRequest(processingRequest);
  }

  public onRawsAuthorize(rows: ReceivedRow[]) {
    this.rowsManager.setRawAuthorized(rows);
  }

  private async putFiltersInQueryParams() {
    const {
      requestedDate,
      measurePoints,
      isAmcSync,
      amcMode,
      processingRequestStatuses,
      isMeasurePointActive,
    } = this.form.getRawValue() as MeasureTableFilters;

    const fromRequestedDate = requestedDate.start;
    const toRequestedDate = requestedDate.end;

    const displayMode = this.pageStore.getDisplayMode();

    const params = {
      fromRequestedDate,
      toRequestedDate,
      measurePoints,
      isAmcSync,
      amcMode,
      displayMode,
      processingRequestStatuses,
      isMeasurePointActive,
    };
    const queryParams = removeNullishValues(params);
    await this.router.navigate([], {
      queryParams,
    });
  }

  private putQueryParamsInFilters(params: Params) {
    const { fromRequestedDate, toRequestedDate, displayMode } = params;

    const measurePoints = parseArrayQueryParam(params.measurePoints, Number);
    const isAmcSync = parseArrayQueryParam(
      params.isAmcSync,
      (v) => v === "true",
    );
    const amcMode = parseArrayQueryParam<AmcModeType>(params.amcMode);
    const processingRequestStatuses =
      parseArrayQueryParam<ProcessingRequestStatusesFilter>(
        params.processingRequestStatuses,
      );
    const isMeasurePointActive = parseArrayQueryParam(
      params.isMeasurePointActive,
      Boolean,
    );

    const values = {
      requestedDate: {
        start: fromRequestedDate ?? "",
        end: toRequestedDate ?? "",
      },
      measurePoints: measurePoints ?? [],
      isAmcSync: isAmcSync ?? [],
      amcMode: amcMode ?? [],
      processingRequestStatuses: processingRequestStatuses ?? [],
      isMeasurePointActive: isMeasurePointActive ?? [],
    };

    if (displayMode && displayMode !== this.pageStore.getDisplayMode())
      this.pageStore.toggleDisplayMode();

    this.form.setValue(values);
  }

  private hasScrollbar() {
    const { clientHeight, scrollHeight } = this.document.body;
    return clientHeight < scrollHeight;
  }
}

interface SelectedAmc {
  amc: Amc;
  mode: AmcModeType;
}
