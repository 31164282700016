<form [formGroup]="form">
  <label>{{ "component.structureForm.location" | translate }}</label>
  <div
    leaflet
    [leafletOptions]="mapOptions"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
    class="map"
  >
    <div [leafletLayer]="locationMarker$ | async"></div>
    <button
      (click)="onClickClear($event)"
      class="leaflet-bottom leaflet-left button-clear"
      *ngIf="(locationMarker$ | async) && !readMode"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div formGroupName="location">
    <input type="hidden" formControlName="lat" />
    <input type="hidden" formControlName="lng" />
    <input type="hidden" formControlName="thumbnail" />
  </div>
  <div #screenshotableMap class="screenshotableMap"></div>
</form>
