import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { BusinessService } from "../../services/network/business.service";
import { EVERY_10_SECONDS } from "../../services/utils/globals";
import { SnackbarService } from "../../services/utils/snackbar.service";
import { DateRangePickerFormValue } from "../date-range-picker/date-range-picker.component";

@Component({
  selector: "structure-measures-table-refresh-snackbar",
  template: "",
})
export class StructureMeasureTableRefreshSnackbarComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() filter?: DateRangePickerFormValue;

  private subscriptions = new Subscription();
  private nbOfMeasures!: number;
  private showedSnackbar = false;

  public constructor(
    private businessService: BusinessService,
    private snackBar: SnackbarService,
  ) {}

  public ngOnChanges() {
    this.fetchTotalNumberOfMeasures().subscribe((total) => {
      this.showedSnackbar = false;
      this.nbOfMeasures = total;
    });
  }

  public ngOnInit() {
    this.subscriptions.add(
      interval(EVERY_10_SECONDS)
        .pipe(mergeMap(() => this.fetchTotalNumberOfMeasures()))
        .subscribe((total) => {
          if (
            // nbOfMeasuresAtTableLoad has been initialized by datesForm.valueChanges
            // because datesForm.valueChanges is triggered at component load
            total !== this.nbOfMeasures &&
            !this.showedSnackbar
          ) {
            this.showedSnackbar = true;
            this.snackBar.openReloadSnackbar(
              "component.measuresTable.snackbarReload",
            );
          }
        }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private fetchTotalNumberOfMeasures() {
    // we use "?? {}"" because if the value is provided
    // with an async pipe, the first value will be null
    const { start, end } = this.filter ?? {};
    return this.businessService
      .getMeasures({
        limit: 1,
        structureId: this.businessService.getStructureValue().generalInfo.id,
        fromRequestedDate: start,
        toRequestedDate: end,
      })
      .pipe(map((result) => result.total));
  }
}
