<h1 mat-dialog-title>
  {{
    "component.measurePointActiveToggleDeactivateDialog.title"
      | translate: { measurePointNumber }
  }}
</h1>
<div mat-dialog-content>
  <p>
    <mat-form-field>
      <mat-label>
        {{
          "component.measurePointActiveToggleDeactivateDialog.deactivationReason"
            | translate
        }}
      </mat-label>
      <mat-select
        [formControl]="reasonControl"
        id="measure-point-active-toggle-deactivate-dialog-reason-select"
      >
        <mat-option *ngFor="let option of reasonOptions" [value]="option">
          {{ "api.deactivationReason." + option | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field>
      <mat-label>
        {{
          "component.measurePointActiveToggleDeactivateDialog.deactivationComment"
            | translate
        }}
      </mat-label>
      <textarea
        matInput
        [formControl]="commentControl"
        cdkTextareaAutosize
        id="measure-point-active-toggle-deactivate-dialog-comment"
      ></textarea>
    </mat-form-field>
  </p>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close
    color="accent"
    id="measure-point-active-toggle-deactivate-dialog-cancel"
  >
    {{ "common.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="{
      active: false,
      deactivationReason: reasonControl.value,
      deactivationComment: commentControl.value
    }"
    color="primary"
    id="measure-point-active-toggle-deactivate-dialog-confirm"
  >
    {{ "common.confirm" | translate }}
  </button>
</div>
