import { Component } from "@angular/core";

@Component({
  selector: "dialog-measures-table-download-all-raw-warning",
  template: `
    <h2 mat-dialog-title>
      {{
        "component.measuresTable.dialogDownloadAllRawWarning.title" | translate
      }}
    </h2>
    <div mat-dialog-content>
      <p>
        {{
          "component.measuresTable.dialogDownloadAllRawWarning.content"
            | translate
        }}
      </p>
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        id="dialog-measures-table-download-all-raw-warning-cancel-button"
        color="primary"
        mat-dialog-close
      >
        {{ "common.cancel" | translate }}
      </button>
      <button
        mat-raised-button
        id="dialog-measures-table-download-all-raw-warning-validate-button"
        color="primary"
        [mat-dialog-close]="true"
      >
        {{ "common.validate" | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogMeasuresTableDownloadAllRawWarning {}
