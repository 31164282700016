<h1 mat-dialog-title>
  {{
    "component.measurePointActiveToggleActivateDialog.title"
      | translate: { measurePointNumber }
  }}
</h1>
<div
  mat-dialog-content
  [innerHTML]="
    'component.measurePointActiveToggleActivateDialog.text' | translate
  "
></div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close
    color="accent"
    id="measure-point-active-toggle-activate-dialog-cancel"
  >
    {{ "common.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="{ active: true }"
    color="primary"
    id="measure-point-active-toggle-activate-dialog-confirm"
  >
    {{ "common.confirm" | translate }}
  </button>
</div>
