<div class="filter-container">
  <div class="mat-fab-buttons">
    <button *ngIf="!show" mat-mini-fab color="primary" (click)="show = true">
      <mat-icon>sort</mat-icon>
    </button>
    <button *ngIf="show" mat-mini-fab color="primary" (click)="show = !show">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="show" mat-mini-fab color="primary" (click)="resetFilters()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="expand-container" [ngClass]="{ expanded: show }">
    <div class="filter-header"></div>

    <form [formGroup]="form" class="form-container">
      <search-input
        [form]="form.get('contains')"
        searchInputStyle=""
      ></search-input>

      <app-date-range-picker [form]="form.get('date')"></app-date-range-picker>

      <event-type-select [form]="form.get('types')"></event-type-select>

      <structure-select-autocomplete
        [form]="form.get('structureId')"
      ></structure-select-autocomplete>

      <customer-select
        *ngxPermissionsOnly="['query-groups', 'structure:foreign:read']"
        [form]="form.get('customerId')"
      ></customer-select>
    </form>
  </div>
</div>
