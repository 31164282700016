<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.889 27.7">
  <g id="download" transform="translate(.001)">
    <g id="Group_704" data-name="Group 704">
      <path
        id="Path_1113"
        d="M29.032 228.277a.853.853 0 0 0-.856.856v7.789a3.843 3.843 0 0 1-3.838 3.838H5.55a3.843 3.843 0 0 1-3.838-3.838v-7.916a.856.856 0 1 0-1.713 0v7.916a5.556 5.556 0 0 0 5.55 5.55h18.789a5.556 5.556 0 0 0 5.55-5.55v-7.789a.857.857 0 0 0-.856-.856z"
        fill="currentColor"
        data-name="Path 1113"
        transform="translate(0 -214.772)"
      />
      <path
        id="Path_1114"
        d="M141.926 38.15a.862.862 0 0 0 .6.254.836.836 0 0 0 .6-.254l5.442-5.442a.857.857 0 1 0-1.212-1.212l-3.977 3.983V18.106a.856.856 0 1 0-1.713 0V35.48l-3.977-3.98a.857.857 0 0 0-1.212 1.212z"
        fill="currentColor"
        data-name="Path 1114"
        transform="translate(-127.584 -17.25)"
      />
    </g>
  </g>
</svg>
