import { Pagination } from "../../models/pagination.model";
import {
  StructureInfo,
  StructureStatus,
  StructureType,
} from "../../models/structure.model";

export function mapPaginationToStructureInfoPagination(
  values: unknown,
): Pagination<StructureInfo> {
  const paginationObject: Pagination<StructureInfo> =
    values as Pagination<StructureInfo>;
  paginationObject.data = paginationObject.data.map((structure) => {
    const structureInfo: StructureInfo = {
      id: structure.id,
      customerId: structure.customerId,
      name: structure.name,
      type: structure.type as StructureType,
      status: structure.status as StructureStatus,
      address: structure.address,
      location: structure.location,
      contact: structure.contact,
      regionId: structure.regionId,
      creationDate: new Date(structure.creationDate),
      commissioningDate: structure.commissioningDate
        ? new Date(structure.commissioningDate)
        : undefined,
      maxSohAlarmStatus: structure.maxSohAlarmStatus,
    };
    return structureInfo;
  });
  return paginationObject;
}
