import { Location } from "@angular/common";
import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { BusinessService } from "src/app/services/network/business.service";
import { Structure } from "../../../../models/structure.model";
import { DeploymentService } from "../../../../services/network/deployment.service";
import { StorageService } from "../../../../services/utils/storage.service";
import { AbstractStructureWorkflowPage } from "../abstract/abstract-structure-workflow-page";

@Component({
  selector: "structure-approval-page",
  templateUrl: "./structure-approval-page.html",
  styleUrls: ["./structure-approval-page.scss"],
})
export class StructureApprovalPage extends AbstractStructureWorkflowPage {
  public structure$: Observable<Structure>;

  public isLoading$ = new BehaviorSubject(false);
  public form: FormGroup<{
    customerCode: FormControl<string>;
    purchaseOrder: FormControl<string>;
  }>;

  public constructor(
    protected location: Location,
    protected businessService: BusinessService,
    protected route: ActivatedRoute,
    private deploymentService: DeploymentService,
    protected storage: StorageService,
  ) {
    super(location, businessService, route, storage);
    this.structure$ = new Observable<Structure>();
    this.form = new FormGroup({
      customerCode: new FormBuilder().nonNullable.control(
        "",
        Validators.required,
      ),
      purchaseOrder: new FormBuilder().nonNullable.control(
        "",
        Validators.required,
      ),
    });
  }

  public ngOnInit() {
    this.setHeader("page.structure.approval.title");

    this.structure$ = this.businessService.structureStream();

    this.structure$.subscribe((structure) => {
      this.form.controls["customerCode"].setValue(
        structure.generalInfo.customerCode,
      );
      this.form.controls["purchaseOrder"].setValue(
        structure.generalInfo.purchaseOrder,
      );
    });
  }

  public approveStructure() {
    this.isLoading$.next(true);
    const { customerCode, purchaseOrder } = this.form.getRawValue();
    this.deploymentService
      .sendApprobation(this.structureId, { customerCode, purchaseOrder })
      .pipe(
        this.businessService.pollUntilStructureLeftState(
          this.structureId,
          "validated",
        ),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe(() => this.location.back());
  }

  public disapproveStructure() {
    this.isLoading$.next(true);
    this.deploymentService
      .disapproveStructure(this.structureId)
      .pipe(
        this.businessService.pollUntilStructureLeftState(
          this.structureId,
          "validated",
        ),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe(() => this.location.back());
  }
}
