<div class="file-viewer-container">
  <div class="actions">
    <button
      id="file-viewer-download-button"
      mat-raised-button
      color="primary"
      class="rounded-border-button"
      (click)="downloadFile()"
    >
      {{ "common.download" | translate }}
      <mat-icon matSuffix> download </mat-icon>
    </button>
  </div>

  <div class="content">
    <pdf-viewer
      [src]="displayedFile.file"
      [render-text]="true"
      [original-size]="true"
    ></pdf-viewer>
  </div>
</div>
