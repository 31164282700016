import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentsModule } from "../../components/components.module";
import { SharedModule } from "../../shared/shared.module";
import { NotificationSettingsPage } from "./notification-settings-page";
import { NotificationSettingsPageRoutingModule } from "./notification-settings-page-routing.module";

const COMPONENTS = [NotificationSettingsPage];
@NgModule({
  imports: [
    NotificationSettingsPageRoutingModule,
    SharedModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    ComponentsModule,
    MatListModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class NotificationSettingsPageModule {}
