<div class="page-header">
  <div class="header-title">
    <button
      *ngIf="(header$ | async).showHomeButton"
      mat-icon-button
      color="primary"
      aria-label="home"
      (click)="redirectToLandingPage()"
    >
      <mat-icon>home</mat-icon>
    </button>
    <button
      *ngIf="(header$ | async).showBackButton"
      mat-icon-button
      color="primary"
      aria-label="back"
      (click)="redirectToPreviousPage()"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{
      (header$ | async).pageTitle | translate: (header$ | async).pageTitleParams
    }}</span>
  </div>
</div>
