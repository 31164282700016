import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSelectionList } from "@angular/material/list";
import { forkJoin } from "rxjs";
import { finalize, map, mergeMap } from "rxjs/operators";
import {
  isReceivedRow,
  ReceivedRow,
  StructureMeasuresPageStore,
} from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { BusinessService } from "../../services/network/business.service";
import { OperationService } from "../../services/network/operation.service";
import { SnackbarService } from "../../services/utils/snackbar.service";
import { pollUntil$ } from "../../services/utils/utils";
@Component({
  selector: "side-panel-measures-authorizations",
  templateUrl: "./side-panel-measures-authorizations.component.html",
  styleUrls: ["./side-panel-measures-authorizations.component.scss"],
})
export class SidePanelMeasuresAuthorizationsComponent implements OnChanges {
  @ViewChild("selection", { static: true })
  private selection!: MatSelectionList;

  @Input() public requestedDate!: Date;
  @Output() public closePanelEvent = new EventEmitter<boolean>();
  @Output() public rawsAuthorize = new EventEmitter<ReceivedRow[]>();

  public measures!: ReceivedRow[];
  public loading!: boolean;

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private operationService: OperationService,
    private businessService: BusinessService,
    private snackBar: SnackbarService,
  ) {}

  public ngOnChanges(): void {
    this.measures = this.pageStore
      .getRowGroupForDate(this.requestedDate)!
      .filter(isReceivedRow)
      .filter(
        (measure) =>
          measure.sensors.raw &&
          !measure.sensors.raw.customerDownloadAuthorization,
      );
  }

  public toggleAll() {
    if (!this.isAllSelected()) {
      this.selection.selectAll();
    } else {
      this.selection.deselectAll();
    }
  }

  public isAllSelected() {
    return (
      this.selection.selectedOptions.selected.length === this.measures.length
    );
  }

  public closePanel() {
    this.closePanelEvent.emit(true);
  }

  public isOneOrMoreSelected() {
    return this.selection.selectedOptions.selected.length > 0;
  }

  public authorize() {
    this.loading = true;

    const measures = this.selection.selectedOptions.selected.map(
      (select) => select.value as ReceivedRow,
    );
    const requestArray = measures.map((measure) =>
      this.operationService
        .authorizeMeasureRaw(
          this.businessService.getStructureValue().generalInfo.region.id!,
          measure.id,
        )
        .pipe(
          mergeMap(() =>
            pollUntil$(() =>
              this.businessService
                .getMeasure(measure.id)
                .pipe(map((m) => m.sensors.raw?.customerDownloadAuthorization)),
            ),
          ),
        ),
    );
    forkJoin(requestArray)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closePanel();
        }),
      )
      .subscribe((results) => {
        if (results.some((success) => !success)) {
          this.snackBar.open("snackbar.requestFailed");
        }
        this.rawsAuthorize.emit(measures);
      });
  }
}
