import { interval, Observable, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  take,
  timeoutWith,
} from "rxjs/operators";

export function clearSpecialCharacters(entry: string): string {
  return entry.replace(/[^A-Z0-9]/gi, "_");
}

export function clearWhiteSpaceAndLowerCase(entry: string): string {
  return entry.replace(/\s/g, "").toLowerCase();
}

/**
 * Call `doPoll$` every 0.5s until it emits a truthy value, for 5s max.
 *
 * Emits only one value: true if the polling was successful, false in case of timeout or error.
 */
export function pollUntil$(
  doPoll$: () => Observable<unknown>,
  timeout = 5000,
): Observable<boolean> {
  return interval(500).pipe(
    mergeMap(doPoll$),
    filter((response) => !!response),
    map(() => true),
    timeoutWith(timeout, of(false)),
    take(1),
    catchError(() => of(false)),
  );
}

/**
 * Same as Object.hasOwnProperty() but it tells typescript that we can use the tested property after that.
 *
 * https://fettblog.eu/typescript-hasownproperty/
 */
export function hasOwnProperty<
  // eslint-disable-next-line @typescript-eslint/ban-types
  X extends {},
  Y extends PropertyKey,
>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

export function formatFilename(
  filename: string,
  shouldReplaceDash = true,
  capitalized = true,
) {
  const filenameSplit = filename.split(".");
  filenameSplit.pop();
  const nameWithoutExtension = filenameSplit.join(".");
  const nameWithoutDash = shouldReplaceDash
    ? nameWithoutExtension.replace("-", " ")
    : nameWithoutExtension;

  const nameCapitalized = capitalized
    ? nameWithoutDash.charAt(0).toUpperCase() + nameWithoutDash.slice(1)
    : nameWithoutDash;
  return nameCapitalized;
}

export function removeNullishValues<T>(
  queryParams: Record<string, T>,
): Record<string, T> {
  const toRemove: unknown[] = [undefined, null, ""];
  const entries = Object.entries(queryParams);
  const entriesFiltered = entries.filter(
    ([, value]) => !toRemove.includes(value),
  );
  return Object.fromEntries(entriesFiltered);
}

export function parseArrayQueryParam<T>(
  param: string | string[] | undefined,
  parseFn?: (m: string) => T,
): T[] {
  if (typeof param === "string") {
    return parseFn ? [parseFn(param)] : [param as unknown as T];
  } else if (Array.isArray(param)) {
    return parseFn ? param.map(parseFn) : param.map((p) => p as unknown as T);
  }
  return [];
}

// from https://stackoverflow.com/a/60142095
export type Entries<T> = Array<
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T]
>;
