<div class="container">
  <div class="round-lock">
    <mat-icon class="white-icon">lock</mat-icon>
  </div>
  <div class="certificate-card">
    <div class="block marged">
      <div class="title">{{ "common.deploymentArchive" | translate }}</div>
      <div
        id="certificate-display-download-deployment"
        class="link"
        (click)="downloadDeploymentArchive()"
      >
        {{ "common.download" | translate }}
      </div>
    </div>
    <div class="block">
      <div class="title">{{ "common.configurationArchive" | translate }}</div>
      <div
        id="certificate-display-download-configuration"
        class="link"
        (click)="downloadConfigurationArchive()"
      >
        {{ "common.download" | translate }}
      </div>
    </div>
    <div></div>
  </div>
</div>
