<!-- template: synthesis cell -->
<div class="synthesis-cell" [id]="rowId">
  <switch-button
    [buttonId]="rowId + '-switch-button'"
    [state]="stateDisplayMode"
    (toggle)="onToggleMode(row.requestedDate)"
    [isSmall]="true"
  ></switch-button>
  <span>
    {{
      "common.dateUTC"
        | translate
          : {
              date: row.requestedDate | localizedDate: "UTCAllDigits" : "UTC"
            }
    }}
  </span>
  <old-measure-icon *ngIf="row.isOld"></old-measure-icon>
  <mat-chip-listbox [selectable]="false">
    <button [id]="rowId + '-amc-button'" (click)="onAmcSelected(row)">
      <mat-chip-option color="primary" [attr.data-mode]="row.amc.mode">
        {{ "component.measuresTable.amcMode." + row.amc.mode | translate }}
      </mat-chip-option>
    </button>
  </mat-chip-listbox>
  <ng-container *ngTemplateOutlet="row.isAmcSync ? syncIcon : desyncIcon">
  </ng-container>
</div>
<div class="download-all-button" *ngIf="mode === 'raw'">
  <button
    [id]="rowId + '-download-all-raw-button'"
    mat-mini-fab
    color="primary"
    (click)="onDownloadAllRaws(row.requestedDate)"
    [matTooltip]="'component.measuresTable.downloadRaw' | translate"
    [attr.aria-label]="'component.measuresTable.downloadRaw' | translate"
  >
    <icon-download></icon-download>
  </button>
  <span>{{ "component.measuresTable.downloadAllRaws" | translate }}</span>
</div>

<!-- template: sync icon -->
<ng-template #syncIcon>
  <mat-icon
    [matTooltip]="'component.measuresTable.amcSyncTooltip.sync' | translate"
  >
    check
  </mat-icon>
</ng-template>

<!-- template: desync icon -->
<ng-template #desyncIcon>
  <ng-container *ngxPermissionsOnly="'structure:device:read'">
    <a
      [id]="rowId + '-amc-desync-link'"
      [routerLink]="'/structure/' + structureId + '/details/sensors'"
    >
      <mat-icon
        class="warning-icon"
        [matTooltip]="
          'component.measuresTable.amcSyncTooltip.desyncWithLink' | translate
        "
      >
        warning
      </mat-icon>
    </a>
  </ng-container>
  <ng-container *ngxPermissionsExcept="'structure:device:read'">
    <mat-icon
      class="warning-icon"
      [matTooltip]="'component.measuresTable.amcSyncTooltip.desync' | translate"
    >
      warning
    </mat-icon>
  </ng-container>
</ng-template>
