<div
  class="processing-requested-status-container"
  [class]="iconContainerClass"
  *ngIf="row.lastProcessingRequest !== false"
>
  <div
    class="container-icon"
    [matTooltip]="
      row.lastProcessingRequest?.creationDate &&
      !!row.receivedRawMeasurePointNumbers.length
        ? ('common.dateUTC'
          | translate
            : {
                date:
                  row.lastProcessingRequest!.creationDate
                  | localizedDate: 'short' : 'UTC'
              })
        : undefined
    "
  >
    <mat-icon class="status-icon">{{ icon }}</mat-icon>
  </div>

  <div>{{ statusTranslationKey | translate }}</div>
</div>
