<label>
  <div class="label-content">
    <event-category-icon [category]="category.name"></event-category-icon>
    <div class="label-text">
      {{ "component.eventTypeSelect.category." + category.name | translate }}
    </div>
  </div>
  <mat-checkbox
    color="primary"
    (change)="onToggle()"
    [checked]="isChecked$ | async"
    [indeterminate]="isIndeterminate$ | async"
    labelPosition="before"
  ></mat-checkbox>
</label>
