import {
  DeviceSohMeasuringPoint,
  DeviceType,
  GatewaySOH,
  NodeSOH,
  SOH,
  Structure,
  StructureDeploymentReportDevice,
  StructureDevice,
} from "../../models/structure.model";
import { hasOwnProperty } from "../utils/utils";

export function mapDeviceSohToDeviceSohMeasuringPoint(
  structure: Structure,
  deviceType: DeviceType,
): DeviceSohMeasuringPoint[] {
  if (structure) {
    const devicesSoh: SOH[] = getDevicesSoh();
    const devicesFromReport: StructureDeploymentReportDevice[] =
      getDevicesFromReport();

    const devices: DeviceSohMeasuringPoint[] = getDevices().map((device) => {
      let deviceSohMeasuringPoint: DeviceSohMeasuringPoint = {
        serialNumber: device.serialNumber,
        lastSeen: device.lastSeen,
      };

      // deployment report
      const deviceFromReport = devicesFromReport.find(
        (deviceReport) => deviceReport.serialNumber === device.serialNumber,
      );
      if (deviceFromReport !== undefined) {
        deviceSohMeasuringPoint.measuringPoint =
          deviceFromReport.measuringPoint;
      }

      // soh
      let deviceSoh = devicesSoh.find(
        (sohDevice) => sohDevice.serialNumber === device.serialNumber,
      );
      if (deviceSoh !== undefined) {
        deviceSoh = sohUnsetValueByStatus(deviceSoh);
        deviceSohMeasuringPoint = {
          ...deviceSohMeasuringPoint,
          ...deviceSoh,
        };
      }
      return deviceSohMeasuringPoint;
    });
    return devices;
  } else {
    return [];
  }

  function sohUnsetValueByStatus(soh: SOH): SOH {
    const propertiesToCheck: Array<keyof NodeSOH | keyof GatewaySOH> = [
      "battery",
      "lora",
      "gnss",
      "wifi",
      "ltem",
      "tilt",
      "temperature",
      "humidity",
    ];
    propertiesToCheck.forEach((property) => {
      if (hasOwnProperty(soh, property)) {
        const value = soh[property] as { status: number };
        if (value.status === 1) {
          return;
        }
        if (hasOwnProperty(value, "chargePercent")) {
          value.chargePercent = undefined;
        }
        if (hasOwnProperty(value, "rssi")) {
          value.rssi = undefined;
        }
        if (hasOwnProperty(value, "quality")) {
          value.quality = undefined;
        }
        if (hasOwnProperty(value, "pitch")) {
          value.pitch = undefined;
        }
        if (hasOwnProperty(value, "roll")) {
          value.roll = undefined;
        }
        if (hasOwnProperty(value, "value")) {
          value.value = undefined;
        }
      }
    });
    return soh;
  }

  function getDevices(): StructureDevice[] {
    return (
      structure.devices?.filter((device) => device.type === deviceType) ?? []
    );
  }

  function getDevicesSoh() {
    let devicesSoh: SOH[];
    if (deviceType === "gateway") {
      devicesSoh = structure.sohGateway;
    } else {
      devicesSoh = structure.sohNode;
    }
    return devicesSoh;
  }

  function getDevicesFromReport(): StructureDeploymentReportDevice[] {
    return structure.deploymentReport && structure.deploymentReport.devices
      ? structure.deploymentReport.devices
      : [];
  }
}
