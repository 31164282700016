<side-panel (closePanelEvent)="closePanel()">
  <div class="title">{{ title }}</div>

  <a
    matRipple
    id="side-panel-download-button"
    *ngIf="downloadedFileName"
    [download]="downloadedFileName + '.json'"
    [href]="getDownloadData()"
    ><mat-icon>save</mat-icon></a
  >

  <button id="side-panel-json-close-button" (click)="closePanel()">
    <mat-icon>close</mat-icon>
  </button>

  <pre
    id="side-panel-json-code-content"
    class="side-panel-body"
  ><code [highlight]="jsonBody | json" [languages]="['json']"></code></pre>
</side-panel>
