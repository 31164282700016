import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: "filter-structure-type",
  templateUrl: "./filter-structure-type.component.html",
  styleUrls: ["./filter-structure-type.component.scss"],
})
export class FilterStructureTypeComponent implements AfterViewInit {
  @Input() public form!: FormControl<string[]>;

  @ViewChild("select") select!: MatSelect;
  public types = ["bridge", "building"];
  public allSelected = false;

  public ngAfterViewInit() {
    this.optionClick();
  }

  public toggleAllSelection() {
    if (this.allSelected) {
      this.form.setValue(this.select.options.map((option) => option.value));
    } else {
      this.form.setValue([]);
    }
  }

  public optionClick() {
    this.allSelected = !this.select.options.some((item) => !item.selected);
  }
}
