import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import prettyBytes from "pretty-bytes";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { HashId, Structure } from "src/app/models/structure.model";
import { BusinessService } from "src/app/services/network/business.service";

@Component({
  selector: "structure-traffic",
  templateUrl: "./structure-traffic.component.html",
  styleUrls: ["./structure-traffic.component.scss"],
})
export class StructureTrafficComponent implements OnInit, OnDestroy {
  public structureId: HashId = "";
  public currentStructure$!: Observable<Structure>;

  private subStructureGet = new Subscription();

  public constructor(
    private businessService: BusinessService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    if (this.route.parent && this.route.parent.parent) {
      this.route.parent.parent.params.pipe(take(1)).subscribe((params) => {
        this.structureId = params["id"];
        this.subStructureGet = this.businessService
          .getStructure(this.structureId)
          .subscribe();
      });
    }
    this.currentStructure$ = this.businessService.structureStream();
  }

  public ngOnDestroy() {
    this.subStructureGet.unsubscribe();
  }

  public getPrettyBytes(bytes: number) {
    const locale = this.translate.currentLang.split("-")[0];
    return prettyBytes(bytes, { locale });
  }
}
