import { Component, Input } from "@angular/core";
import { DisplayedFile } from "../../models/file.model";
import { DownloadFileService } from "../../services/utils/download-file.service";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent {
  @Input() public displayedFile!: DisplayedFile;

  public constructor(private downloadFileService: DownloadFileService) {}

  public async downloadFile() {
    const filename = replaceInvalidCharacters(this.displayedFile.name);
    this.downloadFileService.downloadFile(
      this.displayedFile.blob,
      "application/octet-stream",
      filename,
    );
  }
}
function replaceInvalidCharacters(filename: string): string {
  let str = filename.replaceAll("é", "e");
  str = str.replaceAll(/["'`´‘’“”]/g, " ");
  return str;
}
