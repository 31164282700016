<div class="outer-content">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <div class="tables-panel">
    <div class="gateway-table">
      <mat-table
        id="structure-sensors-table-gateway-table"
        #table
        [dataSource]="gatewayList"
        multiTemplateDataRows
        class="soh-gateway-table"
      >
        <ng-container cdkColumnDef="serialNumber">
          <mat-header-cell *cdkHeaderCellDef
            >{{ "common.gateway" | translate }}
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="serial-number">
            <equipment-status
              [listAlarms]="row.soh.listAlarms ?? []"
            ></equipment-status>
            {{ row.soh.serialNumber }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="measuringPoint">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.point" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{ "common.synthesis" | translate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="LTEM">
          <mat-header-cell *cdkHeaderCellDef>LTEM</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{
              row.soh.ltem && row.soh.ltem.rssi !== undefined
                ? ("component.structureSensorsTable.dbm"
                  | translate: { value: row.soh.ltem.rssi })
                : "-"
            }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="GNSS">
          <mat-header-cell *cdkHeaderCellDef>GNSS</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{ row.soh.gnss?.quality ?? "-" }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.status" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{
              "component.structureSensorsTable.status." + row.status | translate
            }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="amcId">
          <mat-header-cell *cdkHeaderCellDef>{{
            "component.structureSensorsTable.syncConfiguration" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div
              *ngIf="
                row.soh.amcId !== undefined && row.soh.amcId === amcId;
                else notSyncConfig
              "
            >
              <mat-icon>sync</mat-icon>
              {{ "component.structureSensorsTable.sync" | translate }}
            </div>
            <ng-template #notSyncConfig>
              <div class="not-sync-config">
                <mat-icon>sync_disabled</mat-icon>
                {{ "component.structureSensorsTable.notSync" | translate }}
              </div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="battery">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.battery" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div
              *ngIf="
                row.soh.battery !== undefined &&
                  row.soh.battery.chargePercent !== undefined;
                else elseBatteryBlock
              "
            >
              <div class="remaining">{{ row.soh.battery.chargePercent }}%</div>
            </div>
            <ng-template #elseBatteryBlock>
              <div class="remaining">-</div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="lastSeen">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.lastSeen" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="last-seen">{{ row.soh.lastSeen | timeAgo }}</div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <mat-header-cell class="actions-header" *cdkHeaderCellDef>{{
            "common.actions" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="actions">
              <button
                mat-mini-fab
                color="primary"
                (click)="updateDevice(row, 'gateway', $event)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *cdkHeaderRowDef="sohGatewayDataColumns"
          class="header"
        ></mat-header-row>
        <mat-row
          *cdkRowDef="let row; columns: sohGatewayDataColumns"
          [id]="'structure-sensors-table-gateway-row-' + row.soh.serialNumber"
          class="element-row"
          [class.selected-row]="selectedSoh === row.soh"
          (click)="selectSoh(row.soh)"
        >
        </mat-row>
      </mat-table>
    </div>

    <div class="node-table">
      <mat-table
        id="structure-sensors-table-soh-table"
        #table
        [dataSource]="nodeList"
        multiTemplateDataRows
        class="soh-table"
      >
        <ng-container cdkColumnDef="serialNumber">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.node" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="serial-number">
            <equipment-status
              [listAlarms]="row.soh.listAlarms ?? []"
            ></equipment-status>
            {{ row.soh.serialNumber }}</mat-cell
          >
        </ng-container>

        <ng-container cdkColumnDef="measuringPoint">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.point" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{
              row.soh.measuringPoint
                ? ("common.measure" | translate) + " " + row.soh.measuringPoint
                : "-"
            }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="WIFI">
          <mat-header-cell *cdkHeaderCellDef>WIFI</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{
              row.soh.wifi && row.soh.wifi.rssi !== undefined
                ? ("component.structureSensorsTable.dbm"
                  | translate: { value: row.soh.wifi.rssi })
                : "-"
            }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="status">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.status" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            {{
              "component.structureSensorsTable.status." + row.status | translate
            }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="amcId">
          <mat-header-cell *cdkHeaderCellDef>{{
            "component.structureSensorsTable.syncConfiguration" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div
              *ngIf="
                row.soh.amcId !== undefined && row.soh.amcId === amcId;
                else notSyncConfig
              "
            >
              <mat-icon>sync</mat-icon>
              {{ "component.structureSensorsTable.sync" | translate }}
            </div>
            <ng-template #notSyncConfig>
              <div class="not-sync-config">
                <mat-icon>sync_disabled</mat-icon>
                {{ "component.structureSensorsTable.notSync" | translate }}
              </div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="battery">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.battery" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div
              *ngIf="
                row.soh.battery !== undefined &&
                  row.soh.battery.chargePercent !== undefined;
                else elseBatteryBlock2
              "
            >
              <div class="chargePercent">
                {{ row.soh.battery.chargePercent }}%
              </div>
            </div>
            <ng-template #elseBatteryBlock2>
              <div class="chargePercent">-</div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="lastSeen">
          <mat-header-cell *cdkHeaderCellDef>{{
            "common.lastSeen" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="last-seen">{{ row.soh.lastSeen | timeAgo }}</div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <mat-header-cell class="actions-header" *cdkHeaderCellDef>{{
            "common.actions" | translate
          }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="actions">
              <button
                mat-mini-fab
                color="accent"
                (click)="updateDevice(row, 'node', $event)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *cdkHeaderRowDef="sohDataColumns"
          class="header"
        ></mat-header-row>
        <mat-row
          *cdkRowDef="let row; columns: sohDataColumns"
          [id]="'structure-sensors-table-soh-row-' + row.soh.serialNumber"
          class="element-row"
          [class.selected-row]="selectedSoh === row.soh"
          (click)="selectSoh(row.soh)"
        >
        </mat-row>
      </mat-table>
    </div>
  </div>

  <side-panel-json
    *ngIf="selectedSoh"
    [title]="selectedSoh.serialNumber"
    [jsonBody]="selectedSoh"
    (closePanelEvent)="closePanel()"
    [downloadedFileName]="selectedSoh.serialNumber"
    class="side-panel"
  ></side-panel-json>
</div>
