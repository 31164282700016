import { Component, Input, OnChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  Category,
  RemarkableEventType,
} from "../../models/remarkable-event.model";

@Component({
  selector: "event-category-checkbox",
  templateUrl: "event-category-checkbox.component.html",
  styleUrls: ["event-category-checkbox.component.scss"],
})
export class EventCategoryCheckboxComponent implements OnChanges {
  @Input() public form!: FormControl<RemarkableEventType[]>;
  @Input() public category!: Category;

  public isChecked$!: Observable<boolean>;
  public isIndeterminate$!: Observable<boolean>;

  public ngOnChanges() {
    this.isChecked$ = this.form.valueChanges.pipe(
      map(() => this.allCategorySelected()),
    );
    this.isIndeterminate$ = this.form.valueChanges.pipe(
      map(
        () => !this.allCategorySelected() && this.someCategoryTypesSelected(),
      ),
    );
  }

  public onToggle() {
    const typesFromOtherCategories = this.form.value.filter(
      (type) => !this.category.types.includes(type),
    );
    if (this.allCategorySelected()) {
      this.form.patchValue(typesFromOtherCategories);
    } else {
      this.form.patchValue(
        typesFromOtherCategories.concat(this.category.types),
      );
    }
    this.form.markAsDirty();
  }

  private allCategorySelected() {
    return this.category.types.every((typeInCategory) =>
      this.form.value.includes(typeInCategory),
    );
  }

  private someCategoryTypesSelected() {
    return this.category.types.some((typeInCategory) =>
      this.form.value.includes(typeInCategory),
    );
  }
}
