<div class="map-dialog">
  <h2 mat-dialog-title>{{ name }}</h2>
  <button
    id="map-dialog-close-button"
    class="close-button"
    mat-icon-button
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div leaflet [leafletOptions]="mapOptions" class="map">
      <div [leafletLayer]="addressMarker"></div>
    </div>
  </mat-dialog-content>
</div>
