import { Injectable } from "@angular/core";
import { groupBy, matches, orderBy } from "lodash";
import { Observable, ReplaySubject, forkJoin, from, of } from "rxjs";
import { concatMap, map, mergeMap, tap, toArray } from "rxjs/operators";
import { Amc } from "../../models/amc.model";
import { MeasureItem } from "../../models/measure.model";
import { Pagination } from "../../models/pagination.model";
import { ProcessingRequest } from "../../models/processing-request.model";
import {
  MeasureRow,
  NotReceivedRow,
  ReceivedRow,
  RequestedDateRow,
  RowsGroup,
  StructureMeasuresPageStore,
  isReceivedRow,
  isRequestedDateRow,
} from "../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { AuthService } from "../../services/auth/auth.service";
import {
  BusinessService,
  GetMeasuresFilter,
} from "../../services/network/business.service";
import { ProcessingService } from "../../services/network/processing.service";
import { isOnDemandAmcMode } from "../../services/utils/amc-utils";

@Injectable()
export class StructureMeasureRowsManager {
  private fetchRequests = new ReplaySubject<FetchRequestOptions>(1);
  private hasMoreMeasures = true;
  private amcCache: Amc[] = [];

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private businessService: BusinessService,
    private processingService: ProcessingService,
    private authService: AuthService,
  ) {}

  public getFetchingObservable(): Observable<unknown> {
    return this.fetchRequests.pipe(
      // concatMap avoid fetching in parallel, that would cause bugs
      concatMap((options) => this.doFetchMoreMeasures(options)),
    );
  }

  public fetchMoreMeasures(
    options: FetchRequestOptions = { resetRows: false },
  ) {
    this.fetchRequests.next(options);
  }

  public refilterRows() {
    const dates = this.pageStore
      .getRows()
      .filter(isRequestedDateRow)
      .map((row) => row.requestedDate.getTime());
    const newRows: MeasureRow[] = [];
    for (const date of dates) {
      const rows = this.pageStore
        .getRows()
        .filter((row) => row.requestedDate.getTime() === date);
      newRows.push(...this.updateRowsGroupFilters(rows as RowsGroup));
    }
    this.pageStore.updateRows(newRows);
  }

  public addProcessingRequest(processingRequest: ProcessingRequest) {
    this.updateRequestedDateRow(processingRequest.requestedDate, {
      lastProcessingRequest: processingRequest,
    });
  }

  public setRawAuthorized(rows: ReceivedRow[]) {
    const requestedTime = rows[0].requestedDate.getTime();
    const measurePointNumbers = rows.map((row) => row.measurePointNumber);
    const shouldUpdateRow = (row: ReceivedRow) =>
      row.requestedDate.getTime() === requestedTime &&
      measurePointNumbers.includes(row.measurePointNumber);
    // we dont need to update filters properties (displayInRaw, rowspans, etc)
    // because this property doesnt interfer with filters
    this.pageStore.updateRows(
      this.pageStore.getRows().map((row) =>
        isReceivedRow(row) && shouldUpdateRow(row)
          ? updateObject(row, {
              sensors: {
                ...row.sensors,
                raw: {
                  ...row.sensors.raw!,
                  customerDownloadAuthorization: true,
                },
              },
            })
          : row,
      ),
    );
  }

  private doFetchMoreMeasures(options: FetchRequestOptions) {
    if (options.resetRows) {
      this.pageStore.updateRows([]);
      this.hasMoreMeasures = true;
    }
    if (!this.hasMoreMeasures) {
      return of(0);
    }
    const { fromRequestedDate, toRequestedDate } = this.getDateFilter();
    return this.fetchMeasuresWithAtLeastOneCompleteDate({
      structureId: this.pageStore.getStructureId(),
      fromRequestedDate: fromRequestedDate.toISOString(),
      toRequestedDate: toRequestedDate.toISOString(),
      limit: 100,
    }).pipe(
      tap((result) => {
        this.hasMoreMeasures = result.total > result.count;
      }),
      map((result) =>
        Object.values(
          // group by requestedDate (create an array of arrays)
          groupBy(result.data, (measure) => measure.requestedDate.getTime()),
        ),
      ),
      // if there are more measures, skip the last date
      // we probably didnt fetch all its measures due to the "limit" param
      map((measuresByDate) =>
        this.hasMoreMeasures ? measuresByDate.slice(0, -1) : measuresByDate,
      ),
      // emit one value by date
      mergeMap((measuresByDate) => from(measuresByDate)),
      concatMap((measures) =>
        this.getExpectedPoints(measures).pipe(
          map((expectedPoints) => ({
            measures,
            expectedPoints,
          })),
        ),
      ),
      map(({ measures, expectedPoints }) => {
        const requestedDateRow = this.createRequestedDateRow(measures);
        const received = measures.map((measure) =>
          this.createReceivedRow(measure),
        );
        const missing = this.createNotReceivedRows(measures, expectedPoints);
        const measureRows = orderBy(
          [...received, ...missing],
          ["measurePointNumber"],
        );

        return [requestedDateRow, ...measureRows] as RowsGroup;
      }),
      tap((rows) => {
        // refresh display as fast as possible, we will fetch additional stuff after
        this.pageStore.updateRows(this.filterAndAppendRowsGroup(rows));
      }),
      map((rows) => rows[0].requestedDate),
      toArray(),
      mergeMap((dates: Date[]) => {
        const additionalUpdates = [];
        if (dates.length > 0) {
          const startDate = dates.at(-1)!;
          const endDate = dates[0];
          const scopes = this.authService.getScopes();
          if (scopes.includes("processingRequest:read")) {
            additionalUpdates.push(
              this.fetchProcessingRequests(startDate, endDate, dates),
            );
          }
          if (scopes.includes("structure:measure:ageless:read")) {
            additionalUpdates.push(this.fetchIsOld(startDate, endDate));
          }
        }
        // do in parallel
        return forkJoin(additionalUpdates);
      }),
    );
  }

  private getDateFilter() {
    const fromRequestedDate = new Date(
      this.pageStore.getFilterForm().getRawValue().requestedDate.start ||
        "2000-01-01",
    );
    const filterEnd = new Date(
      this.pageStore.getFilterForm().getRawValue().requestedDate.end ||
        "3000-01-01",
    );
    const toRequestedDate = this.getMinFromFilterAndAlreadyFetched(filterEnd);
    return { fromRequestedDate, toRequestedDate };
  }

  private getMinFromFilterAndAlreadyFetched(filterEnd: Date) {
    const fetchedDateRows = this.pageStore.getRows().filter(isRequestedDateRow);
    const lastFetchedMeasureDate = fetchedDateRows.length
      ? new Date(fetchedDateRows.at(-1)!.requestedDate.getTime() - 1)
      : new Date("3000-01-01");
    return new Date(
      Math.min(filterEnd.getTime(), lastFetchedMeasureDate.getTime()),
    );
  }

  /**
   * the goal of this function is to handle the case where a requested date has more than 100 measures.
   * we want to get all of its measures
   */
  private fetchMeasuresWithAtLeastOneCompleteDate(
    filters: GetMeasuresFilter & { limit: number },
  ): Observable<Pagination<MeasureItem>> {
    return this.businessService.getMeasures(filters).pipe(
      mergeMap((result) => {
        if (result.data.length === 0) {
          // no measures found
          return of(result);
        }
        if (
          result.data.at(0)!.requestedDate.getTime() !==
          result.data.at(-1)!.requestedDate.getTime()
        ) {
          // measures from different dates found => the first date is complete
          return of(result);
        }
        if (result.total === result.count) {
          // all measures found
          return of(result);
        }
        // all measures are from the same date and there are more measures:
        // we dont want to return a partial date, augment limit
        return this.fetchMeasuresWithAtLeastOneCompleteDate({
          ...filters,
          limit: filters.limit * 2,
        });
      }),
    );
  }

  private getExpectedPoints(measures: MeasureItem[]): Observable<number[]> {
    const amcRef = measures[0].amc;
    if (amcRef?.mode === "weekly") {
      return of([
        0, // synsthesis point
        ...this.pageStore.structure.measuringPoints.map((point) => point.index),
      ]);
    } else {
      return this.getAmc(amcRef.id).pipe(
        map((amc) => amc.modes.find(isOnDemandAmcMode)!.measuringPoints),
      );
    }
  }

  private getAmc(id: number) {
    const fromCache = this.amcCache.find((amc) => amc.id === id);
    if (fromCache) {
      return of(fromCache);
    }
    return this.businessService
      .getAmc(this.pageStore.getStructureId(), id)
      .pipe(
        tap((amc) => {
          this.amcCache.push(amc);
        }),
      );
  }

  private createRequestedDateRow(measures: MeasureItem[]): RequestedDateRow {
    return {
      isRequestedDate: true,
      requestedDate: measures[0].requestedDate,
      amc: measures[0].amc,
      isAmcSync: measures.every(
        (m) =>
          m.amc.id === measures[0].amc.id &&
          m.amc.mode === measures[0].amc.mode,
      ),
      allMeasures: measures,
      receivedRawMeasurePointNumbers: measures
        .filter((m) => m.sensors.raw)
        .map((m) => m.measurePointNumber),
      lastProcessingRequest: false,
      displayedInProcessed: false,
      displayedInRaw: false,
      rowspanProcessed: 0,
      rowspanRaw: 0,
    };
  }

  private createReceivedRow(measure: MeasureItem): ReceivedRow {
    return {
      ...measure,
      active: this.getMeasurePointActiveStatus(measure.measurePointNumber),
      isReceived: true,
      displayedInProcessed: false,
      displayedInRaw: false,
    };
  }

  private getMeasurePointActiveStatus(measurePointNumber: number): boolean {
    return measurePointNumber === 0
      ? true
      : this.pageStore.structure.measuringPoints.find(
          (m) => m.index === measurePointNumber,
        )!.active;
  }

  private createNotReceivedRows(
    measures: MeasureItem[],
    expectedPoints: number[],
  ): NotReceivedRow[] {
    const missing = expectedPoints.filter(
      (measuringPoint) =>
        !measures.some(
          (_measure) => _measure.measurePointNumber === measuringPoint,
        ),
    );

    return missing.map((measurePointNumber) => ({
      isReceived: false,
      requestedDate: measures[0].requestedDate,
      measurePointNumber,
      active: this.getMeasurePointActiveStatus(measurePointNumber),
      displayedInProcessed: false,
      displayedInRaw: false,
    }));
  }

  private filterAndAppendRowsGroup(rows: RowsGroup): MeasureRow[] {
    const newRows = this.updateRowsGroupFilters(rows);
    return this.pageStore.getRows().concat(newRows);
  }

  private updateRowsGroupFilters(rows: RowsGroup): RowsGroup {
    const [dateRow, ...measureRows] = rows;
    const hiddenGroup: RowsGroup = [
      updateObject(dateRow, {
        displayedInProcessed: false,
        displayedInRaw: false,
        rowspanProcessed: 0,
        rowspanRaw: 0,
      }),
      ...measureRows.map((row) =>
        updateObject(row, {
          displayedInProcessed: false,
          displayedInRaw: false,
        }),
      ),
    ];

    if (!this.filterEntireGroup(rows)) {
      return hiddenGroup;
    }

    const measureRowsWithRawFilter = measureRows.map((row) =>
      this.updateRawFilter(row),
    );

    if (!measureRowsWithRawFilter.some((row) => row.displayedInRaw)) {
      // no row matches the filter, we dont keep the group
      // we dont need to check the "displayedInProcessed" property: if a row
      // is not visible in raw, it wont be visible in processed
      return hiddenGroup;
    }

    const newMeasureRows = measureRowsWithRawFilter.map((row) =>
      this.updateProcessedFilter(row),
    );

    const newDateRow = updateObject(dateRow, {
      displayedInProcessed: true,
      rowspanProcessed:
        newMeasureRows.filter((row) => row.displayedInProcessed).length + 1,
      displayedInRaw: true,
      rowspanRaw: newMeasureRows.filter((row) => row.displayedInRaw).length + 1,
    });

    return [newDateRow, ...newMeasureRows];
  }

  private filterEntireGroup(rows: RowsGroup) {
    const formValues = this.pageStore.getFilterForm().getRawValue();
    if (
      formValues.amcMode.length &&
      !formValues.amcMode.includes(rows[0].amc.mode)
    ) {
      return false;
    }

    if (
      formValues.isAmcSync.length &&
      !formValues.isAmcSync.includes(rows[0].isAmcSync)
    ) {
      return false;
    }

    if (formValues.processingRequestStatuses.length) {
      if (rows[0].lastProcessingRequest === false) {
        return false;
      }
      const lastProcessingRequestCause = rows[0].lastProcessingRequest?.cause;
      if (
        lastProcessingRequestCause === undefined &&
        !formValues.processingRequestStatuses.includes("waiting")
      ) {
        return false;
      }
      if (
        lastProcessingRequestCause !== undefined &&
        !formValues.processingRequestStatuses.includes(
          lastProcessingRequestCause,
        )
      ) {
        return false;
      }
    }

    return true;
  }

  private updateRawFilter(row: ReceivedRow | NotReceivedRow) {
    const formValues = this.pageStore.getFilterForm().getRawValue();
    if (
      formValues.isMeasurePointActive.length &&
      !formValues.isMeasurePointActive.includes(row.active)
    ) {
      return updateObject(row, { displayedInRaw: false });
    }

    if (
      formValues.measurePoints.length &&
      !formValues.measurePoints.includes(row.measurePointNumber)
    ) {
      return updateObject(row, { displayedInRaw: false });
    }

    return updateObject(row, { displayedInRaw: true });
  }

  private updateProcessedFilter(row: ReceivedRow | NotReceivedRow) {
    const formValues = this.pageStore.getFilterForm().getRawValue();
    if (
      formValues.measurePoints.length &&
      !formValues.measurePoints.includes(row.measurePointNumber)
    ) {
      return updateObject(row, { displayedInProcessed: false });
    }

    if (
      row.measurePointNumber !== 0 &&
      (!isReceivedRow(row) || !row.sensors?.openSensors.length)
    ) {
      return updateObject(row, { displayedInProcessed: false });
    }

    return updateObject(row, { displayedInProcessed: true });
  }

  private fetchProcessingRequests(
    fromRequestedDate: Date | undefined,
    toRequestedDate: Date | undefined,
    fetchedDates: Date[],
  ) {
    return this.processingService
      .getProcessingRequests({
        structureId: this.pageStore.getStructureId(),
        fromRequestedDate: fromRequestedDate?.toISOString(),
        toRequestedDate: toRequestedDate?.toISOString(),
      })
      .pipe(
        tap((result) => {
          for (const date of fetchedDates) {
            const lastProcessingRequest = result.data.find(
              (request) => request.requestedDate.getTime() === date.getTime(),
            );
            this.updateRequestedDateRow(date, {
              lastProcessingRequest,
            });
          }
        }),
      );
  }

  private fetchIsOld(startDate: Date, endDate: Date) {
    return this.businessService
      .getRequestedDateList({
        structureId: this.pageStore.getStructureId(),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
      .pipe(
        mergeMap((result) => from(result.data)),
        tap((date) => {
          if (date.isOld) {
            this.updateRequestedDateRow(date.requestedDate, {
              isOld: true,
            });
          }
        }),
      );
  }

  private updateRequestedDateRow(
    requestedDate: Date,
    update: Partial<RequestedDateRow>,
  ) {
    const oldRowsOfSameDate = this.pageStore
      .getRows()
      .filter((row) => row.requestedDate.getTime() === requestedDate.getTime());
    if (oldRowsOfSameDate.length) {
      const [dateRow, ...measureRows] = oldRowsOfSameDate as RowsGroup;
      const newGroup = this.updateRowsGroupFilters([
        { ...dateRow, ...update },
        ...measureRows,
      ]);
      const [newDateRow, ...newMeasureRows] = newGroup;

      this.pageStore.updateRows(
        this.pageStore
          .getRows()
          .map((row) =>
            this.updateRowIfChanged(
              row,
              oldRowsOfSameDate,
              newDateRow,
              newMeasureRows,
            ),
          ),
      );
    }
  }

  private updateRowIfChanged(
    row: MeasureRow,
    oldRowsOfSameDate: MeasureRow[],
    newDateRow: RequestedDateRow,
    newMeasureRows: Array<ReceivedRow | NotReceivedRow>,
  ) {
    if (!oldRowsOfSameDate.includes(row)) {
      return row;
    }
    if (isRequestedDateRow(row)) {
      return newDateRow;
    }
    return newMeasureRows.find(
      (newRow) => newRow.measurePointNumber === row.measurePointNumber,
    )!;
  }
}

// create a new object only if the update actually changes the object
// the goal is to avoid useless re-renders by angular, same object => no re-render
function updateObject<T>(obj: T, update: Partial<T>): T {
  return matches(update)(obj) ? obj : { ...obj, ...update };
}

interface FetchRequestOptions {
  resetRows: boolean;
}
