<div class="outer-content">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <form [formGroup]="form">
    <div class="body-content">
      <div class="header-content">
        <span
          id="structure-approval-previous-button"
          class="blue-previous"
          (click)="backToPreviousPage()"
          ><mat-icon>arrow_backward</mat-icon></span
        >
        <span class="header-title"
          >{{ 'page.structure.approval.title' | translate: {structureName:
          (currentStructure$ | async)?.generalInfo.name } }}</span
        >
      </div>
      <div class="billing-form">
        <structure-billing-form
          [form]="form"
          [readMode]="false"
          [mode]="'approving'"
          [formHeader]="'page.structure.approval.checkCustomerBillingInformation' | translate"
        ></structure-billing-form>
      </div>
      <structure-sensors-table
        [structure]="structure$ | async"
      ></structure-sensors-table>
    </div>
    <button
      id="structure-approval-reject-button"
      mat-raised-button
      class="reject-button"
      (click)="disapproveStructure()"
      color="primary"
      [disabled]="isLoading$ | async"
    >
      {{ 'common.reject' | translate }}
    </button>
    <button
      id="structure-approval-approve-button"
      mat-raised-button
      class="approve-button"
      (click)="approveStructure()"
      color="primary"
      type="submit"
      [disabled]="(!form.valid) || (isLoading$ | async)"
    >
      {{ 'common.approve' | translate }}
    </button>
  </form>
</div>
