import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Structure } from "../../../../models/structure.model";
import { StructureMeasuresPageStore } from "./structure-measures-page.store";

@Component({
  selector: "structure-measures",
  templateUrl: "./structure-measures.component.html",
  styleUrls: ["./structure-measures.component.scss"],
})
export class StructureMeasuresComponent implements OnInit {
  public displayReady!: boolean;

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() {
    const structure = this.activatedRoute.snapshot.parent!.parent!.data
      .structure as Structure;
    this.displayReady =
      structure.generalInfo.status === "monitored" ||
      structure.generalInfo.status === "demobilized";
    this.pageStore.structure = structure;
  }
}
