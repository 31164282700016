<div
  [class.one-table]="displayedTables.length === 1"
  [class.two-tables]="displayedTables.length === 2"
  [class.three-tables]="displayedTables.length === 3"
  [class.has-small-table]="displayedTables.includes('activation')"
>
  <aside
    *ngIf="canSeeDevices && !readOnly"
    class="blue-card"
    id="number-nodes-unassigned"
  >
    <span>{{
      "component.measurePointTableFull.unassignedNodes" | translate
    }}</span>
    <span class="number-device">{{
      availableSerialNumbersCount$ | async
    }}</span>
  </aside>
  <header class="table-container mat-header-cell">
    <span *ngIf="displayedTables.includes('measurePoints')" class="large-table">
      {{
        "component.measurePointTableFull.setupInstructionMeasurePoints"
          | translate
      }}
    </span>
    <span
      *ngIf="displayedTables.includes('deploymentReport')"
      class="large-table"
    >
      {{
        (readOnly
          ? "component.measurePointTableFull.deploymentReport"
          : "component.measurePointTableFull.deploymentReportAssignPoints"
        ) | translate
      }}
    </span>
    <span
      *ngIf="displayedTables.includes('activation')"
      class="small-table activation-table"
    >
      {{ "component.measurePointTableFull.activation" | translate }}
    </span>
  </header>
  <section class="table-container">
    <mat-table
      *ngIf="displayedTables.includes('measurePoints')"
      id="measure-points-table-full-instruction"
      #table
      [dataSource]="structure.measuringPoints"
      multiTemplateDataRows
      class="large-table"
    >
      <ng-container matColumnDef="index">
        <mat-cell *matCellDef="let row">{{
          ("common.measure" | translate) + " " + row.index
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="comment">
        <mat-cell *matCellDef="let row">
          <textarea
            cdkTextareaAutosize
            [id]="'measure-points-table-instruction-comment-' + row.index"
            placeholder="{{ 'common.noComment' | translate }}"
            [readonly]="true"
            >{{ row.comment }}</textarea
          >
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: ['index', 'comment']"></mat-row>
    </mat-table>

    <form
      *ngIf="displayedTables.includes('deploymentReport')"
      [formGroup]="deploymentReportForm"
      class="large-table"
    >
      <mat-table
        id="measure-points-table-full-report"
        #table
        [dataSource]="deploymentReportForm.controls.devices.controls"
        multiTemplateDataRows
        class="deployment-report-table"
        formArrayName="devices"
      >
        <ng-container matColumnDef="serialNumber">
          <mat-cell
            *matCellDef="let row; let i = dataIndex"
            [formGroupName]="i"
            [ngClass]="{ filled: row.value.serialNumber }"
          >
            <input
              [id]="
                'measure-points-table-serial-number-input-' +
                row.value.measuringPoint
              "
              matInput
              placeholder="{{ 'common.enterSerialNumber' | translate }}"
              formControlName="serialNumber"
              [matAutocomplete]="auto"
              [readonly]="readOnly"
              [matTooltip]="row.value.serialNumber"
            />
            <mat-icon
              [@fadeInOut]
              *ngIf="isSerialNumberCorrect(row.value.serialNumber)"
              >check_circle</mat-icon
            >
            <mat-autocomplete #auto="matAutocomplete" panelWidth="auto">
              <mat-option
                *ngFor="
                  let serialNumber of serialNumbersAutocompletes[i] | async
                "
                [value]="serialNumber"
              >
                {{ serialNumber }}
              </mat-option>
            </mat-autocomplete>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reportComment">
          <mat-cell
            *matCellDef="let row; let i = dataIndex"
            [formGroupName]="i"
          >
            <textarea
              cdkTextareaAutosize
              [id]="
                'measure-points-table-report-comment-' +
                row.value.measuringPoint
              "
              formControlName="reportComment"
              placeholder="{{
                (readOnly ? 'common.noComment' : 'common.aComment') | translate
              }}"
              [readonly]="readOnly"
            ></textarea>
          </mat-cell>
        </ng-container>

        <mat-row
          *matRowDef="let row; columns: ['serialNumber', 'reportComment']"
          class="regular-row"
        ></mat-row>
      </mat-table>
    </form>

    <mat-table
      *ngIf="displayedTables.includes('activation')"
      class="small-table activation-table"
      #table
      [dataSource]="structure.measuringPoints"
    >
      <ng-container matColumnDef="active">
        <mat-cell *matCellDef="let row">
          <measure-point-active-toggle
            [measurePoint]="row"
            [structure]="structure"
          ></measure-point-active-toggle>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: ['active']"></mat-row>
    </mat-table>
  </section>
</div>
