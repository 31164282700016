<div class="body-content">
  <loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
  <mat-horizontal-stepper linear #stepper>
    <mat-step
      [completed]="isDeploymentReportValid((deploymentReportForm$ | async).value, currentStructure$ | async)"
    >
      <div class="table-container">
        <measure-points-table-full
          [structure]="currentStructure$ | async"
          [deploymentReportForm]="deploymentReportForm$ | async"
        ></measure-points-table-full>
      </div>
      <div class="fixed-footer">
        <div
          class="footer-container"
          *ngIf="(deploymentReportForm$ | async).value as deploymentReport"
        >
          <button
            id="structure-finalization-save-button"
            class="dark-blue-button"
            mat-raised-button
            (click)="saveDeploymentReportDraft(deploymentReport)"
          >
            {{ 'structure.finalization.saveAsDraft' | translate }}
          </button>

          <button
            id="structure-finalization-confirm-button"
            mat-raised-button
            color="primary"
            [disabled]="!isDeploymentReportValid(deploymentReport, currentStructure$ | async)"
            matStepperNext
            (click)="saveDeploymentReportDraft(deploymentReport, false)"
          >
            {{ 'common.confirm' | translate }}
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <deployment-report-confirmation
        [deploymentReport]="{
          devices: (deploymentReportForm$ | async).value.devices, 
          gateways: gateways$ | async
        }"
        [devices]="(getCurrentStructure() | async).devices"
        (validate)="finalizeStructure($event)"
        (cancel)="goBack(stepper)"
      ></deployment-report-confirmation>
    </mat-step>
  </mat-horizontal-stepper>
</div>
