import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { StructureProcessingConfig } from "../../models/structure-processing-config.model";
import { AuthService } from "../../services/auth/auth.service";
import { ProcessingService } from "../../services/network/processing.service";

@Component({
  selector: "timeout-config",
  templateUrl: "./timeout-config.component.html",
  styleUrls: ["./timeout-config.component.scss"],
})
export class TimeoutConfigComponent implements OnInit {
  public form = new FormGroup({
    timeoutInHours: new FormBuilder().nonNullable.control<number>(7, [
      Validators.required,
      Validators.min(5),
      Validators.max(72),
    ]),
  });
  public savedConfig?: StructureProcessingConfig;
  // we cannot handle the "no timeout" case by just setting form.timeout = undefined
  // because it would trigger the toggle when the user empties the input
  // we need to have a separate variable
  public timeoutDefined = false;
  public loading$ = new BehaviorSubject(true);
  public filteredOptions$: Observable<number[]>;
  public hasUpdateRight: boolean;

  private structureId!: string;

  public constructor(
    private service: ProcessingService,
    private route: ActivatedRoute,
    authService: AuthService,
  ) {
    this.hasUpdateRight = authService
      .getScopes()
      .includes("structure:amc:update");
    // [5..72]
    const options = Array.from({ length: 73 - 5 }, (value, index) => index + 5);
    this.filteredOptions$ = this.form.controls.timeoutInHours.valueChanges.pipe(
      map((input) =>
        options.filter((option) =>
          String(option).startsWith(String(input ?? "")),
        ),
      ),
    );
  }

  public ngOnInit(): void {
    this.structureId = this.route.parent!.parent!.snapshot.paramMap.get("id")!;
    this.service
      .getStructureProcessingConfig(this.structureId)
      .subscribe((config) => {
        this.savedConfig = config;
        this.form.patchValue(config);
        this.timeoutDefined = !!config.timeoutInHours;
        this.loading$.next(false);
      });
  }

  public toggle() {
    this.timeoutDefined = !this.timeoutDefined;
    if (this.timeoutDefined) {
      this.form.patchValue({ timeoutInHours: 7 });
    }
    this.save();
  }

  public save() {
    this.loading$.next(true);
    this.service
      .updateStructureProcessingConfig(
        this.structureId,
        this.timeoutDefined ? this.form.getRawValue() : {},
      )
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        }),
      )
      .subscribe((response) => {
        this.savedConfig = response;
        this.form.markAsPristine();
      });
  }

  public cancel() {
    this.timeoutDefined = !!this.savedConfig!.timeoutInHours;
    this.form.reset(this.savedConfig);
  }
}
