import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { Observable, ReplaySubject } from "rxjs";
import { filter, finalize, mergeMap, tap } from "rxjs/operators";
import {
  PatchMeasurePointDTO,
  Structure,
  StructureMeasuringPoint,
} from "../../models/structure.model";
import { BusinessService } from "../../services/network/business.service";
import { MeasurePointActiveToggleActivateDialogComponent } from "./measure-point-active-toggle-activate-dialog.component";
import { MeasurePointActiveToggleDeactivateDialogComponent } from "./measure-point-active-toggle-deactivate-dialog.component";

@Component({
  selector: "measure-point-active-toggle",
  templateUrl: "./measure-point-active-toggle.component.html",
  styleUrls: ["./measure-point-active-toggle.component.scss"],
})
export class MeasurePointActiveToggleComponent {
  @Input() public measurePoint!: StructureMeasuringPoint;
  @Input() public structure!: Structure;

  public isLoading$ = new ReplaySubject(1);
  public permissions$: Observable<NgxPermissionsObject>;

  public constructor(
    private businessService: BusinessService,
    private dialog: MatDialog,
    permissionService: NgxPermissionsService,
  ) {
    this.permissions$ = permissionService.permissions$;
  }

  public onChange(event: MatSlideToggleChange) {
    event.source.checked = this.measurePoint.active; // dont toggle it yet
    this.dialog
      .open<unknown, number, PatchMeasurePointDTO>(
        this.measurePoint.active
          ? MeasurePointActiveToggleDeactivateDialogComponent
          : MeasurePointActiveToggleActivateDialogComponent,
        { data: this.measurePoint.index },
      )
      .afterClosed()
      .pipe(
        filter((dialogResult) => !!dialogResult),
        tap(() => {
          this.isLoading$.next(true);
        }),
        mergeMap((dialogResult) =>
          this.businessService.patchMeasurePoint(
            this.structure.generalInfo.id,
            this.measurePoint.index,
            dialogResult!,
          ),
        ),
        tap((response) => {
          this.measurePoint.active = response.active;
          this.measurePoint.deactivationReason = response.deactivationReason;
          this.measurePoint.deactivationComment = response.deactivationComment;
          this.measurePoint.deactivationDate = response.deactivationDate;
        }),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }
}
