import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  HashId,
  StructureCreationDTO,
  StructureCreationMeasurePoint,
  StructureDeploymentReportDevice,
} from "../../models/structure.model";

@Injectable({
  providedIn: "root",
})
export class DeploymentService {
  private serviceUrl = "api/v1/deployment";

  public constructor(private http: HttpClient) {}

  public registerStructure(
    structInfos: StructureCreationDTO,
    measuringPoints: StructureCreationMeasurePoint[],
  ) {
    return this.http.post<{ id: string }>(
      `${environment.apiUrl}/${this.serviceUrl}/structure`,
      {
        structureInfo: {
          name: structInfos.name,
          region: { id: structInfos.regionId },
          type: structInfos.type,
          businessType: structInfos.businessType,
          address: structInfos.address,
          location: structInfos.location,
          contact: structInfos.contact,
        },
        dayOfWeekToOversight: structInfos.dayOfWeekToOversight,
        hourOfDayToOversight: structInfos.hourOfDayToOversight,
        customerCode: structInfos.customerCode,
        purchaseOrder: structInfos.purchaseOrder,
        measuringPoints,
      },
    );
  }

  public uploadDeploymentFile(structureId: HashId, deploymentFile: Blob) {
    const formData = new FormData();
    formData.append("file", deploymentFile);
    return this.http.post(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentFile`,
      formData,
    );
  }

  public uploadLocationThumbnail(structureId: HashId, thumbnail: Blob) {
    const formData = new FormData();
    formData.append("file", thumbnail);
    return this.http.post(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/location/thumbnail`,
      formData,
    );
  }

  public finalizeStructure(
    structureId: HashId,
    devices: StructureDeploymentReportDevice[],
  ) {
    return this.http.post<null>(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentReport`,
      { devices },
    );
  }

  public saveDeploymentReportDraft(
    structureId: HashId,
    devices: StructureDeploymentReportDevice[],
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentReport/draft`,
        {
          devices,
        },
        { responseType: "text" },
      )
      .toPromise();
  }
  public getDeploymentReportDraft(structureId: HashId) {
    return this.http
      .get(
        `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentReport/draft`,
        { responseType: "json" },
      )
      .toPromise();
  }

  public validateStructure$(structureId: HashId) {
    return this.http.patch<null>(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentReport`,
      {},
    );
  }

  public rejectStructureDeploymentReport(structureId: HashId) {
    return this.http.delete<null>(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/deploymentReport`,
      {},
    );
  }

  public sendApprobation(
    structureId: HashId,
    structureBillingInformation: {
      customerCode: string;
      purchaseOrder: string;
    },
  ) {
    return this.http.post(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/approve`,
      structureBillingInformation,
      { responseType: "text" },
    );
  }

  public disapproveStructure(structureId: HashId) {
    return this.http.delete(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/approve`,
      { responseType: "text" },
    );
  }

  public updateDevice(
    structureId: HashId,
    serialNumber: string,
    propertiesToUpdate: {
      serialNumber: string;
      modemId?: string;
      comment: string;
    },
  ) {
    return this.http.patch<{ serialNumber: string }>(
      `${environment.apiUrl}/${this.serviceUrl}/structure/${structureId}/device/${serialNumber}`,
      propertiesToUpdate,
    );
  }
}
