<mat-table
  id="measure-points-table"
  #table
  [dataSource]="pointsTable"
  class="measures-table"
>
  <ng-container cdkColumnDef="name">
    <mat-header-cell *cdkHeaderCellDef>{{
      "common.measuringPoints" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{
      "common.measurePoint" | translate: { measurePointNumber: row.index }
    }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="comments" *ngIf="!readMode">
    <mat-header-cell *cdkHeaderCellDef>{{
      "common.setupInstruction" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row; let i = index">
      <mat-form-field
        [floatLabel]="'never'"
        class="input-comment"
        [ngClass]="{ 'input-comment-focus': i === currentIndexFocus }"
      >
        <mat-label>{{ "common.setupInstruction" | translate }}</mat-label>
        <textarea
          [id]="'measure-points-table-field-comment-' + row.index"
          matInput
          [value]="measurePointsCopy[i]?.comment"
          (blur)="saveCommentInModel(i, $event.target.value)"
          [autofocus]
          cdkTextareaAutosize
          *ngIf="i === 0"
          (focus)="setCurrentFocus(i)"
        ></textarea>
        <textarea
          [id]="'measure-points-table-field-comment-' + row.index"
          matInput
          [value]="measurePointsCopy[i]?.comment"
          (blur)="saveCommentInModel(i, $event.target.value)"
          *ngIf="i !== 0"
          cdkTextareaAutosize
          (focus)="setCurrentFocus(i)"
        ></textarea>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="comments" *ngIf="readMode">
    <mat-header-cell *cdkHeaderCellDef>{{
      "common.setupInstruction" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.comment || "-" }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="actions" *ngIf="!readMode">
    <mat-header-cell *cdkHeaderCellDef>{{
      "common.actions" | translate
    }}</mat-header-cell>
    <mat-cell
      [id]="'measure-points-table-delete-button-' + row.index"
      *cdkCellDef="let row = $implicit; let i = index"
      class="delete"
      (click)="removeMeasurePoint(i)"
    >
      <button mat-icon-button class="delete-icon">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="columns" class="header"></mat-header-row>
  <mat-row
    *cdkRowDef="let row; columns: columns; let i = index"
    [ngClass]="{ 'row-shadow-focus': !readMode && i === currentIndexFocus }"
  ></mat-row>
</mat-table>
