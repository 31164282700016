import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import {
  DeploymentReport,
  StructureDeploymentReportDevice,
  StructureDevice,
} from "../../models/structure.model";

@Component({
  selector: "deployment-report-confirmation",
  templateUrl: "./deployment-report-confirmation.component.html",
  styleUrls: ["./deployment-report-confirmation.component.scss"],
})
export class DeploymentReportConfirmationComponent implements OnInit {
  @Input() public deploymentReport!: DeploymentReport;
  @Input() public devices!: StructureDevice[];
  @Output() public cancel = new EventEmitter<void>();
  @Output() public validate = new EventEmitter<DeploymentReport>();

  public form!: FormGroup<Record<string, AbstractControl>>;

  public nodesConfirmed = false;
  public gatewaysConfirmed = false;

  public ngOnInit() {
    this.form = new FormGroup(this.deploymentReportToControls());
    this.form.setValidators(this.atLeastOneCheckboxCheckedValidator());
  }

  public handleValidate() {
    this.validate.emit(this.controlsToDeploymentReport());
  }

  public handleCancel() {
    this.cancel.emit();
  }

  public getDemobilizedNodes() {
    const assignedNodesSerialNumbers = this.deploymentReport.devices.map(
      (device) => device.serialNumber,
    );
    return this.devices.filter(
      (device) =>
        device.type === "node" &&
        !assignedNodesSerialNumbers.includes(device.serialNumber),
    );
  }

  public getGateways() {
    return this.devices.filter((device) => device.type === "gateway");
  }

  private getDeploymentReportGatewaysSerialNumber() {
    return this.devices
      .filter((device) => device.type === "gateway")
      .map((gateway) => gateway.serialNumber);
  }

  private deploymentReportToControls(): Record<string, AbstractControl> {
    const controls: Record<string, AbstractControl> = {};

    this.getGateways()
      .map((gateway) => gateway.serialNumber)
      .forEach((serialNumber) => {
        controls[serialNumber] = new FormControl<boolean>(
          this.getDeploymentReportGatewaysSerialNumber().includes(serialNumber),
        );
      });
    return controls;
  }

  private atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: AbstractControl) {
      if (!(formGroup instanceof FormGroup)) {
        throw new Error("This validator must be used on a FormGroup");
      }
      let checked = 0;
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        };
      }
      return null;
    };
  }

  private controlsToDeploymentReport(): DeploymentReport {
    const gateways: StructureDeploymentReportDevice[] = Object.entries(
      this.form.value as Record<string, boolean>,
    )
      .filter(([, checked]) => checked)
      .map(([serialNumber]) => {
        return { serialNumber, measuringPoint: 0, reportComment: "" };
      });

    const devices = this.deploymentReport.devices.concat(gateways);
    return {
      devices,
    };
  }
}
