import { AsyncPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Temporal } from "@js-temporal/polyfill";
import { TranslateService } from "@ngx-translate/core";
import { isEqual } from "lodash";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import {
  MeasureTableFilters,
  StructureMeasuresPageStore,
} from "../../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";
import { Entries } from "../../../services/utils/utils";

@Component({
  selector: "structure-measures-chip-list",
  templateUrl: "./structure-measures-chip-list.component.html",
  providers: [AsyncPipe],
})
export class StructureMeasuresChipListComponent implements OnInit, OnDestroy {
  public chips$ = new BehaviorSubject<Chip[]>([]);

  private subscription = new Subscription();
  private chipsOrder: Array<keyof MeasureTableFilters> = [
    "requestedDate",
    "amcMode",
    "isAmcSync",
    "measurePoints",
    "processingRequestStatuses",
    "isMeasurePointActive",
  ];

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private datePipe: LocalizedDatePipe,
    private translate: TranslateService,
  ) {}

  public ngOnInit() {
    this.subscription.add(
      this.translate.onLangChange.subscribe(() => this.refreshChips()),
    );
    this.subscription.add(
      this.pageStore
        .getFilterForm()
        .valueChanges.pipe(
          distinctUntilChanged((prev, next) => isEqual(prev, next)),
        )
        .subscribe(() => this.refreshChips()),
    );
    this.refreshChips();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public refreshChips() {
    const filter: MeasureTableFilters = this.pageStore
      .getFilterForm()
      .getRawValue();

    const chips = (Object.entries(filter) as FilterEntries)
      .filter((entry) => this.isFilterActive(entry))
      .map((entry) => ({
        filter: entry[0],
        text: this.getChipText(entry),
      }));

    chips.sort(
      (a, b) =>
        this.chipsOrder.indexOf(a.filter) - this.chipsOrder.indexOf(b.filter),
    );

    this.chips$.next(chips);
  }

  public onRemove(chip: Chip) {
    this.pageStore.getFilterForm().controls[chip.filter].reset();
  }

  private isFilterActive([key, value]: FilterEntries[number]) {
    return key === "requestedDate"
      ? value.start !== "" || value.end !== ""
      : value.length > 0;
  }

  private getChipText([key, value]: FilterEntries[number]) {
    if (key === "requestedDate") {
      const start = value.start
        ? this.datePipe.transform(value.start, "shortDate2")
        : "";
      const end = value.end
        ? this.datePipe.transform(
            Temporal.PlainDate.from(value.end).subtract("P1D").toString(),
            "shortDate2",
          )
        : "";
      return `${start} → ${end}`;
    }
    if (key === "measurePoints") {
      const point = this.translate.instant("common.point");
      return `${point} ${value.join(", ")}`;
    }
    // for the rest of filters, its always the same mechanism
    const i18nKeys = value.map((v) => {
      return {
        amcMode: `component.measuresTable.amcMode.${v}`,
        isAmcSync: `component.measuresTable.amcSync.options.${
          v ? "sync" : "notSync"
        }`,
        processingRequestStatuses: `component.processingRequestedStatus.status.${v}`,
        isMeasurePointActive: `common.${v ? "activated" : "deactivated"}`,
      }[key];
    });
    return i18nKeys
      .map((i18nKey) => this.translate.instant(i18nKey))
      .join(", ");
  }
}

interface Chip {
  text: string;
  filter: keyof MeasureTableFilters;
}

type FilterEntries = Entries<MeasureTableFilters>;
