<div class="outer-content">
  <loading-spinner-mask *ngIf="waiting$ | async"></loading-spinner-mask>
  <div class="body-content">
    <div class="table-container">
      <div class="table-content">
        <measure-points-table-full
          [structure]="currentStructure$ | async"
        ></measure-points-table-full>
      </div>
    </div>
  </div>

  <div class="fixed-footer">
    <div class="footer-container">
      <button
        id="structure-validation-reject-button"
        mat-raised-button
        class="reject-button"
        (click)="disapproveStructure()"
        color="primary"
      >
        {{ "common.reject" | translate }}
      </button>
      <button
        id="structure-validation-validate-button"
        mat-raised-button
        class="validate-button"
        (click)="validateStructure()"
        color="primary"
      >
        {{ "common.validate" | translate }}
      </button>
    </div>
  </div>
</div>
