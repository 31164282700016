import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";
@Component({
  selector: "search-input",
  templateUrl: "search-input.component.html",
  styleUrls: ["search-input.component.scss"],
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() public form!: FormControl<string>;
  @Input() public label = "common.search";
  @Input() public searchInputStyle = "white-background";

  public searchControl = new FormControl<string>("", { nonNullable: true });

  private subscriptions = new Subscription();

  public ngOnInit() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(startWith(this.form.value), distinctUntilChanged())
        .subscribe((value) => this.searchControl.setValue(value)),
    );
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => this.form.setValue(value)),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
