import {
  AmcMode,
  AmcOnDemandMode,
  AmcWeeklyMode,
} from "../../models/amc.model";

export function isWeeklyAmcMode(amcMode: AmcMode): amcMode is AmcWeeklyMode {
  return amcMode.name === "weekly";
}

export function isOnDemandAmcMode(
  amcMode: AmcMode,
): amcMode is AmcOnDemandMode {
  return amcMode.name === "on_demand";
}

export function amcModeToJobConfig(mode: AmcMode) {
  return {
    name: mode.name,
    priority: mode.priority,
    start_date_second: mode.startDateSeconds,
    phase:
      "phase" in mode && mode.phase
        ? {
            day_of_week: mode.phase.dayOfWeek,
            hour_in_the_day_minute: mode.phase.hourOfDay * 60,
          }
        : undefined,
    period_minute: mode.periodMinutes,
    occurrences:
      "occurences" in mode && mode.occurences ? mode.occurences : undefined,
    duration_second: mode.durationSeconds,
    sampling_rate_msecond: mode.samplingRateMilliseconds,
    nodes_filter: mode.measuringPoints,
  };
}
