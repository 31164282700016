<div>
  <p>{{ data | translate }}</p>
  <div>
    <button
      id="snackbar-reload-button"
      class="reload-button"
      mat-button
      (click)="reload()"
    >
      {{ "common.reload" | translate }}
    </button>
    <button id="snackbar-dismiss-button" mat-button (click)="dismiss()">
      {{ "common.dismiss" | translate }}
    </button>
  </div>
</div>
