import { File } from "../../models/file.model";
import { formatFilename } from "../utils/utils";

export function mapFilesFromResponseToFiles(
  files: Array<{ name: string; href: string }>,
  serviceUrl: string,
): File[] {
  const _files = files.map((file) => {
    const { name, href } = file;
    // e.g. href="api/v1/business/file/legals/FR-fr/cgv.pdf"
    // and  serviceUrl="api/v1/business"
    // Split the serviceUrl from the href to get everything else -> path="/file/legals/FR-fr/cgv.pdf"
    const path = href.split(`${serviceUrl}`).pop()!;
    // Split the static root "/file/" from the path and split again to get folders and filename in an array -> folders=['legals', 'FR-fr', 'cgv.pdf']
    const folders = path.split("/file/")[1].split("/");
    // Shift the first elem to get the category folder -> folders=['FR-fr', 'cgv.pdf'] and category="legals"
    const category = folders.shift()!; // category: FR-fr
    // Check if they are more folders -> if so it is a localized category
    let locale;
    if (folders.length > 1) locale = folders.shift();

    const shouldReplaceDash = category !== "release-notes";
    return {
      name: formatFilename(name, shouldReplaceDash),
      category,
      locale,
      path,
    };
  });
  return sortFiles(_files);
}

function sortFiles(files: File[]): File[] {
  return files.sort((a, b) => {
    const extensionA = a.path.split(".").pop()!;
    const extensionB = b.path.split(".").pop()!;
    const pathA = a.path;
    const pathB = b.path;
    return extensionA < extensionB
      ? -1
      : extensionA > extensionB
      ? 1
      : pathA < pathB
      ? -1
      : pathA > pathB
      ? 1
      : 0;
  });
}
