import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { latLng, MapOptions, Marker, tileLayer } from "leaflet";
import { LocationObject } from "../../models/structure.model";
import { OpenStreetMapService } from "../../services/network/open-street-map.service";
import { ClickableMarker } from "../../services/utils/ClickableMarker";

@Component({
  selector: "map-dialog",
  templateUrl: "./map-dialog.component.html",
  styleUrls: ["./map-dialog.component.scss"],
})
export class MapDialogComponent {
  public name: string;
  public addressMarker: Marker;
  public mapOptions: MapOptions;

  public constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: MapDialogData,
    marker: ClickableMarker,
  ) {
    this.name = dialogData.name;
    const { lat, lng } = dialogData.location;
    this.addressMarker = marker.createMarker(lat, lng);
    this.mapOptions = {
      layers: [tileLayer(OpenStreetMapService.tileUrl)],
      zoom: 10,
      center: latLng(lat, lng),
    };
  }
}

export interface MapDialogData {
  name: string;
  location: LocationObject;
}
