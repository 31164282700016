import { Component, Input } from "@angular/core";
import { EventFiltersForm } from "../../services/network/notification.service";

@Component({
  selector: "event-filter",
  templateUrl: "event-filter.component.html",
  styleUrls: ["event-filter.component.scss"],
})
export class EventFilterComponent {
  @Input() public form!: EventFiltersForm;

  public show = true;

  public resetFilters() {
    this.form.patchValue({
      date: {
        start: "",
        end: "",
      },
      types: [],
      structureId: undefined,
      customerId: undefined,
      contains: undefined,
    });
  }
}
