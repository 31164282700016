import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[autofocus]",
})
export class AutofocusDirective {
  @Input() public set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
  private focus = true;

  public constructor(private el: ElementRef) {}

  public ngOnInit() {
    if (this.focus) {
      //Otherwise Angular throws error: Expression has changed after it was checked.
      window.setTimeout(() => {
        this.el.nativeElement.focus();
      });
    }
  }
}
