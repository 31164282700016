import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { HashId } from "src/app/models/structure.model";
import { BusinessService } from "src/app/services/network/business.service";
import { createStructureFormGroup } from "../../../../services/utils/createStructureFormGroup";

@Component({
  selector: "structure-overview",
  templateUrl: "./structure-overview.component.html",
  styleUrls: ["./structure-overview.component.scss"],
})
export class StructureOverviewComponent implements OnInit, OnDestroy {
  public formGeneralInfo = createStructureFormGroup();

  public structureId: HashId = "";

  private sub: Subscription;

  public constructor(
    private businessService: BusinessService,
    private route: ActivatedRoute,
  ) {
    this.formGeneralInfo.disable();
    this.sub = new Subscription();
  }

  public ngOnInit() {
    if (this.route.parent && this.route.parent.parent) {
      this.route.parent.parent.params.pipe(take(1)).subscribe((params) => {
        this.structureId = params["id"];
        this.businessService.getStructure(params["id"]);
      });
    }
    this.sub = this.businessService.structureStream().subscribe((value) => {
      if (value) {
        this.formGeneralInfo.patchValue({
          ...value.generalInfo,
          regionId: value.generalInfo.region?.id,
          location: {
            ...value.generalInfo.location,
            thumbnail: null,
          },
        });
      }
    });
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public downloadFile() {
    this.businessService.getStructureDeploymentFile(this.structureId);
  }
}
