import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  MeasurePointDeactivationReason,
  measurePointDeactivationReasonOptions,
} from "../../models/structure.model";

@Component({
  selector: "measure-point-active-toggle-deactivate-dialog",
  templateUrl: "measure-point-active-toggle-deactivate-dialog.component.html",
  styleUrls: ["./measure-point-active-toggle-deactivate-dialog.component.scss"],
})
export class MeasurePointActiveToggleDeactivateDialogComponent {
  public reasonOptions = measurePointDeactivationReasonOptions;
  public reasonControl = new FormControl<MeasurePointDeactivationReason>(
    measurePointDeactivationReasonOptions[0],
  );
  public commentControl = new FormControl<string>("");

  constructor(@Inject(MAT_DIALOG_DATA) public measurePointNumber: number) {}
}
