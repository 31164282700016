import { Component, HostListener, Input, OnChanges } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import {
  DeviceType,
  GatewaySOH,
  GatewaySohMeasuringPoint,
  NodeSOH,
  NodeSohMeasuringPoint,
  Structure,
  StructureStatus,
} from "src/app/models/structure.model";
import { mapDeviceSohToDeviceSohMeasuringPoint } from "../../services/mapping/mapDeviceSohToDeviceSohMeasuringPoint";
import { MatDialog } from "@angular/material/dialog";
import { UpdateSerialNumberDialogComponent } from "./update-serial-number-dialog.component";
import { BehaviorSubject, filter, finalize, map, mergeMap, tap } from "rxjs";
import { DeploymentService } from "../../services/network/deployment.service";
import { BusinessService } from "../../services/network/business.service";
import { pollUntil$ } from "../../services/utils/utils";
import { SnackbarService } from "src/app/services/utils/snackbar.service";

@Component({
  selector: "structure-sensors-table",
  templateUrl: "./structure-sensors-table.component.html",
  styleUrls: ["./structure-sensors-table.component.scss"],
})
export class StructureSensorsTableComponent implements OnChanges {
  @Input() public structure!: Structure;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public nodeList!: DisplayedSOH[];
  public gatewayList!: DisplayedSOH[];
  public amcId?: number;
  public sohGatewayDataColumns = [
    "serialNumber",
    "measuringPoint",
    "LTEM",
    "GNSS",
    "status",
    "amcId",
    "battery",
    "lastSeen",
    "actions",
  ];
  public selectedSoh?: GatewaySOH | NodeSOH = undefined;

  private sohGatewayAuthzScopes = {
    amcId: "ui:devices:sync:show",
  };
  public sohDataColumns = [
    "serialNumber",
    "measuringPoint",
    "WIFI",
    "status",
    "amcId",
    "battery",
    "lastSeen",
    "actions",
  ];
  private sohNodeAuthzScopes = {
    amcId: "ui:devices:sync:show",
  };

  private updateDeviceAuthzScopes = {
    actions: "structure:device:update",
  };
  public constructor(
    private permissionsService: NgxPermissionsService,
    private businessService: BusinessService,
    private deploymentService: DeploymentService,
    private dialog: MatDialog,
    private snackBar: SnackbarService,
  ) {}

  public ngOnChanges(): void {
    this.gatewayList = mapDeviceSohToDeviceSohMeasuringPoint(
      this.structure,
      "gateway",
    ).map((gateway) => ({
      status: this.getMeasurePointStatus(gateway),
      soh: gateway,
    }));
    this.nodeList = mapDeviceSohToDeviceSohMeasuringPoint(
      this.structure,
      "node",
    ).map((node) => ({ status: this.getMeasurePointStatus(node), soh: node }));
    this.amcId = this.structure.generalInfo.lastAmc?.id;
    this.sohGatewayDataColumns = this.sohGatewayDataColumns
      .filter((column) =>
        this.isColumnAuthorized(column, this.sohGatewayAuthzScopes),
      )
      .filter((column) =>
        this.isColumnRelevant(column, this.structure.generalInfo.status),
      )
      .filter((column) =>
        this.isColumnAuthorized(column, this.updateDeviceAuthzScopes),
      );
    this.sohDataColumns = this.sohDataColumns
      .filter((column) =>
        this.isColumnAuthorized(column, this.sohNodeAuthzScopes),
      )
      .filter((column) =>
        this.isColumnRelevant(column, this.structure.generalInfo.status),
      )
      .filter((column) =>
        this.isColumnAuthorized(column, this.updateDeviceAuthzScopes),
      );
  }

  public selectSoh(soh: GatewaySOH | NodeSOH): void {
    if (this.selectedSoh === soh) {
      this.closePanel();
    } else {
      this.selectedSoh = soh;
    }
  }

  @HostListener("window:keyup", ["$event"])
  public handleKeyUp(event: KeyboardEvent) {
    if (event.code === "Escape") {
      this.closePanel();
    }
  }

  public closePanel() {
    this.selectedSoh = undefined;
  }

  private isColumnAuthorized(
    column: string,
    columnToScope: Record<string, string>,
  ) {
    return (
      !columnToScope[column] ||
      this.permissionsService.getPermission(columnToScope[column])
    );
  }

  private isColumnRelevant(column: string, structureStatus: StructureStatus) {
    return !(column === "amcId" && structureStatus !== "monitored");
  }

  public getMeasurePointStatus(
    row: NodeSohMeasuringPoint | GatewaySohMeasuringPoint,
  ): string {
    const measurePoint = this.structure.measuringPoints.find(
      (m) => m.index === row.measuringPoint,
    );
    let status = "IN_DEPLOYMENT";
    if (row.inOperation) status = "IN_OPERATION";
    if (measurePoint && !measurePoint.active) status = "DEACTIVATED";
    return status;
  }

  public updateDevice(
    row: DisplayedSOH,
    deviceType: DeviceType,
    $event: MouseEvent,
  ) {
    $event.stopPropagation();
    const { serialNumber, measuringPoint } = row.soh;

    this.dialog
      .open(UpdateSerialNumberDialogComponent, {
        autoFocus: false,
        data: { serialNumber, deviceType, measurePointNumber: measuringPoint },
      })
      .afterClosed()
      .pipe(
        filter((dialogResult) => !!dialogResult),
        tap(() => {
          this.isLoading$.next(true);
        }),
        mergeMap((dialogResult) =>
          this.deploymentService.updateDevice(
            this.structure.generalInfo.id,
            serialNumber!,
            dialogResult!,
          ),
        ),
        mergeMap((dialogResult) =>
          pollUntil$(() =>
            this.businessService
              .getStructure(this.structure.generalInfo.id)
              .pipe(
                map((s) =>
                  s.devices.find(
                    (d) => d.serialNumber === dialogResult.serialNumber,
                  ),
                ),
              ),
          ),
        ),
        tap(() =>
          this.snackBar.open(
            "component.structureSensorsTable.snackbar.deviceUpdated",
          ),
        ),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe();
  }
}

interface DisplayedSOH {
  status: string;
  soh: NodeSohMeasuringPoint | GatewaySohMeasuringPoint;
}
