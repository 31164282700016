import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.scss"],
})
export class InputNumberComponent {
  @Input() public form!: FormControl<number>;
  @Input() public id!: string;
  @Input() public label!: string;
  @Input() public error!: string;

  public constructor() {}

  public increment() {
    this.form.patchValue(this.form.value + 1);
  }

  public decrement() {
    this.form.patchValue(this.form.value - 1);
  }

  public isIncrementDisabled() {
    return (
      this.form.validator!(new FormControl(this.form.value + 1))?.max ||
      this.form.disabled
    );
  }

  public isDecrementDisabled() {
    return (
      this.form.validator!(new FormControl(this.form.value - 1))?.min ||
      this.form.disabled
    );
  }
}
