import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { DisplayedFile } from "../../models/file.model";
import { BusinessService } from "../../services/network/business.service";
import { StorageService } from "../../services/utils/storage.service";
import { formatFilename } from "../../services/utils/utils";

@Component({
  selector: "file-page",
  templateUrl: "./file-page.html",
  styleUrls: ["./file-page.scss"],
})
export class FilePageComponent implements OnDestroy {
  public displayedFile$ = new Observable<DisplayedFile | undefined>();
  public subscriptions: Subscription = new Subscription();

  public constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private businessService: BusinessService,
  ) {
    // override the route reuse strategy or query params change will not trigger the subscription
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  public ngOnInit() {
    this.displayedFile$ = this.businessService.displayedFileStream();

    this.subscriptions.add(
      combineLatest([this.route.params, this.businessService.getFilesStream()])
        .pipe(
          map((results) => ({
            name: results[0].name,
            category: results[0].category,
            files: results[1],
          })),
        )
        .subscribe((results) => {
          const { name, category, files } = results;
          const path = this.router.url;

          if (files.length === 0) this.businessService.getFiles();
          else {
            this.storage.setPageHeader({
              showHomeButton: true,
              showBackButton: false,
              showNavLinks: false,
              pageTitle: `page.${category}.title`,
              pageTitleParams: { name: formatFilename(name) },
            });
            this.businessService.clearDisplayedFile();

            this.businessService.getFile(name, path);
          }
        }),
    );
  }
  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
