<div class="outer-content">
  <div class="body-content">
    <div class="down">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="140.801"
        height="140.801"
        viewBox="0 0 140.801 140.801"
      >
        <g id="downward" transform="translate(-.313)">
          <path
            id="Path_1136"
            d="M38.025 140.8L.313 103.088h10.82a4.487 4.487 0 0 0 4.482-4.482V49.3A49.361 49.361 0 0 1 64.918 0h40.339v8.964a44.872 44.872 0 0 0-44.821 44.821v44.821a4.487 4.487 0 0 0 4.482 4.482h10.819zm0 0"
            class="down-clear"
            data-name="Path 1136"
          />
          <g
            id="Group_1365"
            data-name="Group 1365"
            transform="translate(114.221)"
          >
            <path
              id="Path_1137"
              d="M406.938 0h8.962v8.964h-8.964zm0 0"
              class="down-dark"
              data-name="Path 1137"
              transform="translate(-406.938)"
            />
            <path
              id="Path_1138"
              d="M470.938 0h8.962v8.964h-8.964zm0 0"
              class="down-dark"
              data-name="Path 1138"
              transform="translate(-453.009)"
            />
          </g>
        </g>
      </svg>
      <div class="block-title">
        {{ getPrettyBytes((currentStructure$ | async)?.dataUse?.down) }}
        {{ "page.structure.traffic.received" | translate }}
      </div>
    </div>
    <div class="up">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="140.801"
        height="140.801"
        viewBox="0 0 140.801 140.801"
      >
        <g id="downward" transform="rotate(180 70.556 70.4)">
          <path
            id="Path_1136"
            d="M38.025 140.8L.313 103.088h10.82a4.487 4.487 0 0 0 4.482-4.482V49.3A49.361 49.361 0 0 1 64.918 0h40.339v8.964a44.872 44.872 0 0 0-44.821 44.821v44.821a4.487 4.487 0 0 0 4.482 4.482h10.819zm0 0"
            class="up-clear"
            data-name="Path 1136"
          />
          <g
            id="Group_1365"
            data-name="Group 1365"
            transform="translate(114.221)"
          >
            <path
              id="Path_1137"
              d="M406.938 0h8.962v8.964h-8.964zm0 0"
              class="up-dark"
              data-name="Path 1137"
              transform="translate(-406.938)"
            />
            <path
              id="Path_1138"
              d="M470.938 0h8.962v8.964h-8.964zm0 0"
              class="up-dark"
              data-name="Path 1138"
              transform="translate(-453.009)"
            />
          </g>
        </g>
      </svg>
      <div class="block-title">
        {{ getPrettyBytes((currentStructure$ | async)?.dataUse?.up) }}
        {{ "page.structure.traffic.sended" | translate }}
      </div>
    </div>
  </div>
</div>
