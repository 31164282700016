<loading-spinner-mask *ngIf="isLoading$ | async"></loading-spinner-mask>
<div class="measure-point-active-toggle">
  <mat-slide-toggle
    [id]="'measure-point-active-toggle-' + measurePoint.index"
    [checked]="measurePoint.active"
    (change)="onChange($event)"
    [disabled]="
      !(permissions$ | async)['structure:deactivated_measure_points:manage']
    "
    color="primary"
  ></mat-slide-toggle>
  <div *ngIf="!measurePoint.active" class="deactivation-date">
    {{ measurePoint.deactivationDate | localizedDate: "shortDate2" }}
  </div>
  <div
    *ngIf="!measurePoint.active"
    [matTooltip]="measurePoint.deactivationComment"
  >
    {{
      "api.deactivationReason." + measurePoint.deactivationReason | translate
    }}
  </div>
</div>
