import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, from, Observable } from "rxjs";
import {
  filter,
  finalize,
  map,
  mergeMap,
  shareReplay,
  tap,
} from "rxjs/operators";
import { BusinessService } from "src/app/services/network/business.service";
import { DeploymentService } from "src/app/services/network/deployment.service";
import { StorageService } from "src/app/services/utils/storage.service";
import {
  DeploymentReport,
  Structure,
  StructureDeploymentReportDevice,
} from "../../../../models/structure.model";
import { SnackbarService } from "../../../../services/utils/snackbar.service";
import { AbstractStructureWorkflowPage } from "../abstract/abstract-structure-workflow-page";

@Component({
  selector: "structure-finalization-page",
  templateUrl: "./structure-finalization-page.html",
  styleUrls: ["./structure-finalization-page.scss"],
})
export class StructureFinalizationPage
  extends AbstractStructureWorkflowPage
  implements OnInit
{
  public deploymentReportForm$: Observable<FormGroup>;

  public gateways$!: Observable<string[]>;
  public isLoading$ = new BehaviorSubject(false);

  @ViewChild("stepper") public stepper!: MatStepper;

  public constructor(
    protected location: Location,
    protected businessService: BusinessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected storage: StorageService,
    private deploymentService: DeploymentService,
    private snackBar: SnackbarService,
  ) {
    super(location, businessService, route, storage);

    const draft$ = this.route.data.pipe(
      map((data) => data?.deploymentReportDraft),
    );
    this.deploymentReportForm$ = this.currentStructure$.pipe(
      filter((structure) => structure !== undefined),
      map(
        (structure) =>
          new FormGroup({
            devices: new FormArray(
              structure.measuringPoints
                .filter((point) => point.index !== 0)
                .map(
                  (point) =>
                    new FormGroup({
                      measuringPoint: new FormControl(point.index),
                      serialNumber: new FormControl(""),
                      reportComment: new FormControl(""),
                    }),
                ),
            ),
          }),
      ),
      mergeMap((form) =>
        draft$.pipe(
          tap((draft) => {
            if (draft) form.patchValue(draft);
          }),
          map(() => form),
        ),
      ),
      shareReplay(),
    );
  }

  public ngOnInit() {
    this.setHeader("page.structure.finalization.title");

    this.gateways$ = this.getCurrentStructure().pipe(
      map((structure) =>
        structure.devices
          .filter((device) => device.serialNumber === "gateway")
          .map((device) => device.serialNumber),
      ),
    );
  }
  public ngAfterViewInit() {
    this.route.queryParamMap.subscribe(async (query) => {
      if (query.has("step")) {
        this.stepper.selectedIndex = Number(query.get("step"));
      }
    });

    this.stepper.selectionChange.subscribe(
      async (event: StepperSelectionEvent) => {
        await this.navigateToStepIndex(event.selectedIndex);
      },
    );
  }
  private navigateToStepIndex(selectedIndex: number) {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        step: selectedIndex,
      },
    });
  }

  public isDeploymentReportValid(
    deploymentReport: DeploymentReport,
    structure: Structure,
  ) {
    const uniqueSerialNumbers = new Set(
      deploymentReport.devices.map((device) => device.serialNumber),
    );
    if (deploymentReport.devices.length !== uniqueSerialNumbers.size) {
      return false;
    }
    const registeredNodes = structure.devices
      .filter((device) => device.type === "node")
      .map((device) => device.serialNumber);
    const allExist = deploymentReport.devices.every((device) =>
      registeredNodes.includes(device.serialNumber),
    );
    return allExist;
  }

  public finalizeStructure($event: {
    devices: StructureDeploymentReportDevice[];
  }) {
    this.isLoading$.next(true);
    const devices = $event.devices;
    this.deploymentService
      .finalizeStructure(this.structureId, devices)
      .pipe(
        this.businessService.pollUntilStructureLeftState(
          this.structureId,
          "created",
        ),
        mergeMap(() => from(this.router.navigate(["landing"]))),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }

  public getCurrentStructure(): Observable<Structure> {
    return this.currentStructure$;
  }

  public async saveDeploymentReportDraft(
    deploymentReport: DeploymentReport,
    withAlert = true,
  ) {
    await this.deploymentService.saveDeploymentReportDraft(
      this.structureId,
      deploymentReport.devices,
    );
    if (withAlert) {
      this.snackBar.open("structure.finalization.draftHasBeenSaved");
    }
  }

  public goBack(stepper: MatStepper) {
    stepper.previous();
  }
}
