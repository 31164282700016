import { NgModule } from "@angular/core";
import { AutofocusDirective } from "./autofocus.directive";
import { ReactiveDisabledDirective } from "./reactive-disabled.directive";

const DIRECTIVES = [AutofocusDirective, ReactiveDisabledDirective];

@NgModule({
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
})
export class DirectivesModule {}
