import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentsModule } from "src/app/components/components.module";
import { SharedModule } from "../../shared/shared.module";
import { LandingPage } from "./landing-page";
import { LandingPageRoutingModule } from "./landing-page-routing.module";

const COMPONENTS = [LandingPage];

@NgModule({
  imports: [
    SharedModule,
    ComponentsModule,
    BrowserAnimationsModule,
    LandingPageRoutingModule,
    MatButtonModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class LandingPageModule {}
