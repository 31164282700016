<div class="synthesis-header-cell">
  <div class="requested-date-header-cell">
    <app-date-range-picker
      [label]="'common.date'"
      [form]="form.get('requestedDate')"
      pickerStyle="blue-dark-background"
    ></app-date-range-picker>
  </div>
  <div class="mode-sync-header-cell">
    <span class="amc-mode">
      <select-clear-button
        [buttonId]="'select-clear-button-mode'"
        [form]="form.get('amcMode')"
        [options]="amcModeOptions"
        [labelTranslationKey]="'component.measuresTable.amcMode.mode'"
        [optionTranslations]="amcModeTranslations"
        [width]="'80px'"
        selectStyle="shrinked"
        class="blue-dark-background"
      ></select-clear-button>
    </span>
    <span class="amc-sync">
      <select-clear-button
        [buttonId]="'select-clear-button-sync'"
        [form]="form.get('isAmcSync')"
        [options]="amcSyncOptions"
        [labelTranslationKey]="'component.measuresTable.amcSync.sync'"
        [optionTranslations]="syncTranslations"
        [width]="'80px'"
        selectStyle="shrinked"
        class="blue-dark-background"
      ></select-clear-button>
    </span>
  </div>
</div>
