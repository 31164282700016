import { CdkTableModule } from "@angular/cdk/table";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HighlightModule } from "ngx-highlightjs";
import { ComponentsModule } from "src/app/components/components.module";
import { StructureSensorsTableComponent } from "../../components/structure-sensors-table/structure-sensors-table.component";
import { SharedModule } from "../../shared/shared.module";
import {
  DialogOnDemandInfo,
  DialogWeeklyInfo,
  StructureConfigComponent,
} from "./structure-details/structure-config/structure-config.component";
import { StructureDetailsPageComponent } from "./structure-details/structure-details-page";
import { StructureMeasureSpotsComponent } from "./structure-details/structure-measure-spots/structure-measure-spots.component";
import { StructureMeasuresComponent } from "./structure-details/structure-measures/structure-measures.component";
import { StructureOverviewComponent } from "./structure-details/structure-overview/structure-overview.component";
import { StructureSensorsComponent } from "./structure-details/structure-sensors/structure-sensors.component";
import { StructureTrafficComponent } from "./structure-details/structure-traffic/structure-traffic.component";
import { StructurePageComponent } from "./structure-page";
import { StructurePageRoutingModule } from "./structure-page-routing.module";
import { StructureApprovalPage } from "./structure-workflow/structure-approval/structure-approval-page";
import { StructureFinalizationPage } from "./structure-workflow/structure-finalization/structure-finalization-page";
import { StructureValidationPage } from "./structure-workflow/structure-validation/structure-validation-page";

const COMPONENTS = [
  StructureTrafficComponent,
  StructureSensorsTableComponent,
  StructureSensorsComponent,
  StructureOverviewComponent,
  StructureConfigComponent,
  StructureMeasuresComponent,
  StructureMeasureSpotsComponent,
  StructureDetailsPageComponent,
  StructureApprovalPage,
  StructureFinalizationPage,
  StructureValidationPage,
  StructurePageComponent,
  DialogOnDemandInfo,
  DialogWeeklyInfo,
];

@NgModule({
  imports: [
    SharedModule,
    ComponentsModule,
    MatTableModule,
    MatButtonModule,
    BrowserAnimationsModule,
    CdkTableModule,
    MatIconModule,
    StructurePageRoutingModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    HighlightModule,
    MatStepperModule,
    MatSlideToggleModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class StructurePageModule {}
