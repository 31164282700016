import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSelectionList } from "@angular/material/list";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MeasureItem, OpenSensorValue } from "src/app/models/measure.model";
import { BusinessService } from "../../services/network/business.service";
@Component({
  selector: "side-panel-opensensors",
  templateUrl: "./side-panel-opensensors.component.html",
  styleUrls: ["./side-panel-opensensors.component.scss"],
})
export class SidePanelOpenSensorsComponent implements OnChanges {
  @ViewChild("selection", { static: true })
  private selection!: MatSelectionList;

  @Input() public measure!: MeasureItem;
  @Output() public closePanelEvent = new EventEmitter<boolean>();

  // object: id => Observable<committed>
  public committedStates!: Record<string, Observable<{ committed: boolean }>>;

  public constructor(private businessService: BusinessService) {}

  public ngOnChanges(): void {
    this.committedStates = Object.fromEntries(
      this.measure.sensors.openSensors.map((os) => [
        os.id,
        this.getCommittedState$(os),
      ]),
    );
  }

  public toggleAll() {
    if (!this.isAllSelected()) {
      this.selection.selectAll();
    } else {
      this.selection.deselectAll();
    }
  }

  public isAllSelected() {
    return (
      this.selection.selectedOptions.selected.length ===
      this.measure.sensors.openSensors.length
    );
  }

  public download() {
    const openSensors = this.selection.selectedOptions.selected.map(
      (select) => select.value as OpenSensorValue,
    );
    if (openSensors.length === 1) {
      this.businessService.downloadOpenSensor(openSensors[0], this.measure);
    } else {
      this.businessService.downloadMultipleOpenSensors(
        openSensors,
        this.measure,
      );
    }
  }

  private getCommittedState$(openSensor: OpenSensorValue) {
    return this.businessService
      .getOpenSensorByHref(openSensor.href)
      .pipe(map((os) => ({ committed: os.committed })));
  }

  public closePanel() {
    this.closePanelEvent.emit(true);
  }
}
