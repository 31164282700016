import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as JSZip from "jszip";

@Injectable({
  providedIn: "root",
})
export class DownloadFileService {
  public constructor() {}

  public downloadFile(
    data: unknown,
    type = "application/base64",
    name?: string,
  ) {
    const blobPart: BlobPart = data as BlobPart;
    const blob = new Blob([blobPart], { type });
    const url = window.URL.createObjectURL(blob);
    if (name) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      window.open(url);
    }
  }

  public async downloadMeasureRaws(
    requests: Array<
      Promise<{
        filename: string;
        measure: unknown;
      }>
    >,
    zipName: string,
  ) {
    const zip = new JSZip();
    const measures = await Promise.all(requests);
    measures.forEach((result) => {
      const { filename, measure } = result;
      zip.file(filename, JSON.stringify(measure));
    });

    await zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, zipName);
    });
  }

  public async downloadHTMLToPdf(htmlElement: HTMLElement, filename: string) {
    await html2canvas(htmlElement).then((canvas) => {
      const margin = 10;
      const contentDataURL = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "pt", "a4");

      const PDFWidth = pdf.internal.pageSize.getWidth();
      const PDFHeight = pdf.internal.pageSize.getHeight();

      const ratio = canvas.height / canvas.width;
      const width = PDFWidth - 2 * margin;
      const height = ratio * width - 2 * margin;
      const numberOfPages = Math.ceil(height / PDFHeight) - 1;

      pdf.addImage(contentDataURL, "JPG", margin, margin, width, height);
      for (let i = 1; i <= numberOfPages; i++) {
        pdf.addPage();
        pdf.addImage(
          contentDataURL,
          "JPG",
          margin,
          -(PDFHeight * i) + margin,
          width,
          height,
        );
      }

      pdf.save(`${filename}.pdf`);
    });
  }
}
