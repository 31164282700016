import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DisplayedFile } from "../../models/file.model";
import { DownloadFileService } from "../../services/utils/download-file.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Redoc: any;

@Component({
  selector: "app-swagger-viewer",
  templateUrl: "./swagger-viewer.component.html",
  styleUrls: ["./swagger-viewer.component.scss"],
})
export class SwaggerViewerComponent implements OnInit, OnDestroy {
  @Input() public displayedFile!: DisplayedFile;

  public constructor(private downloadFileService: DownloadFileService) {}

  public async downloadFile() {
    this.downloadFileService.downloadFile(
      this.displayedFile.blob,
      "application/octet-stream",
      this.displayedFile.name,
    );
  }

  public ngOnInit() {
    this.initSwagger();
  }

  public ngOnDestroy() {
    const redocElement = document.getElementById("swagger-doc-redoc")!;
    Redoc.destroy(redocElement);
    redocElement.remove();
  }

  private initSwagger() {
    Redoc.init(
      this.displayedFile.file,
      {},
      document.getElementById("swagger-doc-redoc"),
    );
  }
}
