import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
@Component({
  selector: "filter-structure-workflow",
  templateUrl: "./filter-structure-workflow.component.html",
  styleUrls: ["./filter-structure-workflow.component.scss"],
})
export class FilterStructureWorkflowComponent implements AfterViewInit {
  @Input() public form!: FormControl<string[]>;

  @ViewChild("select") select!: MatSelect;
  public workflows = [
    { translationKey: "waitingForDeployment", value: "created" },
    { translationKey: "waitingForValidation", value: "deployed" },
    { translationKey: "waitingForApproval", value: "validated" },
    { translationKey: "approved", value: "approved" },
    { translationKey: "monitored", value: "monitored" },
    { translationKey: "demobilized", value: "demobilized" },
  ];
  public allSelected = false;

  public ngAfterViewInit() {
    this.optionClick();
  }

  public toggleAllSelection() {
    if (this.allSelected) {
      this.form.setValue(this.select.options.map((option) => option.value));
    } else {
      this.form.setValue([]);
    }
  }

  public optionClick() {
    this.allSelected = !this.select.options.some((item) => !item.selected);
  }
}
