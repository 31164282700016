<mat-form-field>
  <mat-label>{{ "component.eventFilter.filterByType" | translate }}</mat-label>
  <mat-select multiple [formControl]="form">
    <ng-container *ngFor="let category of categories">
      <event-category-checkbox
        [form]="form"
        [category]="category"
      ></event-category-checkbox>
      <mat-option *ngFor="let type of category.types" [value]="type">
        {{ "component.eventTypeSelect.type." + type | translate }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
