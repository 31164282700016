import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MeasureItem } from "../../../models/measure.model";
import {
  isRequestedDateRow,
  MeasureRow,
  ReceivedRow,
  RequestedDateRow,
  StructureMeasuresPageStore,
} from "../../../pages/structure-page/structure-details/structure-measures/structure-measures-page.store";
import { AuthService } from "../../../services/auth/auth.service";
import { BusinessService } from "../../../services/network/business.service";

import { FormGroup } from "@angular/forms";
import { Dictionary } from "lodash";
import {
  ProcessingRequest,
  processingRequestStatusesFilterOptions,
} from "../../../models/processing-request.model";
import { Translation } from "../../../models/translation.model";
import { LocalizedDatePipe } from "../../../services/utils/LocalizedDate.pipe";

@Component({
  selector: "structure-measures-processed-table",
  templateUrl: "./structure-measures-processed-table.component.html",
  styleUrls: ["../structure-measures-table.component.scss"],
  providers: [LocalizedDatePipe],
})
export class StructureMeasuresProcessedTableComponent
  implements OnInit, OnChanges
{
  public columns = [
    "requestedDate",
    "noRowMessage",
    "measuringPoint",
    "processingRequestStatus",
    "forceProcessing",
    "openSensors",
  ];
  @Input() rows!: MeasureRow[];
  @Output() public measureOpenSensorsSelected = new EventEmitter<ReceivedRow>();
  @Output() public amcSelected = new EventEmitter<RequestedDateRow>();
  @Output() public toggle = new EventEmitter<Date>();
  @Output() public forceProcessing = new EventEmitter<ProcessingRequest>();

  public form!: FormGroup;
  public processingRequestStatuses = processingRequestStatusesFilterOptions;
  public processingRequestStatusTranslations =
    processingRequestStatusesFilterOptions.map((status) => ({
      key: `component.processingRequestedStatus.status.${status}`,
    }));
  public requestedDateRowDictionary: Dictionary<RequestedDateRow> = {};
  public measurePoints!: number[];
  public measurePointsTranslations!: Translation[];

  public constructor(
    private pageStore: StructureMeasuresPageStore,
    private businessService: BusinessService,
    private authService: AuthService,
  ) {
    this.form = this.pageStore.getFilterForm();
  }

  public ngOnInit() {
    this.removeColumnIfNoRight(
      "processingRequestStatus",
      "processingRequest:read",
    );
    this.removeColumnIfNoRight("forceProcessing", "processingRequest:create");
    this.measurePoints = this.pageStore.getStructureMeasurePointNumbers();
    this.measurePointsTranslations = this.getMeasurePointsTranslations();
  }

  public ngOnChanges(): void {
    this.requestedDateRowDictionary = Object.fromEntries(
      this.rows
        .filter(isRequestedDateRow)
        .map((row) => [row.requestedDate.toISOString(), row]),
    );
  }

  public onMeasureOpenSensorsSelected(measure: ReceivedRow) {
    this.measureOpenSensorsSelected.emit(measure);
  }

  public downloadAllOpenSensors(measure: MeasureItem) {
    if (measure.sensors.openSensors.length === 1) {
      this.businessService.downloadOpenSensor(
        measure.sensors.openSensors[0],
        measure,
      );
    } else {
      this.businessService.downloadMultipleOpenSensors(
        measure.sensors.openSensors,
        measure,
      );
    }
  }

  public onAmcSelected(measureRow: RequestedDateRow) {
    this.amcSelected.emit(measureRow);
  }

  public onToggleMode(requestedDate: Date) {
    this.toggle.emit(requestedDate);
  }

  private getMeasurePointsTranslations(): Translation[] {
    return this.pageStore
      .getStructureMeasurePointNumbers()
      .map((measurePointNumber) =>
        measurePointNumber === 0
          ? { key: "common.synthesis" }
          : {
              key: "common.measurePoint",
              interpolateParams: { measurePointNumber },
            },
      );
  }

  private removeColumnIfNoRight(columnToRemove: string, scope: string) {
    if (!this.authService.getScopes().includes(scope)) {
      this.columns = this.columns.filter((column) => column !== columnToRemove);
    }
  }
}
