import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { take } from "rxjs/operators";
import { BusinessService } from "../../../services/network/business.service";

@Injectable()
export class StructureResolver {
  public constructor(private businessService: BusinessService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get("id");
    if (id) {
      return this.businessService.getStructure(id).pipe(take(1));
    }
  }
}
