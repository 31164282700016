<form [formGroup]="form">
  <h1 mat-dialog-title>
    {{
      deviceType === "gateway"
        ? ("component.updateSerialNumberDialog.gatewayTitle"
          | translate: { serialNumber })
        : ("component.updateSerialNumberDialog.nodeTitle"
          | translate: { serialNumber })
    }}
  </h1>
  <div mat-dialog-content>
    <p>
      {{
        deviceType === "gateway"
          ? ("component.updateSerialNumberDialog.gatewayDescription"
            | translate
              : {
                  serialNumber: serialNumber,
                  measurePointNumber: measurePointNumber
                })
          : ("component.updateSerialNumberDialog.nodeDescription"
            | translate
              : {
                  serialNumber: serialNumber,
                  measurePointNumber: measurePointNumber
                })
      }}
    </p>
    <mat-form-field>
      <mat-label>
        {{ "component.updateSerialNumberDialog.newSerialNumber" | translate }}
      </mat-label>
      <input
        matInput
        type="text"
        id="update-serial-number-dialog-serial-number-input"
        formControlName="serialNumber"
      />
    </mat-form-field>
    <mat-form-field *ngIf="deviceType === 'gateway'">
      <mat-label>
        {{ "component.updateSerialNumberDialog.newModemId" | translate }}
      </mat-label>
      <input
        matInput
        type="text"
        id="update-serial-number-dialog-modem-id-input"
        formControlName="modemId"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ "common.aComment" | translate }}
      </mat-label>
      <textarea
        matInput
        id="update-serial-number-dialog-comment-input"
        formControlName="comment"
      ></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      [mat-dialog-close]="false"
      color="accent"
      id="update-serial-number-dialog-cancel"
    >
      {{ "common.cancel" | translate }}
    </button>
    <button
      mat-flat-button
      [mat-dialog-close]="form.value"
      color="primary"
      id="update-serial-number-dialog-validate"
      [disabled]="!form.valid"
    >
      {{ "common.validate" | translate }}
    </button>
  </div>
</form>
