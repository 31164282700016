import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StructureType } from "../../models/structure.model";
import { AuthService } from "../../services/auth/auth.service";
import { StructureFormGroup } from "../../services/utils/createStructureFormGroup";
import { LocalizedDatePipe } from "../../services/utils/LocalizedDate.pipe";
import { SnackbarService } from "../../services/utils/snackbar.service";

@Component({
  selector: "structure-form",
  templateUrl: "./structure-form.component.html",
  styleUrls: ["./structure-form.component.scss"],
})
export class StructureFormComponent {
  @Input() public form!: StructureFormGroup;
  @Input() public readMode = false;
  @Output() public download = new EventEmitter();

  public isConfigWeeklyEnabled = false;
  public currentFileName = "";

  public constructor(
    private snackBar: SnackbarService,
    private datePipe: LocalizedDatePipe,
    public authService: AuthService,
  ) {}

  public downloadFile() {
    this.download.emit();
  }

  public selectType(type: StructureType) {
    this.form.controls.type.setValue(type);
  }

  public typeFieldReadmodeAndError(): boolean {
    return this.readMode && !this.form.controls.type.value;
  }

  public isFileFiled() {
    return this.currentFileName && this.currentFileName !== "";
  }

  public onDrop($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    const dataUrl = $event.dataTransfer!.files[0];
    this.updateFileForm(dataUrl);
  }

  public uploadButtonTrigger($event: Event) {
    const file = ($event.target as HTMLInputElement).files![0];
    if (file) {
      this.updateFileForm(file);
    }
  }

  private updateFileForm(file: File) {
    if (file.size === 0) {
      this.resetDeploymentFileInput();
      this.snackBar.open("component.structureForm.emptyFileNotAllowed");
      return;
    }
    // 10485760 = 10MB
    if (file.size > 10485760) {
      this.resetDeploymentFileInput();
      this.snackBar.open(
        "component.structureForm.uploadMore10MbFileNotAllowed",
      );
      return;
    }
    this.form.controls.deploymentFile.setValue(file);
    this.currentFileName = file.name;

    // force (re)validation of the form to enable submit button
    this.form.controls.name.updateValueAndValidity();
  }

  public onDragOver($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  public getWeekdayName(dayNumber: number) {
    return this.datePipe.transform(`2020-06-0${dayNumber}`, "EEEE");
  }

  private resetDeploymentFileInput() {
    this.form.controls.deploymentFile.setValue(null);
    this.currentFileName = "";
  }
}
