import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "../../services/auth/auth.guard";
import { EventsPage } from "./events-page";

const routes: Routes = [
  {
    path: "events",
    component: EventsPage,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ["ui:events:show"],
        redirectTo: "/landing",
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class EventsPageRoutingModule {}
