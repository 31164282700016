import { Component, Input } from "@angular/core";
import { Alarm } from "src/app/models/structure.model";

@Component({
  selector: "equipment-status",
  templateUrl: "./equipment-status.component.html",
  styleUrls: ["./equipment-status.component.scss"],
})
export class EquipmentStatusComponent {
  @Input() public listAlarms!: Alarm[];

  public constructor() {}

  public getCurrentAlarmState(): number | undefined {
    if (this.listAlarms.length === 0) {
      return undefined;
    }
    const priorityAlarm = this.listAlarms.reduce((prev, current) => {
      return prev.status > current.status ? prev : current;
    });
    return priorityAlarm.status;
  }
}
