import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Customer } from "../../models/customer.model";
import { BillingService } from "../../services/network/billing.service";

@Component({
  selector: "customer-select",
  templateUrl: "customer-select.component.html",
  styleUrls: ["customer-select.component.scss"],
})
export class CustomerSelectComponent implements OnInit {
  @Input() public form!: FormControl<string>;

  public customers$!: Observable<Customer[]>;

  constructor(private billingService: BillingService) {}

  public ngOnInit() {
    this.customers$ = this.billingService
      .getCustomers(0, Number.MAX_SAFE_INTEGER)
      .pipe(map((pagination) => pagination.data));
  }
}
