import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { LocalizedDatePipe } from "../../services/utils/LocalizedDate.pipe";

@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.scss"],
})
export class DateRangePickerComponent implements OnInit {
  // this form is used to initialize the dateRangeFormGroup, and the output values looks like: "2022-09-14"
  // or "" if it's empty
  @Input() public form!: FormGroup<{
    start: FormControl<string>;
    end: FormControl<string>;
  }>;

  // this form is used in the html of the component, when value changes it will also update the form values
  public dateRangeFormGroup = new FormGroup({
    start: new FormControl<Date | null>(null, { updateOn: "blur" }),
    end: new FormControl<Date | null>(null, { updateOn: "blur" }),
  });
  @Input() public label = "common.filterByDate";
  @Input() public pickerStyle = "default";
  @Input() public mostRecentDate$ = new Observable<Date>();

  public constructor(private datePipe: LocalizedDatePipe) {}

  public ngOnInit() {
    this.updateDateRangeFormGroupValue(this.form.getRawValue());

    this.form.valueChanges
      .pipe(
        // avoid multiple value changes on change (especially on load and reset)
        debounceTime(10),
        map(() => this.form.getRawValue()),
        distinctUntilChanged(areStringValuesEqual), //  avoid infinite looping between the 2 forms
      )
      .subscribe((values) => {
        this.updateDateRangeFormGroupValue(values);
      });

    this.dateRangeFormGroup.valueChanges
      .pipe(
        // avoid multiple value changes on change (especially on load and reset)
        debounceTime(10),
        map(() => this.dateRangeFormGroup.getRawValue()),
        map(({ start, end }) => ({
          start: this.dateToString(start),
          end: this.dateToString(end, true),
        })),
        distinctUntilChanged(areStringValuesEqual),
      )
      .subscribe((values) => {
        this.form.patchValue(values);
      });
  }

  private updateDateRangeFormGroupValue(values: DateRangePickerFormValue) {
    const { start, end } = values;

    this.dateRangeFormGroup.patchValue({
      start: start ? new Date(start) : null,
      end: end
        ? new Date(new Date(end).setDate(new Date(end).getDate() - 1))
        : null,
    });
  }

  private dateToString(date: Date | null, isEndDate = false): string {
    if (!date) {
      return "";
    }
    const dateCopy = new Date(date);
    if (isEndDate) {
      dateCopy.setDate(dateCopy.getDate() + 1);
    }
    return this.datePipe.transform(dateCopy, "yyyy-MM-dd")!;
  }
}

function areStringValuesEqual(
  old: DateRangePickerFormValue,
  current: DateRangePickerFormValue,
) {
  return old.start === current.start && old.end === current.end;
}

export interface DateRangePickerFormValue {
  start: string;
  end: string;
}
