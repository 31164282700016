import { ComponentType } from "@angular/cdk/portal";
import { Injectable, OnDestroy } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { filter, first } from "rxjs/operators";
import { SnackbarReloadComponent } from "../../components/snackbar-reload/snackbar-reload.component";

@Injectable()
export class SnackbarService implements OnDestroy {
  private isReloadSnackbarOpenned = false;

  public constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
  ) {}

  public ngOnDestroy() {
    this.dismiss(this.snackBar);
  }

  public open(translateKey: string, options: SnackbarOptions = {}) {
    const opts = { ...defaultOptions, ...options };
    forkJoin([
      this.translate.get(translateKey, opts.interpolationParams),
      this.translate.get("common.close"),
    ]).subscribe(([message, close]) => {
      const snackbar = this.snackBar.open(message, close, opts.matConfig);
      this.configureSnackbar(snackbar, opts);
    });
  }

  public openFromComponent<T>(
    component: ComponentType<T>,
    options: SnackbarOptions = {},
  ) {
    const opts = { ...defaultOptions, ...options };
    const snackbar = this.snackBar.openFromComponent(component, opts.matConfig);
    this.configureSnackbar(snackbar, opts);
  }

  public openReloadSnackbar(translateKey: string) {
    if (!this.isReloadSnackbarOpenned) {
      this.isReloadSnackbarOpenned = true;
      this.openFromComponent(SnackbarReloadComponent, {
        dismissOnChangePage: true,
        onAction: () => {
          window.location.reload();
        },
        matConfig: {
          duration: 0,
          data: translateKey,
          horizontalPosition: "right",
          verticalPosition: "top",
        },
      });
    }
  }

  private configureSnackbar(
    snackbar: MatSnackBarRef<unknown>,
    opts: SnackbarOptions,
  ) {
    if (opts.onAction) {
      snackbar.onAction().subscribe(() => {
        opts.onAction!();
      });
    }
    if (opts.dismissOnChangePage) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationStart),
          first(),
        )
        .subscribe(() => {
          this.dismiss(snackbar);
        });
    }
  }

  private dismiss(snackbar: MatSnackBarRef<unknown> | MatSnackBar) {
    this.isReloadSnackbarOpenned = false;
    snackbar.dismiss();
  }
}

const defaultOptions: SnackbarOptions = {
  interpolationParams: {},
  dismissOnChangePage: false,
  matConfig: {
    duration: 3000,
  },
};

export interface SnackbarOptions {
  interpolationParams?: Record<string, unknown>;
  dismissOnChangePage?: boolean;
  onAction?: () => void;
  matConfig?: MatSnackBarConfig;
}
