import { Amc } from "../../models/amc.model";

export function mapAmcResponseToAmc(amcResponse: AmcResponse): Amc {
  return {
    ...amcResponse,
    lastOnDemandSwitchDate: amcResponse.lastOnDemandSwitchDate
      ? new Date(amcResponse.lastOnDemandSwitchDate)
      : undefined,
  };
}

export type AmcResponse = Omit<Amc, "lastOnDemandSwitchDate"> & {
  lastOnDemandSwitchDate: string;
};
