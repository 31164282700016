import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MarkdownService } from "ngx-markdown";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DisplayedFile, File } from "../../models/file.model";
import { BusinessService } from "../../services/network/business.service";
import { DownloadFileService } from "../../services/utils/download-file.service";
import { formatFilename } from "../../services/utils/utils";

@Component({
  selector: "app-markdown-viewer",
  templateUrl: "./markdown-viewer.component.html",
  styleUrls: ["./markdown-viewer.component.scss"],
})
export class MarkdownViewerComponent implements OnInit {
  @Input() public displayedFile!: DisplayedFile;
  public releaseNotes$ = new Observable<File[]>();

  @ViewChild("fileContent", { static: false })
  fileContent!: ElementRef<HTMLElement>;

  public constructor(
    private markdownService: MarkdownService,
    private businessService: BusinessService,
    private downloadFileService: DownloadFileService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.releaseNotes$ = this.businessService.getReleaseNotesFilesStream().pipe(
      map((files) =>
        files.map((file) => ({
          ...file,
          version: file.name,
        })),
      ),
    );
    this.markdownService.renderer.link = (
      href: string,
      title: string,
      text: string,
    ) => {
      if (href.startsWith("#")) {
        return `<a>${text}</a>`;
      }
      return `<a href="${href}">${text}</a>`;
    };
  }

  public async downloadFile() {
    const fileContent = this.fileContent.nativeElement.querySelector(
      "markdown",
    ) as HTMLElement;

    await this.downloadFileService.downloadHTMLToPdf(
      fileContent,
      formatFilename(this.displayedFile.name),
    );
  }

  public isAReleaseNotes() {
    return this.router.url.startsWith("/file/release-notes");
  }

  public getReleaseNotesNameToDisplay(name: string) {
    return formatFilename(name);
  }
}
