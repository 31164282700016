import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, merge, Observable, timer } from "rxjs";
import { map, mergeMap, share, skip } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Pagination } from "../../models/pagination.model";
import { EVERY_MINUTE } from "../utils/globals";
import { ERROR_INTERCEPTOR_SKIP_HEADER } from "./http-error.interceptor";

const eventUrl = `${environment.apiUrl}/api/v1/notification/event`;

@Injectable({ providedIn: "root" })
export class NotificationUnreadService {
  private unread$: Observable<number>;
  private setZeroSignal$ = new BehaviorSubject(true);
  private refreshSignal$ = new BehaviorSubject(true);

  public constructor(private http: HttpClient) {
    const fromTimer = timer(0, EVERY_MINUTE).pipe(mergeMap(() => this.fetch()));
    const fromForceRefresh = this.refreshSignal$.pipe(
      skip(1),
      mergeMap(() => this.fetch()),
    );
    const fromSetZero = this.setZeroSignal$.pipe(
      skip(1),
      map(() => 0),
    );

    this.unread$ = merge(fromTimer, fromForceRefresh, fromSetZero).pipe(
      share(),
    );
  }

  public setZero() {
    this.setZeroSignal$.next(true);
  }

  public forceRefresh() {
    this.refreshSignal$.next(true);
  }

  public getUnread$(): Observable<number> {
    return this.unread$;
  }

  private fetch() {
    return this.http
      .get<Pagination<unknown>>(eventUrl, {
        params: { unread: true, limit: 1 },
        headers: new HttpHeaders({ [ERROR_INTERCEPTOR_SKIP_HEADER]: "" }),
      })
      .pipe(map((result) => result.total));
  }
}
